import { useContext, useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "context/AuthContext"
import { UserContext } from "context/UserContext"
import { TealiumLinkTrack } from "tealium"
import { AuthApi } from "api/auth"
import { UserApi } from "api/user"
import { AppRoutes, IDLE_TIMEOUT } from "utilities/constants"
import { attachRedirectUrl, getParamFromUrlHash } from "utilities/helpers"
import { Authenticated } from "components/Authenticated"
import { ApplicationData } from "types"
import { ClientContext } from "context/ClientContext"
import { Unauthenticated } from "./Unauthenticated"

export const AuthenticationWrapper = (localAppData: ApplicationData) => {
  const [errorLoadingToken, setErrorLoadingToken] = useState<boolean>(false)
  const { adb2cToken, token, setToken, setAdb2cToken, unsetTokens } =
    useContext(AuthContext)
  const { user, setUser } = useContext(UserContext)
  const { cid } = useContext(UserContext)
  const { client } = useContext(ClientContext)
  const navigate = useNavigate()
  const urlHash = window.location.hash

  const [loopCount, setLoopCount] = useState(1)
  const [loopSource, setLoopSource] = useState("UE1")

  /**
   * Unauth user after inactivity period
   */
  const onIdle = () => {
    console.log("Idle timeout")
    navigate(AppRoutes.logout)
  }
  useIdleTimer({ onIdle, timeout: IDLE_TIMEOUT })

  /**
   * Clear the DB token on load to reauth from ADB2C token
   */
  useEffect(() => {
    const idTokenFromHash = getParamFromUrlHash(urlHash, "id_token")
    setLoopSource("UE2") // Login Loop 3
    setLoopCount(prevCount => prevCount + 1)

    // Hits on refresh loop or /logout route
    if (!idTokenFromHash) {
      return
    }

    // Hits on auth loop. Not refresh. adb2cToken is undefined
    if (idTokenFromHash !== adb2cToken) {
      console.log("Clearing token, setting setAdb2cToken")
      setToken(undefined)
      setAdb2cToken(idTokenFromHash)
    }
  }, [urlHash, setToken, setAdb2cToken, adb2cToken])

  /**
   * On login, use adb2cToken to get/set db token
   * Hits on auth loop. Not refresh.
   */
  useEffect(() => {
    if (!errorLoadingToken && !token && adb2cToken) {
      setLoopSource("UE3")
      setLoopCount(prevCount => prevCount + 1)

      console.log("Attempting to auth with adb2cToken: ", { adb2cToken })

      // TODO: Replace cid with CDN cid
      // -- Ask Matt Deasy about this
      AuthApi.authenticate({ idToken: adb2cToken, cid })
        .then(token => {
          setToken(token.idToken)
        })
        .catch(e => {
          /**
           * Check for 412 status (access denied)
           * If back-end says user needs to re-auth, like after passphrase reset, here we send them back to the Sign In page.  */
          if (e.response?.status === 412) {
            const signInUrl =
              attachRedirectUrl(
                client.adb2cUrl,
                encodeURIComponent(client.redirectUrl),
              ) + "&passwordreset"

            unsetTokens()

            window.location.href = signInUrl
              .toLowerCase()
              .replace("b2c_1a_signup", "b2c_1a_signin")
          }
          setErrorLoadingToken(true)
        })
    }
  }, [adb2cToken, errorLoadingToken, setToken, cid, token, client, unsetTokens])

  /**
   * Use `token` to load or create `user` data
   * Hits on page refresh loop (not auth)
   */
  useEffect(() => {
    if (token && !user) {
      setLoopSource("UE4")
      setLoopCount(prevCount => prevCount + 1)

      console.log("Attempting to .me:", token)

      UserApi.me({ token })
        .then(user => {
          setUser(user)
        })
        .catch(error => {
          console.error(".me has failed", { error })
          // !!! Stuck in /logout because not inside Router !!!
          navigate(AppRoutes.logout)
        })
    }

    // eslint-disable-next-line
  }, [user, token, setUser, setAdb2cToken, setToken, navigate])

  useEffect(() => {
    // @ts-ignore
    if (user && window.utag) {
      TealiumLinkTrack({
        tealium_event: "app_login",
        email_address: user.email,
      })
    }

    // @ts-ignore
    // eslint-disable-next-line
  }, [user, window.utag])

  /**
   * Must have `token` and `user` to pass this block
   */
  if (!token || !user) {
    if (!token && user) {
      // Expect to never see this message. Token loads before user.
      console.log(`No token found. L${loopCount} ${loopSource}`)
    }
    if (token && !user) {
      // Expect Auth L4 UE3
      // Expect Refresh L3 UE4
      console.log(`No user found. L${loopCount} ${loopSource}`)
    }
    if (!token && !user) {
      // Expect Auth L2 UE2
      // Expect Auth L4 UE3
      // Expect Auth L6 UE4
      // Expect Refresh L1 UE1
      console.log(`No token or user found. L${loopCount} ${loopSource}`)
    }

    return <Unauthenticated {...localAppData} />
  }

  // TODO: How to detect expired tokens
  // Ask Matt Deasy about this
  console.log(`Authenticated: ${user.email}`)
  return <Authenticated {...localAppData} />
}
