import { colors } from "./colors"
import { assetsUrl } from "data/colleges/hollins/hollins"
import { TWO_COL_AUTH_BREAKPOINT } from "pages/susi/hollins/HollinsAuthLayout"

const base64Checkmark =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb25zL2NoZWNrIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTUuOTk5OTggMTAuNzhMMy4yMTk5OCA4LjAwMDAyTDIuMjczMzIgOC45NDAwMkw1Ljk5OTk4IDEyLjY2NjdMMTQgNC42NjY2OEwxMy4wNiAzLjcyNjY4TDUuOTk5OTggMTAuNzhaIiBmaWxsPSIjMzczNzM0Ii8+CjwvZz4KPC9zdmc+Cg=="

const base64MagnifyingGlass =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTIuOTE2NyAxMS42NjY3SDEyLjI1ODNMMTIuMDI1IDExLjQ0MTdDMTIuODQxNyAxMC40OTE3IDEzLjMzMzMgOS4yNTgzMyAxMy4zMzMzIDcuOTE2NjdDMTMuMzMzMyA0LjkyNSAxMC45MDgzIDIuNSA3LjkxNjY3IDIuNUM0LjkyNSAyLjUgMi41IDQuOTI1IDIuNSA3LjkxNjY3QzIuNSAxMC45MDgzIDQuOTI1IDEzLjMzMzMgNy45MTY2NyAxMy4zMzMzQzkuMjU4MzMgMTMuMzMzMyAxMC40OTE3IDEyLjg0MTcgMTEuNDQxNyAxMi4wMjVMMTEuNjY2NyAxMi4yNTgzVjEyLjkxNjdMMTUuODMzMyAxNy4wNzVMMTcuMDc1IDE1LjgzMzNMMTIuOTE2NyAxMS42NjY3Wk03LjkxNjY3IDExLjY2NjdDNS44NDE2NyAxMS42NjY3IDQuMTY2NjcgOS45OTE2NyA0LjE2NjY3IDcuOTE2NjdDNC4xNjY2NyA1Ljg0MTY3IDUuODQxNjcgNC4xNjY2NyA3LjkxNjY3IDQuMTY2NjdDOS45OTE2NyA0LjE2NjY3IDExLjY2NjcgNS44NDE2NyAxMS42NjY3IDcuOTE2NjdDMTEuNjY2NyA5Ljk5MTY3IDkuOTkxNjcgMTEuNjY2NyA3LjkxNjY3IDExLjY2NjdaIiBmaWxsPSIjMzczNzM0Ii8+Cjwvc3ZnPg=="

const heroImage = assetsUrl + "20240613172549_ca-hollins-hero.jpg"
const heroBgImage = assetsUrl + "20240613172549_ca-hollins-paper.png"
const heroBelieveImage = assetsUrl + "20240613172548_ca-hollins-believe.png"

export const Button = {
  baseStyle: {
    borderRadius: "999px",
    boxShadow: "none",
    fontFamily: "body",
    fontWeight: "bold",
    transition: "all 0.2s ease-in-out",

    _hover: {
      boxShadow: "none",
      _disabled: {
        boxShadow: "none",
      },
    },
    _focus: {
      boxShadow: "none",
    },
    _active: {
      boxShadow: "none",
    },
  },
  sizes: {
    sm: {
      fontSize: "sm",
      lineHeight: 1.57143,
      height: "auto",
      paddingX: 4,
      paddingY: 2,
    },
    md: {
      fontSize: "md",
      lineHeight: 1.5,
      height: "auto",
      paddingX: 5,
      paddingY: "0.625rem",
    },
  },
  variants: {
    solid: {
      background: "buttonLinkColor",
      boxShadow: "2px 2px 6px 0px rgba(0, 0, 0, 0.15)",
      color: "white",
      border: "none",
      outline: "2px solid",
      outlineColor: "buttonLinkColor",
      _hover: {
        background: "buttonLinkHoverColor",
        outlineColor: "buttonLinkHoverColor",
        _disabled: {
          background: "buttonLinkColor",
        },
      },
      _focus: {
        background: "buttonLinkFocusColor",
        outlineColor: "buttonLinkFocusBorderColor",
        boxShadow: "none",
      },
      _active: {
        background: "buttonLinkFocusColor",
        outlineColor: "buttonLinkFocusBorderColor",
        boxShadow: "none",
      },
      _disabled: {
        background: "buttonLinkDisabledColor",
        outlineColor: "buttonLinkDisabledColor",
      },
    },
    outline: {
      border: "none",
      outline: "2px solid",
      backgroundColor: "white",
      outlineColor: "buttonLinkColor",
      color: "buttonLinkColor",
      _hover: {
        backgroundColor: colors.accent[10],
        outlineColor: colors.accent[80],
        color: colors.accent[80],
        _disabled: {
          color: "buttonLinkDisabledColor",
          outlineColor: "buttonLinkDisabledColor",
        },
      },
      _focus: {
        backgroundColor: colors.accent[10],
        outlineColor: colors.accent[90],
        color: colors.accent[90],
      },
      _active: {
        backgroundColor: colors.accent[10],
        outlineColor: colors.accent[90],
        color: colors.accent[90],
      },
      _disabled: {
        color: "buttonLinkDisabledColor",
        outlineColor: "buttonLinkDisabledColor",
      },
    },
    ghost: {},
    link: {
      background: "transparent!",
      border: "none",
      outline: "none",
      borderRadius: "none",
      color: "buttonLinkColor",
      margin: 0,
      padding: 0,
      fontWeight: "semibold",
      _hover: {
        color: "buttonLinkHoverColor",
        _disabled: {
          color: "buttonLinkColor",
        },
      },
      focus: {
        color: "buttonLinkActiveColor",
      },
      _active: {
        color: "buttonLinkActiveColor",
      },
      _disabled: {
        color: "buttonLinkDisabledColor",
      },
    },
  },
}

/** This is the final check page */
const LayoutCentered = {
  baseStyle: {
    "main": {
      maxWidth: "48.75rem",
      paddingY: { base: 4, md: 0 },

      ".chakra-accordion": {
        marginTop: 8,
        marginBottom: 0,
        zIndex: 1,

        "> .chakra-stack": {
          gap: {
            base: 0,
            md: 6,
          },
        },
      },
    },

    ".final-check__submit-button": {
      marginTop: {
        base: 8,
        md: 12,
      },
    },
  },
}

const cardBorderRadius = { base: 0, md: "4px" }
const FinalCheckCard = {
  baseStyle: {
    background: "white!",
    borderRadius: cardBorderRadius,
    borderBottom: `1px solid ${colors.neutral[40]}`,
    borderTop: `1px solid ${colors.neutral[40]}`,
    borderLeft: {
      base: "none",
      md: `1px solid ${colors.neutral[40]}`,
    },
    borderRight: {
      base: "none",
      md: `1px solid ${colors.neutral[40]}`,
    },

    boxShadow: {
      base: "none",
      md: "1px 2px 6px 0px rgba(39, 44, 51, 0.15)",
    },

    "&:last-of-type": {
      borderBottom: "1px solid",
      borderColor: "formInputBorderColor",
    },

    "&:has([aria-expanded='true'])": {
      borderColor: "formInputFocusedBorderColor",
    },

    "&:focus-within": {
      boxShadow: "outline",
      transform: "translateY(0)", // overlap hack
    },

    ".chakra-collapse": {
      overflow: "visible!important",

      "> div": {
        padding: { base: 8 },
      },
      ".combined-fields-wrapper": {
        gap: 6,
      },
    },
  },
}

/** button.chakra-accordion__button */
const FinalCheckCardHeader = {
  baseStyle: {
    background: "white",
    borderRadius: cardBorderRadius,
    gap: "0.625rem",
    padding: { base: 6 },
    _focus: {
      background: colors.neutral[20],
      boxShadow: "none",
    },

    _hover: {
      background: colors.neutral[20],
    },

    "&[aria-expanded='true']": {
      backgroundColor: colors.neutral[20],
    },

    "& > svg": {
      height: 8,
      width: 8,
    },

    ".card-header-eyebrow": {
      color: colors.neutral[50],
      fontWeight: 600,
      fontSize: "md",
      lineHeight: 1.5,
    },

    h2: {
      margin: 0,
    },

    ".error-badge": {
      backgroundColor: colors.negative[90],
      borderRadius: "1rem",
      boxShadow: "none",
      gap: "2px",
      marginRight: 0,
      paddingX: 3,

      "& svg": {
        height: "0.875rem",
        width: "0.875rem",
        padding: "1px",
      },

      "& span": {
        marginLeft: 0,
      },
    },

    ".success-badge": {
      backgroundColor: colors.primary[80],
      boxShadow: "none",
    },
  },
}

const Headings = {
  H1: {
    baseStyle: {
      fontFamily: "h1",
      fontSize: {
        base: "1.75rem",
        sm: "2rem",
        md: "3rem",
      },
      fontWeight: "bold",
      lineHeight: { base: 1.142, md: 1.3 },
      marginBottom: 1,
    },
  },
  H2: {
    baseStyle: {
      color: "bodyColor",
      fontSize: {
        base: "1.25rem",
        md: "1.625rem",
      },
      fontWeight: "bold",
      lineHeight: {
        base: 1.2,
        md: 1.23077,
      },
      marginBottom: {
        base: 1,
        md: 1,
      },
    },
  },
  H3: {
    baseStyle: {
      fontSize: { base: "xl", md: "xl!" },
      fontWeight: "500!",
      lineHeight: 1.2,
    },
  },
}

const Input = {
  baseStyle: {
    field: {
      height: {
        base: "auto",
      },
    },
  },
  sizes: {
    base: {
      field: {
        fontSize: "sm",
      },
    },
    md: {
      field: {
        fontSize: "md",
        height: 12,
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: {
          base: "4px",
        },
        // repetition necessary to override base padding
        paddingX: {
          base: 3,
          sm: 3,
          md: 3,
        },
        paddingY: {
          base: 2,
        },
        borderColor: "formInputBorderColor",

        _placeholder: {
          color: colors.neutral[60],
        },

        _hover: {
          borderColor: "formInputHoveredBorderColor",
        },

        _focus: {
          borderColor: "formInputFocusedBorderColor",
          outline: "1px solid",
          outlineColor: "formInputFocusedBorderColor",
          outlineOffset: 0,
          boxShadow: "1px 2px 6px 0px rgba(39, 44, 51, 0.15)",
        },

        _invalid: {
          borderColor: colors.negative[70],
          boxShadow: "none",
          _focus: {
            borderColor: colors.negative[70],
            outline: `1px solid ${colors.negative[70]}`,
          },
        },

        _disabled: {
          background: "disabledBackground",
          borderColor: "disabledBorder",
          color: colors.neutral[50],
        },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
}

const Textarea = {
  variants: {
    outline: {
      ...Input.variants.outline.field,
    },
  },
  ...Input.defaultProps,
}

const Nav = {
  baseStyle: {
    a: {
      "& span": {
        color: "navLinkColor",
      },
      "&:hover span": {
        color: "navLinkHoverColor",
      },
      "&.active > span": {
        background: "navLinkHoverBackgroundColor",
        borderLeft: "4px solid",
        color: "navLinkHoverColor",
        "& *": {
          color: "inherit",
        },
      },
    },
    // This is the only place I can successfully apply this style from
    "+ main": {
      overflow: "visible"
    }
  },
}

const NestedSectionDataParser = {
  baseStyle: {
    border: "1px solid",
    borderColor: colors.primary[50],
    borderRadius: "none",
    padding: 6,

    h3: {
      fontWeight: "bold",
      fontSize: "lg",
    },
  },
}

const Text = {
  baseStyle: {
    color: "bodyColor",
    fontSize: {
      base: "1rem",
      md: "1.125rem",
    },
    lineHeight: {
      base: 1.5,
      md: 1.55556,
    },
  },
}

const PageHeader = {
  baseStyle: {
    padding: "0!",
    marginBottom: {
      base: 0,
      md: 0,
    },
  },
}

const Main = {
  baseStyle: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      gap: {
        base: 8,
        md: 12,
      },
    },
  },
}

const ContentWrapper = {
  baseStyle: {
    "& > div": {
      border: "none",
    },
    ".radio-group, .checkbox-group": {
      gap: 1,
    },
  },
}

const selectionControlChecked = {
  background: colors.accent[80],
  borderColor: colors.accent[80],
}

const selectionControlStyles = {
  control: {
    borderColor: "formInputBorderColor",
    height: 5,
    width: 5,
    marginX: {
      base: "0.625rem",
    },
    marginY: {
      base: "calc(0.5rem + 1px)",
    },
    paddingY: {
      base: "1px",
    },
    _hover: {
      background: colors.accent[10],
      borderColor: selectionControlChecked.borderColor,
    },
  },
  label: {
    fontSize: { base: "1rem" },
    lineHeight: { base: 1.5 },
    _checked: {
      fontWeight: "600",
    },
  },
  defaultProps: {
    size: "sm",
  },
}

const Radio = {
  parts: ["control", "label"],
  sizes: {
    sm: {
      label: {...selectionControlStyles.label, marginLeft: 0},

      control: {
        ...selectionControlStyles.control,
        _checked: {
          borderColor: selectionControlChecked.borderColor,
          _before: {
            background: selectionControlChecked.background,
            height: "0.625rem",
            width: "0.625rem",
          },
          _hover: selectionControlStyles.control._hover,
        },
      },
    },
  },
  defaultProps: selectionControlStyles.defaultProps,
}

const Checkbox = {
  parts: ["control", "icon", "label"],
  sizes: {
    sm: {
      label: {...selectionControlStyles.label, marginLeft: 0},
      control: {
        ...selectionControlStyles.control,
        _checked: {
          ...selectionControlChecked,
          _hover: selectionControlChecked,
          _focus: {
            _hover: selectionControlChecked,
          },
        },
      },
    },
  },
  defaultProps: selectionControlStyles.defaultProps,
}

const SectionDataParser = {
  baseStyle: {
    borderBottomColor: "none",
    paddingX: { base: 4, md: 6 },
    paddingY: {
      base: 0,
    },
  },
}

const SiteFooter = {
  baseStyle: {
    backgroundColor: colors.neutral[10],
    borderTop: `4px solid ${colors.accent[80]}`,
    display: "flex",
    flexDirection: "column",
    fontSize: "xs",
    lineHeight: 1.5,
    marginTop: "auto",

    ".footer-container": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: { base: 3, [TWO_COL_AUTH_BREAKPOINT]: 2 },
      marginTop: {
        [TWO_COL_AUTH_BREAKPOINT]: 2,
      },
      marginX: "auto",
      width: "fit-content",
      textAlign: "center",
    },

    ".contact-details": {
      display: "flex",
      gap: 6,
      justifyContent: "center",

      a: {
        color: "bodyColor",
        textDecoration: {
          base: "none",
        },
        _hover: { color: "buttonLinkHoverColor" },
      },

      svg: {
        color: colors.accent[80],
        height: { base: 3, [TWO_COL_AUTH_BREAKPOINT]: 4 },
        width: { base: 3, [TWO_COL_AUTH_BREAKPOINT]: 4 },
      },

      ".phone-icon, .email-icon": {
        display: "inline",
      },
    },

    ".privacy-policy": {
      textDecoration: "underline",
    },

    "#user-signout > p": {
      display: "none",
    },

    ".logo": {
      display: "none",
    },
  },
}

const SiteHeader = {
  baseStyle: {
    backgroundColor: "transparent",
    paddingTop: { base: 4, md: 6 },
    paddingBottom: { base: 4, md: "1.125rem" },
    marginBottom: { base: 0, md: "4.25rem" },

    "& .logo": {
      height: "auto",
      width: {
        base: "155px",
        md: "152px",
      },
    },
  },
}

const SelectMenuNav = {
  baseStyle: {
    background: "white",

    "&#mobile-nav": {
      paddingX: "2.375rem",
      paddingTop: 4,
      paddingBottom: 8,

      select: {
        background: colors.primary[80],
        color: "white",
        fontWeight: "bold",
        fontSize: "1rem",
        lineHeight: "1.5",
        height: "auto",
        paddingLeft: 4,
        paddingRight: 3,
        paddingY: 2,
        borderRadius: "4px",
        border: "none",
      },
      "& .chakra-select__icon-wrapper": {
        color: "white",
      },
    },
  },
}

const IconButton = {
  variants: {
    link: {
      ...Button.variants.link,
    },
  },
  sizes: {
    sm: {
      height: 8,
      width: 4,
    },
  },
  defaultProps: {
    size: "sm",
    variant: "link",
  },
}

// ------------------------------------------------------------------------------

// https://github.com/bl00mber/react-phone-input-2
const { field: outlineInputStyles } = Input.variants.outline
const { sizes: inputSizes } = Input

const invalidInputStyles = {
  ...outlineInputStyles._invalid,
  background: "inherit",
  _focus: {
    ...outlineInputStyles._invalid._focus,
    boxShadow: outlineInputStyles._focus.boxShadow,
  },
  _hover: {
    borderColor: colors.negative[70],
  },
}

const PhoneInput = {
  baseStyle: {
    ".react-tel-input": {
      fontFamily: "body",
      fontSize: {
        base: inputSizes.base.field.fontSize,
        md: inputSizes.md.field.fontSize,
      },

      "&:has(+ [class*='error-message'])": {
        "> .form-control": invalidInputStyles,
      },

      ".form-control": {
        ...outlineInputStyles,
        fontSize: "inherit",
        height: inputSizes.md.field.height,
        width: "100%",
        // repeat styles necessary to override base padding
        paddingLeft: { base: "3.75rem", sm: "3.75rem", md: "3.75rem" },

        "&.invalid-number": invalidInputStyles,
      },

      ".flag-dropdown": {
        background: "transparent!",
        border: "none",

        "&.open": {
          width: "100%",
          position: "relative",
        },

        ".selected-flag": {
          background: "inherit",
          paddingLeft: outlineInputStyles.paddingX,
          "&.open": {
            transform: "translateY(-1.75rem)",
          },
        },

        "ul.country-list": {
          position: "relative",
          width: "100%",
          marginTop: 1,
          padding: 1,
          paddingTop: 0,
          borderRadius: outlineInputStyles.borderRadius,
          border: "1px solid",
          borderColor: colors.neutral[40],
          boxShadow: "0px 2px 8px 0px rgba(50, 59, 72, 0.10)",
          maxHeight: "21rem", // 7 items
          scrollSnapType: "y mandatory",

          "li.search": {
            display: "flex",
            paddingX: 2,
            paddingY: 0,
            alignItems: "center",
            borderBottom: "1px solid",
            borderBottomColor: colors.neutral[30],

            "&::before": {
              content: `url(${base64MagnifyingGlass})`,
              lineHeight: 1,
              height: 5,
              width: 5,
            },

            ".search-box": {
              border: "none",
              fontSize: "md",
              lineHeight: 1.5,
              marginLeft: 1,
              paddingY: 2,
              textTransform: "capitalize",
              width: "100%",
            },
          },

          "li.country": {
            display: "flex",
            alignItems: "center",
            marginTop: "0!",
            paddingX: 3,
            paddingY: 2,
            lineHeight: 1,
            fontSize: "md",
            scrollSnapAlign: "end",
            gap: 1,

            "&:hover": {
              backgroundColor: `${colors.accent[10]}!`,
            },

            "&[aria-selected=true]": {
              backgroundColor: "white",

              ".country-name, .dial-code": {
                fontWeight: "bold",
                color: "bodyColor!",
              },

              "&::after": {
                content: `url(${base64Checkmark})`,
                lineHeight: 1,
                position: "absolute",
                right: 3,
              },
            },

            ".flag": {
              display: "block",
              position: "relative",
              margin: 0,
              top: "auto",
              left: "auto",
              height: "25px",
            },
          },
        },
      },
    },
  },
}

const MultiSelect = {
  baseStyle: {
    menu: provided => ({
      ...provided,
      boxShadow: "md",
    }),
    menuList: provided => ({
      ...provided,
      paddingY: 0,
      "&::-webkit-scrollbar": {
        width: "14px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset  0 6px white",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.neutral[30],
        borderRadius: "7px",
        height: "33%",
        maxHeight: "100px",
        border: "4px solid transparent",
        backgroundClip: "padding-box",
      },
    }),
    option: (provided, state) => {
      if (state.children === "United States") {
        console.log(state)
      }
      return {
        ...provided,

        background: "white!",
        color: "formInputTextColor!",
        fontWeight: state.isSelected ? "bold" : "normal",
        justifyContent: "space-between",
        lineHeight: 1.5,
        paddingX: 3,
        paddingY: 2,

        "&::after": {
          content: state.isSelected ? `url(${base64Checkmark})` : "''",
          lineHeight: 1,
        },
        "&:hover": {
          backgroundColor: `${colors.accent[10]}!`,
          color: "formInputTextColor",
        },
      }
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        backgroundColor: "transparent",
        borderRadius: "md",
        color: state.isDisabled ? colors.neutral[50] : "bodyColor",
        padding: 0,
        svg: {
          width: 8,
          height: 8,
          padding: 1,
        },
        width: "auto",
        height: "auto",
      }
    },
    indicatorSeparator: (provided, state) => {
      const showSeparator = state.hasValue && !state.isDisabled
      return {
        ...provided,
        display: showSeparator ? null : "none!",
        marginX: 2,
      }
    },
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    clearIndicator: provided => ({
      ...provided,
      margin: 0,
    }),
  },
}

const HelperText = {
  baseStyle: {
    color: "bodyColorLight",
    fontSize: "0.75rem",
    lineHeight: { base: 1.5, md: 1.29, lg: 1.5 },
  },
}

const FormLabel = {
  baseStyle: {
    color: "formLabelColor",
    fontWeight: "bold",
    fontSize: {
      base: "sm",
      md: "md",
    },
    lineHeight: 1.5,
    span: {
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  },
}

const OptionalTag = {
  baseStyle: {
    color: "bodyColorLight",
    fontSize: "0.75rem",
    lineHeight: 1.5,
  },
}

const AuthPage = {
  baseStyle: {
    flexDirection: "column",
    maxWidth: "none",
    padding: {
      base: 0,
      md: 0,
    },

    "& > *:not(:first-of-type):not(:last-child)": {
      marginX: {
        base: "auto",
        [TWO_COL_AUTH_BREAKPOINT]: 0,
      },
      maxWidth: {
        base: "40rem",
        [TWO_COL_AUTH_BREAKPOINT]: "80rem",
      },
      width: "100%",
      paddingX: {
        base: 5,
        [TWO_COL_AUTH_BREAKPOINT]: 0,
      },
    },

    ".logo": {
      height: "auto",
      width: {
        base: "155px",
        [TWO_COL_AUTH_BREAKPOINT]: "200px",
      },
      marginTop: {
        base: 5,
        md: 7,
        [TWO_COL_AUTH_BREAKPOINT]: "3.75rem",
      },
      marginBottom: {
        base: 8,
        md: 9,
        [TWO_COL_AUTH_BREAKPOINT]: "4.625rem",
      },
      marginX: {
        base: "auto",
        [TWO_COL_AUTH_BREAKPOINT]: "5rem",
      },
    },

    "#auth-page-heading": {
      textAlign: {
        base: "center",
        [TWO_COL_AUTH_BREAKPOINT]: "left",
      },
      paddingLeft: {
        [TWO_COL_AUTH_BREAKPOINT]: "5rem",
      },
      paddingRight: {
        [TWO_COL_AUTH_BREAKPOINT]: 12,
      },

      h1: {
        color: "bodyColor",
        fontSize: {
          md: "2.5rem",
          [TWO_COL_AUTH_BREAKPOINT]: "4xl",
        },
        lineHeight: {
          base: 1.286,
          md: 1.2,
          [TWO_COL_AUTH_BREAKPOINT]: 1.22222,
        },
        marginBottom: {
          base: 5,
          md: 10,
          [TWO_COL_AUTH_BREAKPOINT]: 10,
        },
        marginX: {
          base: "auto",
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },

        "&.sign-up-heading:after": {
          [TWO_COL_AUTH_BREAKPOINT]: {
            content: "''",
            display: "block",
            width: "5.25rem",
            height: 2,
            background: colors.accent[60],
            marginY: 8,
          },
        },
      },
    },

    "#auth-page-copy": {
      display: "flex",
      fontSize: {
        base: "lg",
        [TWO_COL_AUTH_BREAKPOINT]: "md",
      },
      lineHeight: {
        base: 1.55556,
        [TWO_COL_AUTH_BREAKPOINT]: 1.5,
      },
      marginBottom: {
        base: 9,
        [TWO_COL_AUTH_BREAKPOINT]: 0,
      },
      paddingLeft: {
        [TWO_COL_AUTH_BREAKPOINT]: "5rem",
      },
      paddingRight: {
        [TWO_COL_AUTH_BREAKPOINT]: 11,
      },

      "& > div": {
        paddingRight: 0,
        flex: 1,
      },

      figure: {
        display: {
          base: "none",
          [TWO_COL_AUTH_BREAKPOINT]: "flex",
        },
        justifyContent: "end",
        position: "relative",

        "&.sign-up-image": {
          display: {
            [TWO_COL_AUTH_BREAKPOINT]: "flex",
          },
          marginLeft: "2.125rem",
          maxWidth: "30rem",
          flexBasis: "52.3%", // == 430px @ 1440px design preview

          "&:before": {
            content: "''",
            display: "block",
            // height divided by width of Figma container to create aspect ratio
            paddingBottom: "calc(100% * 410 / 430)",
          },

          "#hero-background": {
            display: "flex",
            alignItems: "center",
            position: "relative",
            top: "-40px",
            width: "100%",
            background: `url(${heroBgImage})`,
            backgroundPosition: "left 98px top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

            "&:after": {
              content: "''",
              position: "absolute",
              right: 0,
              bottom: "-12px",
              width: "120.553px",
              height: "47.532px",
              background: `url(${heroBelieveImage}) center center no-repeat`,
              backgroundSize: "95%",
              transform: "rotate(-16.157deg)",
              filter: "opacity(0.65)",
            },
          },

          "#hero-image": {
            display: "flex",
            width: "calc(100% * 368 / 430)",
            height: "calc(100% * 330 / 410)",
            background: `url(${heroImage})`,
            backgroundPosition: "right 20% center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "relative",
            boxShadow: "md",
          },
        },

        "&.sign-in-image": {
          display: {
            lg: "flex",
          },
          flexBasis: "100%",
          marginTop: 9,
          maxWidth: "597px",

          "&:before": {
            content: "''",
            display: "block",
            // height divided by width of Figma container to create aspect ratio
            paddingBottom: "calc(100% * 419 / 597)",
          },

          "#hero-background": {
            display: "flex",
            alignItems: "center",
            position: "relative",
            top: "-40px",
            width: "100%",
            background: `url(${heroBgImage})`,
            backgroundPosition: "left 49px top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

            "&:after": {
              content: "''",
              position: "absolute",
              right: 0,
              bottom: "-24px",
              width: "164.76px",
              height: "64.96px",
              background: `url(${heroBelieveImage}) center center no-repeat`,
              backgroundSize: "95%",
              transform: "rotate(-16.157deg)",
              filter: "opacity(0.65)",
            },
          },

          "#hero-image": {
            display: "flex",
            width: "calc(100% * 525 / 597)",
            height: "calc(100% * 330 / 420)",
            background: `url(${heroImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "relative",
            boxShadow: "md",
          },
        },
      },
    },

    "#auth-form-container": {
      background: {
        [TWO_COL_AUTH_BREAKPOINT]: colors.accent[10],
      },
      display: "flex",
      flexDirection: "column",
      height: {
        [TWO_COL_AUTH_BREAKPOINT]: "100%",
      },
      justifyContent: {
        [TWO_COL_AUTH_BREAKPOINT]: "center",
      },
      textAlign: "center",

      "#cancel": {
        display: "none",
      },

      "&.forgot-password": {
        "#attributeList + .buttons": {
          "#cancel": {
            ...Button.baseStyle,
            ...Button.sizes.md,
            ...Button.variants.link,
            display: "block",
          },
        },
      },

      "#auth-form": {
        background: {
          base: colors.accent[10],
          [TWO_COL_AUTH_BREAKPOINT]: "transparent",
        },
        display: "flex",
        flexDirection: "column",
        gap: {
          base: 5,
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
        marginBottom: {
          base: 7,
          md: 10,
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
        marginX: {
          [TWO_COL_AUTH_BREAKPOINT]: "auto",
        },
        maxWidth: {
          base: "37.5rem",
          [TWO_COL_AUTH_BREAKPOINT]: "none",
        },
        paddingX: {
          base: 5,
          md: "3.75rem",
          [TWO_COL_AUTH_BREAKPOINT]: "4rem",
        },
        paddingY: {
          base: 5,
          md: 6,
        },
        width: {
          [TWO_COL_AUTH_BREAKPOINT]: "100%",
        },
      },

      "#auth-form-heading": {
        color: colors.accent[90],
        fontFamily: "heading",
        fontSize: {
          base: "1.25rem",
          md: "1.375rem",
          [TWO_COL_AUTH_BREAKPOINT]: "1.375rem",
        },
        fontWeight: "bold",
        lineHeight: {
          base: 1.2,
          md: 1.09091,
          [TWO_COL_AUTH_BREAKPOINT]: 1.1,
        },
        marginBottom: {
          base: "unset",
          [TWO_COL_AUTH_BREAKPOINT]: 9,
        },
        marginTop: {
          [TWO_COL_AUTH_BREAKPOINT]: "1.625rem",
        },
      },

      "#auth-form-subtext": {
        color: "bodyColor",
        lineHeight: {
          base: 1.375,
          [TWO_COL_AUTH_BREAKPOINT]: 1.57143,
        },
        fontSize: {
          base: "md",
          [TWO_COL_AUTH_BREAKPOINT]: "sm",
        },
        marginTop: {
          [TWO_COL_AUTH_BREAKPOINT]: 5,
        },

        a: {
          fontWeight: "semibold",
        },
      },
    },

    ".grid-spacer-y": {
      background: {
        [TWO_COL_AUTH_BREAKPOINT]: colors.accent[10],
      },
      minHeight: {
        [TWO_COL_AUTH_BREAKPOINT]: "15px",
      },
    },

    ".site-footer": {
      backgroundColor: {
        [TWO_COL_AUTH_BREAKPOINT]: colors.accent[10],
      },
      borderTop: {
        [TWO_COL_AUTH_BREAKPOINT]: "none",
      },
      paddingTop: {
        base: 5,
        md: 5,
      },
      paddingBottom: {
        base: 6,
        md: 6,
      },
      paddingY: {
        [TWO_COL_AUTH_BREAKPOINT]: 2,
      },
    },
  },
}

const AuthFormWrapper = {
  baseStyle: {
    background: "unset",
    borderRadius: "unset",
    boxShadow: "unset",
    padding: "unset",

    "#api": {
      marginBottom: "unset",

      // Sign Up, Sign In, & Forgot Password input groups
      "#attributeList.attr li + li, #localAccountForm .entry-item + .entry-item,#emailVerificationControl li + li .attrEntry":
        {
          marginTop: 3,
        },

      input: {
        ...Input.baseStyle.field,
        ...Input.variants.outline.field,
        fontSize: {
          base: "sm",
          md: "md",
        },
        lineHeight: {
          base: 1.57143,
          md: 1.5,
        },
      },
      button: {
        ...Button.baseStyle,
        ...Button.sizes.md,
        ...Button.variants.solid,
        flexBasis: "auto",
        fontSize: {
          base: "lg",
          [TWO_COL_AUTH_BREAKPOINT]: "md",
        },
        lineHeight: {
          base: 1.55556,
          [TWO_COL_AUTH_BREAKPOINT]: 1.5,
        },
        paddingX: {
          base: 5,
        },
        paddingY: {
          base: 3,
        },
        width: {
          base: "100%",
          md: "83.333%",
          [TWO_COL_AUTH_BREAKPOINT]: "72.222%",
        },

        marginY: {
          base: 5,
          [TWO_COL_AUTH_BREAKPOINT]: 6,
        },
      },
      ul /*labels */: {
        listStyle: "none!",
        margin: "0!",
      },
      "ul li, label": {
        ...FormLabel.baseStyle,
      },
      label: {
        lineHeight: {
          base: 1.57143,
          md: 1.5,
        },
        marginBottom: {
          base: 1,
        },
        textAlign: "left",
      },

      ".password-label": {
        alignItems: "baseline",
        display: "flex",
        justifyContent: "space-between",
        fontSize: { ...FormLabel.baseStyle.fontSize },

        a: {
          fontSize: "xs",
        },
      },
    },
  },
}

export const components = {
  AuthFormWrapper,
  AuthPage,
  Button,
  ContentWrapper,
  Checkbox,
  FinalCheckCard,
  FinalCheckCardHeader,
  ...Headings,
  Input,
  LayoutCentered,
  Main,
  Nav,
  NestedSectionDataParser,
  NumberInput: {
    ...Input,
  },
  OptionalTag,
  PageHeader,
  Radio,
  SectionDataParser,
  SelectMenuNav,
  SiteFooter,
  SiteHeader,
  Text,
  Textarea,
  IconButton,
  PhoneInput,
  MultiSelect,
  HelperText,
  FormLabel,
}
