import Connection from "./connection"

abstract class ApiRoot {
  private static _connection?: Connection

  static get connection() {
    if (!this._connection) {
      console.error("Attempting to use connection before instantiated")
    }

    return this._connection
  }

  static async connect(apiRoot) {
    this._connection = new Connection(apiRoot)
  }

  static disconnect() {
    this._connection = undefined
  }
}

export default ApiRoot
