const neutral = {
    10: "#FAFAFA",
    20: "#F5F5F3",
    30: "#EFEEEC",
    40: "#D5D4D3",
    50: "#B3B2B1",
    60: "#8F8F8C",
    70: "#6E6D6A",
    80: "#4E4D49",
    90: "#373734",
    100: "#2C2C2A",
  }
  
  const primary = {
    10: "#E5F0EE",
    25: "#BFDAD4",
    50: "#80B5A9",
    60: "#66A698",
    80: "#006F53",
    90: "#005240",
  }
  
  const accent = {
    10: "#F7F3EE",
    25: "#E6DCCA",
    50: "#CDB895",
    60: "#C2AA80",
    80: "#9A722C",
    90: "#805E24",
  }
  
  const success = {
    10: "#E6F1EE",
    25: "#C2DCD6",
    50: "#91C1B5",
    60: "#5EA493",
    80: "#2E8972",
    90: "#006F53",
  }
  
  const warning = {
    10: "#FFF4E5",
    25: "#FFE9CC",
    50: "#FFC780",
    60: "#FFB14D",
    80: "#FF9B1A",
    90: "#D58000",
  }
  
  const negative = {
    10: "#FAEEEC",
    25: "#F2D5D2",
    50: "#DD9189",
    60: "#C44554",
    70: "#C95144",
    80: "#C95144",
    90: "#BB3526",
  }
  
  export const colors = {
    neutral,
    primary,
    accent,
    success,
    warning,
    negative,
    white: "#FFFFFF",
    bodyColor: neutral[90],
    bodyColorLight: neutral[70],
    buttonLinkColor: primary[80],
    buttonLinkHoverColor: accent[80],
    buttonLinkActiveColor: accent[90],
    buttonLinkFocusColor: accent[90],
    buttonLinkFocusBorderColor: accent[25],
    buttonLinkDisabledColor: primary[50],
    disabledBackground: neutral[20],
    disabledBorder: neutral[40],
    disabledCheckedBackground: neutral[40],
    errorText: negative[90],
    red: {
      500: negative[90],
    },
    formInputBackgroundColor: "white",
    formInputHoveredBackgroundColor: "white",
    formInputFocusedBackgroundColor: "white",
  
    formInputBorderColor: neutral[50],
    formInputHoveredBorderColor: neutral[80],
    formInputFocusedBorderColor: neutral[80],
  
    formLabelColor: neutral[80],
    formInputTextColor: neutral[90],
  
    headingColor: primary[80],
    navLinkColor: primary[60],
    navLinkActiveColor: primary[80],
    navLinkActiveAccentColor: accent[80],
    navLinkHoverBackgroundColor: neutral[10],
    navLinkHoverColor: primary[80],
    primaryColor: primary[80],
  }
  