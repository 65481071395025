import { useLocation, useNavigate } from "react-router-dom"
import {
  Box,
  BoxProps,
  Select,
  SelectProps,
  useStyleConfig,
} from "@chakra-ui/react"

export const SelectMenuNav = ({
  children,
  size,
  ...rest
}: BoxProps & SelectProps) => {
  const styles = useStyleConfig("SelectMenuNav")
  const location = useLocation()
  const navigate = useNavigate()
  const currentPage = location.pathname.slice(1)

  const handleSelectChange = e => navigate(`/${e.target.value}`)

  return (
    <Box __css={styles} {...rest}>
      <Select onChange={handleSelectChange} size={size} value={currentPage}>
        {children}
      </Select>
    </Box>
  )
}
