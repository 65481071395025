export const concentrationsForRemove = {
  "Accounting Theory And Foundations": [
    "Not-For-Profit Governmental Accounting",
  ],
  "Brand Management": [
    "Marketing Research And Analytics",
    "Marketing Communications And Digital Marketing",
    "Sales And Business To Business Marketing",
    "Sports Marketing",
  ],

  "Corporate Finance": ["Financial Planning", "Fundamentals of Finance"],
  "Economic Theory And Practice": ["Public Policy And Economics"],

  "Financial Planning": [
    "Corporate Finance",
    "Fundamentals of Finance",
    "Investment Fundamentals",
  ],
  "Fundamentals of Finance": ["Financial Planning", "Investment Fundamentals"],

  "Investment Fundamentals": ["Financial Planning", "Fundamentals of Finance"],
  Leadership: ["Project Management"],
  "Marketing Communications And Digital Marketing": [
    "Brand Management",
    "Marketing Research And Analytics",
    "Sales And Business To Business Marketing",
    "Sports Marketing",
  ],
  "Not-For-Profit Governmental Accounting": [
    "Accounting Theory And Foundations",
  ],

  "Public Policy And Economics": ["Economic Theory And Practice"],
  "Sales And Business To Business Marketing": [
    "Brand Management",
    "Marketing Communications And Digital Marketing",
    "Marketing Research And Analytics",
    "Sports Marketing",
  ],
  "Sports Marketing": [
    "Brand Management",
    "Marketing Communications And Digital Marketing",
    "Marketing Research And Analytics",
    "Sales And Business To Business Marketing",
  ],

  "Strategic Human Resource Development": ["Leadership"],
  "Strategic Decision Making": ["Food Marketing"],
}
