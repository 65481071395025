import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
} from "@chakra-ui/react"
import { Option } from "types"

export type RadioProps = Omit<ChakraRadioProps, "value"> & Option

export const Radio = ({ ...rest }: RadioProps) => {
  return <ChakraRadio {...rest} />
}
