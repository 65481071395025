import React from "react"
import { GridProps } from "@chakra-ui/react"
import { ApplicationData, PageData, LayoutDetails } from "types"
import { Layout1 } from "components/Layout1"
import { Layout2 } from "components/Layout2"

export interface LayoutProps extends GridProps {
  applicationData: ApplicationData
  layout?: LayoutDetails
  navRef?: React.MutableRefObject<HTMLElement>
  mainRef?: React.MutableRefObject<HTMLElement>
  currentPage?: PageData
  showLoadingScreen?: boolean
}

/**
 * Once upon a time there were multiple layout options.
 * At time of comment (10/21/22)
 * - We are only using Layout "1" for applications
 * - We use Layout "centered" for Final Check, Confirmation, Logout
 */
export const Layout = (props: LayoutProps) => {
  switch (props.layout.version) {
    case "1":
    case "centered":
      return <Layout1 {...props} />
    case "2":
      return <Layout2 {...props} />
  }
}
