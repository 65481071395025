/** Array Methods
 ----------------------------------------------------*/
export const alphabetize = (
  options: any[],
  key: string,
  direction?: "asc" | "desc"
) => {
  return options.sort((a, b) =>
    direction === "desc" ? (a[key] < b[key] ? 1 : -1) : a[key] > b[key] ? 1 : -1
  )
}

/** Date Functions 
-----------------------------------------------------*/
export function timeAgo(date: Date) {
  // export function timeAgo(date?: string | Date) {
  // if (!date) {
  //   return "Last saved time unknown";
  // }

  const second = { label: "sec", value: 1000 }
  const minute = { label: "min", value: second.value * 60 }
  const hour = { label: "hour", value: minute.value * 60 }
  const day = { label: "day", value: hour.value * 24 }

  const currentDateTime = new Date().getTime()
  const savedDateTime = new Date(date).getTime()

  const dateTimeDifference = currentDateTime - savedDateTime

  const message = ({ label, value }: { label: string; value: number }) => {
    const time = (dateTimeDifference / value).toFixed()
    const unit = label + (parseInt(time) !== 1 ? "s" : "")
    return `${time} ${unit} ago`
  }

  switch (true) {
    case dateTimeDifference > day.value:
      return message(day)
    case dateTimeDifference > hour.value:
      return message(hour)
    case dateTimeDifference > minute.value:
      return message(minute)
    case dateTimeDifference > second.value:
      return message(second)
    default:
      return ""
  }
}

/** String Methods
 ----------------------------------------------------*/
export const dehyphenate = (string: string) => {
  return string.replace(/-/g, "")
}

export const lowercaseFirstLetter = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const splitString = (string: string, separator = " ") =>
  string.toLowerCase().split(separator)

export const titleize = (string: string, separator = " ") => {
  // prettier-ignore
  const lowercaseWords = ["a", "an", "and", "at", "but", "by", "for", "in", "nor", "of", "on", "or", "so", "the", "to", "up", "yet"]

  // Capitalize the first, last, and any word not in array
  const shouldCapitalize = (string: string, index: number) => {
    return index <= 0 || !lowercaseWords.includes(string)
  }

  return splitString(string, separator)
    .map((word, index) =>
      shouldCapitalize(word, index) ? capitalize(word) : word
    )
    .join(" ")
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const toLowercaseString = (value: string | number) => {
  return value.toString().toLowerCase()
}

export const isValidHTMLString = (string: string) => {
  // HTML tag regex borrowed from https://techstacker.com/regex-find-every-html-tag-document/
  const htmlTags = string.match(/<[^<>]+>/gm)

  // If no match, it's a string with no tags
  if (!htmlTags) {
    return false
  }

  // Example: <a> or <strong>
  const openingTags = htmlTags.filter((tag: string) => tag.charAt(1) !== "/")

  // Example: </a> or </strong>
  const closingTags = htmlTags.filter((tag: string) => tag.charAt(1) === "/")

  // An opening tag never closes
  if (openingTags.length > closingTags.length) {
    console.error("Field HTML error: Extra opening tag(s) found.")
    return false
  }

  // A duplicate closing tag
  if (openingTags.length < closingTags.length) {
    console.error("Field HTML error: Extra closing tag(s) found.")
    return false
  }

  // This string has HTML to parse!
  return true
}
