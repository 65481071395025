import { rgbToRgba } from "utilities/colors"

const primaryColor = "rgb(2, 107, 84)"

const primaryColorAlpha = (opacity: number) => {
  return rgbToRgba(primaryColor, opacity)
}

const secondaryColor = "#FFC325"

const white = {
  100: "#FFFFFF",
  99: "#FCFCFC",
  98: "#FAFAFA",
  96: "#F5F5F5",
  92: "#EBEBEB",
}

const black = "#000000"

// 👆 variables only used in this file
// -------------------------------------
// 👇 named variables used in the theme

export const colors = {
  bodyColor: "#323B48",
  bodyColorLight: "#5B6470",

  borderColor: primaryColorAlpha(0.5),

  buttonLinkColor: primaryColor,
  buttonLinkActiveColor: "#095F4A",
  buttonLinkFocusColor: primaryColor,
  buttonLinkHoverColor: "#005A47",

  black: black,
  white: white[100],

  checkedBoxMarkColor: white[100],
  checkedBoxBackgroundColor: primaryColor,

  error: "#B01C2E",
  errorText: "#B71E25",

  formInputBackgroundColor: "#E5F0EE",
  formInputBorderColor: primaryColorAlpha(0.5),
  formInputFocusedBackgroundColor: primaryColorAlpha(0.02),
  formInputFocusedBorderColor: "#006B54",
  formInputHoveredBorderColor: primaryColor,
  formInputTextColor: black,
  formLabelColor: black,

  headingColor: primaryColor,

  navLinkColor: "#40907F",
  navLinkActiveColor: "#006B54",
  navLinkActiveAccentColor: secondaryColor,
  navLinkHoverBackgroundColor: white[96],
  navLinkHoverColor: "#3d8d7c",

  offWhite: white[99],

  pageBgColor: white[100],
  pageBgColorShade1: white[98],
  pageBgColorShade2: white[96],
  pageBgColorShade3: white[92],

  primaryColor: primaryColor,

  secondaryColor: secondaryColor,

  siteFooterBackgroundColor: primaryColor,
  siteFooterLinkColor: "#0D736A",
  siteFooterLinkHoverColor: "#1BA094",

  siteHeaderBackgroundColor: primaryColor,
}
