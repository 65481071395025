import { useContext } from "react"
import { forwardRef } from "@chakra-ui/react"
import FormContext from "context/FormContext"

// TODO: I believe forwardRef is needed to clear console error
// import {forwardRef} from "@chakra-ui/react"
import {
  Searchbox,
  SearchOption,
  filterOptionsBySearchTerm,
} from "components/Searchbox"
import { titleize } from "utilities/formatters"
import { CustomSearch, HighSchoolSearchResponse } from "api/customSearch"
import { useLocalStorage } from "hooks"

// I think this should be defined here since this is the only place I think it will be used
// But I could see removing this and defining the object explicitly on the
// HighSchoolSearchProps in customSearch.ts
export interface HighSchoolData {
  OrgName: string
  StateCd: string
  City: string
  CeebCode: string
}

interface SchoolOption extends SearchOption {
  ceebCode: string
}

export const HighSchoolSearch = forwardRef<any, "div">((props, ref) => {
  const { name, ...rest } = props
  const {
    form: { getValues },
  } = useContext(FormContext)

  const token = useLocalStorage("token", undefined)
  const formValues = getValues()

  const searchHighSchools = (searchTerm = ""): Promise<SchoolOption[]> => {
    // TODO: The type wouldn't accept SchoolData or SchoolData[] so it's 'any'
    const mapResponseToOptions = (response: HighSchoolSearchResponse) => {
      return response.data.map(
        ({ OrgName: org, StateCd: state, City: city, CeebCode: ceebCode }) => {
          const schoolName = titleize(org.trim())
          let schoolLocation = `${titleize(city || "")}`
          if (state) {
            schoolLocation += `, ${state}`
          }
          return {
            label: `${schoolName} ${schoolLocation}`,
            value: `${schoolName} ${schoolLocation}`,
            ceebCode,
          }
        }
      )
    }

    const schoolNotFound: SchoolOption = {
      label: "I don't see my school",
      value: "I don't see my school",
      ceebCode: "",
    }

    return CustomSearch.highSchoolSearch(searchTerm, token).then(res => {
      if (!res) {
        return []
      }
      if (res.status !== 200) {
        return []
      }
      const options = mapResponseToOptions(res)
      const filteredOptions = filterOptionsBySearchTerm(options, searchTerm)

      return filteredOptions.concat(schoolNotFound)
    })
  }

  return (
    <Searchbox
      ref={ref}
      name={props.name}
      defaultValue={formValues[name]}
      loadOptions={searchHighSchools}
      {...rest}
    />
  )
})
