import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Alert,
  Flex,
  AlertTitle,
  AlertDescription,
  Button,
  Text,
} from "@chakra-ui/react"
import { H4 } from "components/common"
import { ErrorIcon } from "components/ErrorIcon"
import { Dispatch, SetStateAction } from "react"

interface DebugSubmissionModalProps {
  handleSubmitApplication(): void
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  setShowDebugModal: Dispatch<SetStateAction<boolean>>
}

export const DebugSubmissionModal = ({
  handleSubmitApplication,
  setIsSubmitting,
  setShowDebugModal,
}: DebugSubmissionModalProps) => {
  const clearSubmission = () => {
    setIsSubmitting(false)
    setShowDebugModal(false)
  }

  const completeSubmission = () => {
    handleSubmitApplication()
    setShowDebugModal(false)
  }

  const ClearValueButton = () => {
    return (
      <Button
        variant="outline"
        leftIcon={<ChevronLeftIcon />}
        mr={4}
        onClick={clearSubmission}
      >
        Cancel Submission
      </Button>
    )
  }

  const AdvanceToRedirectButton = () => {
    return (
      <Button
        variant="solid"
        rightIcon={<ChevronRightIcon />}
        onClick={completeSubmission}
      >
        Continue
      </Button>
    )
  }

  return (
    <Modal
      isOpen={true}
      onClose={clearSubmission}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        mt={48}
        borderStyle="solid"
        borderColor="transparent"
        borderLeftWidth={0}
        borderBottom={0}
      >
        <Alert
          variant="left-accent"
          borderColor="error"
          background="white"
          borderRadius="md"
          borderLeftWidth={8}
          boxShadow="lg"
          alignItems="flex-start"
          py={6}
        >
          <ErrorIcon color="error" mr={6} mt={-1.85} boxSize={5} />
          <Flex direction="column">
            <AlertTitle color="errorText" as={H4}>
              Submission Complete!
            </AlertTitle>
            <AlertDescription mt={4}>
              <Text>
                In production a user would now be sent to a confirmation page.
              </Text>
              <Text>
                Click "Unsubmit Application" to undo submission and return to
                the app or "Continue" to complete the process.
              </Text>
            </AlertDescription>
            <Flex mt="4">
              <ClearValueButton />
              <AdvanceToRedirectButton />
            </Flex>
          </Flex>
        </Alert>
      </ModalContent>
    </Modal>
  )
}
