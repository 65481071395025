import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Alert,
  Flex,
  AlertTitle,
  AlertDescription,
  Button,
} from "@chakra-ui/react"
import { H4 } from "components/common"
import { ErrorIcon } from "components/ErrorIcon"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "utilities/constants"

interface ErrorFallbackModalProps {
  error: any
}

const ErrorFallbackModal = ({ error }: ErrorFallbackModalProps) => {
  const navigate = useNavigate()
  const isAuthError = error.message.includes("AuthenticationWrapper")

  const authCTA = "Log back in"
  const generalCTA = "Refresh this Page"

  const ctaTemplate = (cta: string) =>
    `We've encountered an unexpected error. Please ${cta.toLowerCase()} to pick up where you left off.`

  const errorMessage = isAuthError
    ? ctaTemplate(authCTA)
    : ctaTemplate(generalCTA)

  const ctaButtonText = isAuthError ? authCTA : generalCTA

  const refreshPage = () => window.location.reload()

  const reAuthUser = () => {
    navigate(AppRoutes.logout)
    refreshPage()
  }

  const onAction = () => (isAuthError ? reAuthUser() : refreshPage())

  return (
    <Modal
      isOpen={true}
      onClose={onAction}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        mt={48}
        borderStyle="solid"
        borderColor="transparent"
        borderLeftWidth={0}
        borderBottom={0}
      >
        <Alert
          variant="left-accent"
          borderColor="error"
          background="white"
          borderRadius="md"
          borderLeftWidth={8}
          boxShadow="lg"
          alignItems="flex-start"
          py={6}
        >
          <ErrorIcon color="error" mr={6} mt={-1.85} boxSize={5} />
          <Flex direction="column">
            <AlertTitle color="errorText" as={H4}>
              We're sorry! Something went wrong.
            </AlertTitle>
            <AlertDescription mt={2}>{errorMessage}</AlertDescription>
            <Flex mt="4">
              <Button
                variant="link"
                rightIcon={<ChevronRightIcon />}
                onClick={onAction}
              >
                {ctaButtonText}
              </Button>
            </Flex>
          </Flex>
        </Alert>
      </ModalContent>
    </Modal>
  )
}

export default ErrorFallbackModal
