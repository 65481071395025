import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { FinalCheckCard } from "components/FinalCheckCard"
import { ApplicationData, ContactInfo, FieldMetaData } from "types"
import {
  getSectionMetaData,
  getInvalidSections,
  transformDataToServerFormat,
} from "utilities/helpers"
import { AccordionCardStack, ExternalLink, H1, Span } from "components/common"
import { PageHeader } from "components/LayoutComponents"
import { ChevronLeftIcon } from "@chakra-ui/icons"
import { Stack, Button, Text, Box } from "@chakra-ui/react"
import FormContext from "context/FormContext"
import { TealiumLinkTrack } from "tealium"
import { IS_PRODUCTION } from "utilities/constants"
import { useNavigate } from "react-router-dom"
import { UserContext } from "context/UserContext"
import { ClientContext } from "context/ClientContext"

import { DebugSubmissionModal } from "components/DebugSubmissionModal"
import { dehyphenate } from "utilities/formatters"
import { COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT } from "data"

interface FinalCheckPageProps {
  applicationData: ApplicationData
  setIsAppComplete: Dispatch<SetStateAction<boolean>>
}

export const FinalCheckPage = ({
  applicationData,
  setIsAppComplete,
}: FinalCheckPageProps) => {
  const {
    onSave,
    onSubmit,
    form: { formState, getValues },
  } = useContext(FormContext)
  const { user, setUser } = useContext(UserContext)
  const { client } = useContext(ClientContext)
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSubmissionFailed, setHasSubmissionFailed] = useState(false)
  const [showDebugModal, setShowDebugModal] = useState(false)

  const [initialInvalidSections, setInitialInvalidSections] = useState<
    FieldMetaData[]
  >([])
  const [invalidSections, setInvalidSections] = useState<Map<string, string[]>>(
    new Map()
  )
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  // Send tealium event on page load
  useEffect(() => {
    TealiumLinkTrack({
      page: "final_check_page",
      email_address: user.email,
      tealium_event: "app_entered_validation",
    })
    // eslint-disable-next-line
  }, [])

  // Setup the initial world of questions to answer
  useEffect(() => {
    if (isDataLoaded) {
      return
    }
    setIsDataLoaded(false)

    const currentValues = getValues()
    const arrayToCheck = COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT[client.theme]

    const formValues = transformDataToServerFormat(currentValues, arrayToCheck)

    const invalidSections = getInvalidSections({
      applicationData: applicationData,
      formValues: formValues,
      errors: formState.errors,
      currentValues,
    })

    const invalidSectionsMetaData = invalidSections.map(section => {
      return getSectionMetaData(applicationData, section)
    })

    setInitialInvalidSections(invalidSectionsMetaData)
    setIsDataLoaded(true)
    // eslint-disable-next-line
  }, [applicationData, formState])

  const updateSectionErrors = (
    sectionTitle: string,
    sectionErrors: string[]
  ) => {
    // N.B: This has to be functional setState since each card calls it at the same time on load
    setInvalidSections(prevState => {
      const newInvalidSections = new Map(prevState)
      newInvalidSections.set(sectionTitle, sectionErrors)
      return newInvalidSections
    })
  }

  // TODO: This is copied from InputSwitch.
  // It should be a helper function or custom hook used in both places
  const saveData = async () => {
    try {
      const user = await onSave()
      if (user) {
        setUser(user)
        return user
      } else {
        throw new Error("No user found")
      }
    } catch (err) {
      throw err
    }
  }

  const handleAppSubmissionClicked = () => {
    return IS_PRODUCTION
      ? handleSubmitApplication()
      : handleDebugAppCompletion()
  }

  const handleBackButtonClicked = () => {
    saveData()
    navigate(applicationData.pages[0].slug)
  }

  const handleDebugAppCompletion = () => {
    setShowDebugModal(true)
  }

  const handleSubmitApplication = async () => {
    setIsSubmitting(true)

    try {
      await saveData()
      const user = await onSubmit()

      if (user && user?.submittedAt) {
        setIsAppComplete(true)
      } else {
        setHasSubmissionFailed(true)
      }
    } catch (err) {
      console.error("Error during handleSubmitApplication", err)
      setHasSubmissionFailed(true)
    } finally {
      setIsSubmitting(false)
    }
  }
  const errorsArePresent = Array.from(invalidSections.values()).some(
    sectionErrors => sectionErrors.length > 0
  )

  return (
    <>
      <PageHeader textAlign="center" className="final-check">
        {hasSubmissionFailed ? (
          <SubmissionFailureMessage contact={applicationData.meta.contact} />
        ) : errorsArePresent ? (
          <AppIncompleteMessage />
        ) : (
          <AppCompleteMessage />
        )}
      </PageHeader>
      {!!initialInvalidSections.length && (
        <AccordionCardStack
          allowMultiple={false}
          allowToggle
          spacing={{ base: 0, md: 8, lg: 12 }}
          marginBottom={{ base: 8, md: 8, lg: 12 }}
        >
          {initialInvalidSections.map(({ page, pageNumber, section }) => {
            return (
              <FinalCheckCard
                key={`p${page.id}_${
                  section.title || section.nestedSectionTitle
                }`}
                pageNumber={pageNumber}
                pageSlug={page.slug}
                pageTitle={page.title}
                sectionData={section}
                sectionErrors={invalidSections.get(section.title) || []}
                updateSectionErrors={updateSectionErrors}
                client={client.theme}
              />
            )
          })}
        </AccordionCardStack>
      )}

      <Stack
        alignItems="center"
        marginBottom={{ base: 8, md: 12 }}
        paddingX={{ base: 4, md: 0 }}
        spacing={{ base: 6, md: 8 }}
        sx={{ "& > button": { whiteSpace: "break-spaces" } }}
      >
        <Button
          onClick={handleAppSubmissionClicked}
          isDisabled={errorsArePresent}
          isLoading={isSubmitting}
          loadingText="Submitting..."
          className="final-check__submit-button"
          size="lg"
          title={
            errorsArePresent
              ? "All form errors must be fixed before submission"
              : null
          }
        >
          Submit Application to {applicationData.meta.name}
        </Button>
        <Button
          id="double-check"
          onClick={handleBackButtonClicked}
          size="lg"
          variant="link"
        >
          <ChevronLeftIcon mr={2} />I want to double check everything
        </Button>
      </Stack>
      {showDebugModal && (
        <DebugSubmissionModal
          setIsSubmitting={setIsSubmitting}
          handleSubmitApplication={handleSubmitApplication}
          setShowDebugModal={setShowDebugModal}
        />
      )}
    </>
  )
}

const AppCompleteMessage = () => {
  return (
    <>
      <H1 mb="4">Ready to apply?</H1>
      <Text>
        Great job! Your application is complete. Submit it below when you're
        ready.
      </Text>
    </>
  )
}

const AppIncompleteMessage = () => {
  return (
    <>
      <H1 mb="4">Almost Done</H1>
      <Text>
        You have a few items to review before you can submit your application.
      </Text>
    </>
  )
}

const SubmissionFailureMessage = ({ contact }: { contact?: ContactInfo }) => {
  return (
    <>
      <H1 mb="4">Hmm, something's gone wrong.</H1>
      <Text>
        Your application is complete but has not been submitted successfully.
        Please try again.
        {contact && (
          <Text>
            If this problem continues, please contact us at{" "}
            <ExternalLink
              href={`mailto:${contact.email}`}
              children={contact.email}
              color="buttonLinkColor"
            />{" "}
            or{" "}
            <ExternalLink
              href={`tel:${dehyphenate(contact.phone)}`}
              children={contact.phone}
              color="buttonLinkColor"
            />
            <Span ml={-1}>.</Span>
          </Text>
        )}
      </Text>
    </>
  )
}
