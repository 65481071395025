import { colors } from "themes/siena/colors"

const blackAlpha = (alpha: number) => `rgba(0, 0, 0, 0.${alpha})`

export const shadows = {
  buttonActive: `2px 2px 4px 0 ${blackAlpha(25)})`,
  buttonFocus: `
    0 4px 4px ${blackAlpha(25)}, 
    0 4px 4px ${blackAlpha(25)}, 
    0 0 9px #006B54,
    inset 0 0 0 2px #004D3D`,
  buttonHover: `1px 1px 6px 0 ${blackAlpha(60)}`,
  buttonRest: `0 2px 8px 0 ${blackAlpha(25)}`,

  card: `0 2px 8px 0 ${blackAlpha(25)}`,

  inputFocused: `1px 2px 6px 0 ${blackAlpha(45)}`,
  inputFocusedError: `0 0 6px 0 ${colors.error}`,

  radioHeavy: `1px 2px 4px 0 ${blackAlpha(50)}`,
  radioLight: `1px 2px 4px 0 ${blackAlpha(25)}`,

  checkboxHeavy: `1px 2px 6px 0 ${blackAlpha(45)}`,
  checkboxLight: `1px 2px 6px 0 ${blackAlpha(25)}`,

  siteFooter: `0px -4px 4px ${blackAlpha(15)}`,
  siteHeader: `0 4px 4px 0 ${blackAlpha(25)}`,
}
