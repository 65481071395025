export const SPORTS = [
  { label: "Archery", value: "Archery" },
  { label: "Badminton", value: "Badminton" },
  { label: "Baseball", value: "Baseball" },
  { label: "Basketball", value: "Basketball" },
  { label: "Bowling", value: "Bowling" },
  { label: "Boxing", value: "Boxing" },
  { label: "Cheerleading", value: "Cheerleading" },
  { label: "Cricket", value: "Cricket" },
  { label: "Cross-country", value: "Cross-country" },
  { label: "Diving", value: "Diving" },
  { label: "Equestrian", value: "Equestrian" },
  { label: "Fencing", value: "Fencing" },
  { label: "Field Hockey", value: "Field Hockey" },
  { label: "Figure Skating", value: "Figure Skating" },
  { label: "Football", value: "Football" },
  {
    label: "Football (non-tackle)",
    value: "Football (non-tackle)",
  },
  { label: "Golf", value: "Golf" },
  { label: "Gymnastics", value: "Gymnastics" },
  { label: "Handball", value: "Handball" },
  { label: "Ice Hockey", value: "Ice Hockey" },
  { label: "Indoor Track", value: "Indoor Track" },
  { label: "Judo", value: "Judo" },
  { label: "Lacrosse", value: "Lacrosse" },
  { label: "Other Sport", value: "Other Sport" },
  { label: "Outdoor Track", value: "Outdoor Track" },
  { label: "Racquetball", value: "Racquetball" },
  { label: "Rifle", value: "Rifle" },
  {
    label: "Rock climbing, Bouldering",
    value: "Rock climbing, Bouldering",
  },
  { label: "Rodeo", value: "Rodeo" },
  { label: "Rowing (Crew)", value: "Rowing (Crew)" },
  { label: "Rugby", value: "Rugby" },
  { label: "Sailing", value: "Sailing" },
  { label: "Skiing", value: "Skiing" },
  { label: "Skin Diving", value: "Skin Diving" },
  { label: "Soccer", value: "Soccer" },
  { label: "Softball", value: "Softball" },
  { label: "Squash", value: "Squash" },
  { label: "Swim", value: "Swim" },
  { label: "Sync. Swim", value: "Sync. Swim" },
  { label: "Table Tennis", value: "Table Tennis" },
  { label: "Tennis", value: "Tennis" },
  { label: "Track and Field", value: "Track and Field" },
  { label: "Triathalon", value: "Triathalon" },
  { label: "Ultimate Frisbee", value: "Ultimate Frisbee" },
  { label: "Volleyball", value: "Volleyball" },
  { label: "Water Polo", value: "Water Polo" },
  { label: " Weight Lifting", value: " Weight Lifting" },
  { label: "Wrestling", value: "Wrestling" },
]
