// Returned from `type: "tel"` in college data
/**
 * TODO: Replace the content of this file with "PhoneInput2.tsx".
 *
 * Check the styles of apps that use it. Reference Hollins for styling, determine what can be used in "base" theme.
 */

import { useEffect, useState, useRef } from "react"
import {
  forwardRef,
  useStyleConfig,
  FormLabel as ChakraFormLabel,
  Flex,
  Text,
} from "@chakra-ui/react"
import { ErrorIcon } from "components/ErrorIcon"

import ReactPhoneInput from "react-phone-input-2"

import "react-phone-input-2/lib/style.css"

export const PhoneInput = forwardRef((props, ref) => {
  const [inputWidth, setInputWidth] = useState<number>(0)
  const [placeholder, setPlaceholder] = useState<string>("+.. (...) ..-..-..")
  const [isInvalid, setInvalidState] = useState<string>(props.isInvalid || "")
  const styles: any = useStyleConfig("PhoneInput")
  const helperTextStyles: any = useStyleConfig("HelperText")

  const containerClass = isInvalid ? "error" : ""

  const inpRef = useRef()

  useEffect(() => {
    const handleResize = () =>
      inpRef.current && setInputWidth(inpRef.current["offsetWidth"])
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (inpRef.current) {
      setInputWidth(inpRef.current["offsetWidth"])
    }
  }, [inpRef])

  useEffect(() => {
    setInvalidState(props.isInvalid)
  }, [props.isInvalid])

  return (
    <div ref={inpRef}>
      {props.label && (
        <ChakraFormLabel sx={{ marginBottom: "4px" }}>
          {props.label}
          {props.isRequired && (
            <Text display="inline" color="red.500">
              &nbsp;*
            </Text>
          )}
        </ChakraFormLabel>
      )}

      {props.helperText && (
        <Text {...helperTextStyles}>{props.helperText}</Text>
      )}

      <ReactPhoneInput
        priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
        enableSearch={true}
        country="us"
        disableDropdown={false}
        preferredCountries={["us", "ca", "au"]}
        preserveOrder={["preferredCountries"]}
        disableSearchIcon={true}
        searchPlaceholder="Search"
        dropdownStyle={{ overflowX: "hidden", width: `${inputWidth}px` }}
        onChange={(value, country, e, formattedValue) => {
          /* @ts-ignore */
          setPlaceholder(country.format)
          /* @ts-ignore */
          props.onChange(`+${country.dialCode}`)
        }}
        inputProps={{
          onChange: props.onChange,
          maxLength: "25",
        }}
        value={props.value}
        onBlur={props.onBlur}
        {...styles}
        localization={{ "United States": "United States of America" }}
        containerClass={containerClass}
        placeholder={placeholder}
        enableLongNumbers={true}
      />
      {props.errorText && (
        <Flex alignItems="center" mt={1}>
          <ErrorIcon color="errorText" mr={1} w={4} h={4} />
          <span style={{ fontSize: "14px", color: "#B71E25" }}>
            {props.errorText}
          </span>
        </Flex>
      )}
    </div>
  )
})
