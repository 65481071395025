import { useLocation } from "react-router-dom"
import { PageData } from "types"
import {
  Box,
  Stack,
  forwardRef,
  useStyleConfig,
  useTheme,
} from "@chakra-ui/react"
import { CSSObject } from "@emotion/react"
import { RouterNavLink, Span } from "components/common"
import {
  ContentWrapper,
  ContentWrapperProps,
} from "components/LayoutComponents"
import { useMaxWidth } from "hooks"
import { SelectMenuNav } from "./SelectMenuNav"

export interface NavProps extends ContentWrapperProps {
  layoutLinkStyles?: CSSObject
  navLinkSpacing?: number | { [key: string]: number }
  pages: PageData[]
}
export const Nav = forwardRef<NavProps, "nav">(
  ({ layoutLinkStyles, navLinkSpacing = 0, pages, ...rest }, ref) => {
    const styles = useStyleConfig("Nav")
    const navItemStyles = useStyleConfig("NavItem")

    const theme = useTheme()
    const { isMaxTabletView } = useMaxWidth()
    const navLinkStyles = {
      alignItems: "center",
      borderLeft: "4px solid transparent",
      display: "flex",
      paddingX: 6,
      paddingY: 4,
      whiteSpace: "nowrap",
      color: "navLinkColor",
      activeStyle: {
        borderLeft: "4px solid",
        borderLeftColor: theme.colors["navLinkActiveAccentColor"],
        boxShadow: "none",
        color: theme.colors["navLinkActiveColor"],
      },
      _hover: {
        backgroundColor: "navLinkHoverBackgroundColor",
        color: "navLinkHoverColor",
        textDecoration: "none",
      },
      ...layoutLinkStyles,
      ...navItemStyles,
    }

    return (
      <Box
        as="nav"
        gridArea="nav"
        ref={ref}
        __css={styles}
        className="app-nav"
        {...rest}
      >
        <ContentWrapper paddingX={isMaxTabletView ? 0 : null}>
          {isMaxTabletView ? (
            <MobileNav pages={pages} />
          ) : (
            <Stack spacing={navLinkSpacing}>
              {pages.map((page, index) => (
                <RouterNavLink
                  key={page.id}
                  to={`/${page.slug}`}
                  {...navLinkStyles}
                >
                  <Span className="nav-numbers">{index + 1}</Span>
                  <Span paddingRight={2}>{page.title}</Span>
                </RouterNavLink>
              ))}
            </Stack>
          )}
        </ContentWrapper>
      </Box>
    )
  }
)

const MobileNav = ({ pages }: { pages: PageData[] }) => {
  const location = useLocation()
  const currentPage = location.pathname.slice(1)

  return (
    <SelectMenuNav id="mobile-nav" size="sm">
      {pages.map(({ slug, id, title, mobileNavTitle }) => (
        <option key={id} value={slug} disabled={slug === currentPage}>
          {mobileNavTitle || title}
        </option>
      ))}
    </SelectMenuNav>
  )
}
