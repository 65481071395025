export const HOLLINS_MAJORS: [string, string][] = [
  ["Art History", "ARTH"],
  ["Art Studio", "ARTS"],
  ["Biology", "BIOL"],
  ["Business", "BUSI"],
  ["Chemistry", "CHEM"],
  ["Classical Studies", "CLAS"],
  ["Communication Studies", "COMM"],
  ["Creative Writing", "CREA"],
  ["Dance", "THDA"],
  ["Economics", "ECON"],
  ["Education", "EDUC"],
  ["English", "ENGL"],
  ["Environmental Science", "ENVI"],
  ["Environmental Studies", "ENST"],
  ["Film", "THFI"],
  ["Fine Arts", "FIAR"],
  ["French", "FREN"],
  ["History", "HIST"],
  ["International Studies", "INTL"],
  ["Mathematics", "MATH"],
  ["Music", "MUSC"],
  ["Other", "OTHE"],
  ["Philosophy", "PHIL"],
  ["Physics", "PHYS"],
  ["Political Science", "POSC"],
  ["Prelaw", "PLAW"],
  ["Premedicine", "PMED"],
  ["PreVeterinary Medicine", "PVET"],
  ["Psychology", "PSYC"],
  ["Public Health", "PBHL"],
  ["Religion", "RELG"],
  ["Sociology", "SOCI"],
  ["Spanish", "SPAN"],
  ["Theatre", "THTR"],
  ["Undecided", "UNDE"],
  ["Womens Studies", "WMST"],
]
