import { useNavigate } from "react-router-dom"
import { Button, ButtonProps } from "@chakra-ui/react"

export interface RouterButtonProps extends ButtonProps {
  to: string
}
export const RouterButton = ({ to, ...rest }: RouterButtonProps) => {
  const navigate = useNavigate()
  return <Button onClick={() => navigate(to)} {...rest} />
}
