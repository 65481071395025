export const IB_SUBJECTS = [
  { value: "Afrikaans A", label: "Afrikaans A" },
  {
    value: "Afrikaans A: Literature",
    label: "Afrikaans A: Literature",
  },
  { value: "Akan A", label: "Akan A" },
  { value: "Akan A: Literature", label: "Akan A: Literature" },
  { value: "Albanian A", label: "Albanian A" },
  {
    value: "Albanian A: Literature",
    label: "Albanian A: Literature",
  },
  { value: "Amharic A", label: "Amharic A" },
  {
    value: "Amharic A: Literature",
    label: "Amharic A: Literature",
  },
  { value: "Arabic A", label: "Arabic A" },
  {
    value: "Arabic A: Language and Literature",
    label: "Arabic A: Language and Literature",
  },
  {
    value: "Arabic A: Literature",
    label: "Arabic A: Literature",
  },
  { value: "Arabic AB", label: "Arabic AB" },
  { value: "Arabic B", label: "Arabic B" },
  { value: "Armenian A", label: "Armenian A" },
  {
    value: "Armenian A: Literature",
    label: "Armenian A: Literature",
  },
  { value: "Art History", label: "Art History" },
  { value: "Assyrian A", label: "Assyrian A" },
  {
    value: "Assyrian A: Literature",
    label: "Assyrian A: Literature",
  },
  { value: "Astronomy", label: "Astronomy" },
  { value: "Azebaijani A", label: "Azebaijani A" },
  {
    value: "Azerbaijani A: Literature",
    label: "Azerbaijani A: Literature",
  },
  { value: "Beginning Hindi", label: "Beginning Hindi" },
  { value: "Beginning Siswati", label: "Beginning Siswati" },
  {
    value: "Belarusian A: Literature",
    label: "Belarusian A: Literature",
  },
  { value: "Bemba A", label: "Bemba A" },
  { value: "Bemba A: Literature", label: "Bemba A: Literature" },
  { value: "Biology", label: "Biology" },
  { value: "Bosnian A", label: "Bosnian A" },
  {
    value: "Bosnian A: Literature",
    label: "Bosnian A: Literature",
  },
  {
    value: "Brazilian Social Studies",
    label: "Brazilian Social Studies",
  },
  { value: "Bulgarian A", label: "Bulgarian A" },
  {
    value: "Bulgarian A: Literature",
    label: "Bulgarian A: Literature",
  },
  { value: "Burmese A", label: "Burmese A" },
  {
    value: "Burmese A: Literature",
    label: "Burmese A: Literature",
  },
  { value: "Business Management", label: "Business Management" },
  { value: "Catalan A", label: "Catalan A" },
  {
    value: "Catalan A: Literature",
    label: "Catalan A: Literature",
  },
  { value: "Chemistry", label: "Chemistry" },
  { value: "Chichewa A", label: "Chichewa A" },
  {
    value: "Chichewa A: Literature",
    label: "Chichewa A: Literature",
  },
  { value: "Chile &amp; Pac.", label: "Chile &amp; Pac." },
  { value: "Chinese A", label: "Chinese A" },
  {
    value: "Chinese A: Language and Literature",
    label: "Chinese A: Language and Literature",
  },
  {
    value: "Chinese A: Literature",
    label: "Chinese A: Literature",
  },
  { value: "Chinese B", label: "Chinese B" },
  {
    value: "Chinese B - Cantonese",
    label: "Chinese B - Cantonese",
  },
  {
    value: "Chinese B - Mandarin",
    label: "Chinese B - Mandarin",
  },
  { value: "Classical Greek", label: "Classical Greek" },
  {
    value: "Classical Greek and Roman Studies",
    label: "Classical Greek and Roman Studies",
  },
  { value: "Computer Science", label: "Computer Science" },
  { value: "Croatian A", label: "Croatian A" },
  {
    value: "Croatian A: Literature",
    label: "Croatian A: Literature",
  },
  { value: "Czech A", label: "Czech A" },
  { value: "Czech A: Literature", label: "Czech A: Literature" },
  { value: "Dance", label: "Dance" },
  { value: "Danish A", label: "Danish A" },
  {
    value: "Danish A: Literature",
    label: "Danish A: Literature",
  },
  { value: "Danish AB", label: "Danish AB" },
  { value: "Danish B", label: "Danish B" },
  { value: "Design Tech.", label: "Design Tech." },
  { value: "Dhivehi A", label: "Dhivehi A" },
  {
    value: "Dhivehi A: Literature",
    label: "Dhivehi A: Literature",
  },
  { value: "Dutch A", label: "Dutch A" },
  {
    value: "Dutch A: Language and Literature",
    label: "Dutch A: Language and Literature",
  },
  { value: "Dutch A: Literature", label: "Dutch A: Literature" },
  { value: "Dutch AB", label: "Dutch AB" },
  { value: "Dutch B", label: "Dutch B" },
  { value: "Dzongkha A", label: "Dzongkha A" },
  {
    value: "Dzongkha A: Literature",
    label: "Dzongkha A: Literature",
  },
  { value: "Economics", label: "Economics" },
  { value: "Efik A", label: "Efik A" },
  { value: "English A", label: "English A" },
  {
    value: "English A: Language and Literature",
    label: "English A: Language and Literature",
  },
  {
    value: "English A: Literature",
    label: "English A: Literature",
  },
  { value: "English AB", label: "English AB" },
  { value: "English B", label: "English B" },
  {
    value: "Environmental Systems and Societies",
    label: "Environmental Systems and Societies",
  },
  { value: "Estonian A", label: "Estonian A" },
  {
    value: "Estonian A: Literature",
    label: "Estonian A: Literature",
  },
  { value: "Faroese A", label: "Faroese A" },
  {
    value: "Faroese A: Literature",
    label: "Faroese A: Literature",
  },
  { value: "Fijian A", label: "Fijian A" },
  { value: "Filipino A", label: "Filipino A" },
  {
    value: "Filipino A: Literature",
    label: "Filipino A: Literature",
  },
  { value: "Film", label: "Film" },
  { value: "Finnish A", label: "Finnish A" },
  {
    value: "Finnish A: Literature",
    label: "Finnish A: Literature",
  },
  { value: "Finnish B", label: "Finnish B" },
  {
    value: "Food and Science and Technology",
    label: "Food and Science and Technology",
  },
  { value: "French A", label: "French A" },
  {
    value: "French A: Language and Literature",
    label: "French A: Language and Literature",
  },
  {
    value: "French A: Literature",
    label: "French A: Literature",
  },
  { value: "French AB", label: "French AB" },
  { value: "French B", label: "French B" },
  { value: "Further Mathematics", label: "Further Mathematics" },
  {
    value: "Gaelic A: Literature",
    label: "Gaelic A: Literature",
  },
  { value: "Galician A", label: "Galician A" },
  { value: "Geography", label: "Geography" },
  { value: "Georgian A", label: "Georgian A" },
  {
    value: "Georgian A: Literature",
    label: "Georgian A: Literature",
  },
  { value: "German A", label: "German A" },
  {
    value: "German A: Language and Literature",
    label: "German A: Language and Literature",
  },
  {
    value: "German A: Literature",
    label: "German A: Literature",
  },
  { value: "German AB", label: "German AB" },
  { value: "German B", label: "German B" },
  { value: "Global Politics", label: "Global Politics" },
  { value: "Greenlandic A", label: "Greenlandic A" },
  {
    value: "Greenlandic A: Literature",
    label: "Greenlandic A: Literature",
  },
  { value: "Gujarati A", label: "Gujarati A" },
  { value: "Hebrew A", label: "Hebrew A" },
  {
    value: "Hebrew A: Literature",
    label: "Hebrew A: Literature",
  },
  { value: "Hebrew B", label: "Hebrew B" },
  { value: "Hindi A", label: "Hindi A" },
  { value: "Hindi A: Literature", label: "Hindi A: Literature" },
  { value: "Hindi B", label: "Hindi B" },
  { value: "History", label: "History" },
  { value: "History of Africa", label: "History of Africa" },
  {
    value: "History of Asia and Oceana",
    label: "History of Asia and Oceana",
  },
  {
    value: "History of Europe and the Middle East",
    label: "History of Europe and the Middle East",
  },
  {
    value: "History of the Americas",
    label: "History of the Americas",
  },
  { value: "Human Rights", label: "Human Rights" },
  { value: "Hungarian A", label: "Hungarian A" },
  {
    value: "Hungarian A: Literature",
    label: "Hungarian A: Literature",
  },
  { value: "Icelandic A", label: "Icelandic A" },
  {
    value: "Icelandic A: Literature",
    label: "Icelandic A: Literature",
  },
  { value: "Igbo A", label: "Igbo A" },
  { value: "Igbo A: Literature", label: "Igbo A: Literature" },
  { value: "Indonesian A", label: "Indonesian A" },
  {
    value: "Indonesian A: Language And Literature",
    label: "Indonesian A: Language And Literature",
  },
  {
    value: "Indonesian A: Literature",
    label: "Indonesian A: Literature",
  },
  { value: "Indonesian AB", label: "Indonesian AB" },
  { value: "Indonesian B", label: "Indonesian B" },
  {
    value: "Information Technology in a Global Society",
    label: "Information Technology in a Global Society",
  },
  { value: "Irish A", label: "Irish A" },
  { value: "Irish A: Literature", label: "Irish A: Literature" },
  { value: "Italian A", label: "Italian A" },
  {
    value: "Italian A: Language and Literature",
    label: "Italian A: Language and Literature",
  },
  {
    value: "Italian A: Literature",
    label: "Italian A: Literature",
  },
  { value: "Italian AB", label: "Italian AB" },
  { value: "Italian B", label: "Italian B" },
  { value: "Japanese A", label: "Japanese A" },
  {
    value: "Japanese A: Language and Literature",
    label: "Japanese A: Language and Literature",
  },
  {
    value: "Japanese A: Literature",
    label: "Japanese A: Literature",
  },
  { value: "Japanese AB", label: "Japanese AB" },
  { value: "Japanese B", label: "Japanese B" },
  { value: "Kannada A", label: "Kannada A" },
  { value: "Kazakh A", label: "Kazakh A" },
  {
    value: "Kazakh A: Literature",
    label: "Kazakh A: Literature",
  },
  { value: "Khmer A", label: "Khmer A" },
  { value: "Khmer A: Literature", label: "Khmer A: Literature" },
  { value: "Kinyarwanda A", label: "Kinyarwanda A" },
  {
    value: "Kinyarwanda A: Literature",
    label: "Kinyarwanda A: Literature",
  },
  { value: "Korean A", label: "Korean A" },
  {
    value: "Korean A: Language and Literature",
    label: "Korean A: Language and Literature",
  },
  {
    value: "Korean A: Literature",
    label: "Korean A: Literature",
  },
  { value: "Korean B", label: "Korean B" },
  { value: "Kurdish A", label: "Kurdish A" },
  {
    value: "Kurdish A: Literature",
    label: "Kurdish A: Literature",
  },
  { value: "Kyrgyz A", label: "Kyrgyz A" },
  { value: "Lao A", label: "Lao A" },
  { value: "Lao A: Literature", label: "Lao A: Literature" },
  { value: "Latin", label: "Latin" },
  { value: "Latvian A", label: "Latvian A" },
  {
    value: "Latvian A: Literature",
    label: "Latvian A: Literature",
  },
  {
    value: "Literature and Performance",
    label: "Literature and Performance",
  },
  { value: "Lithuanian A", label: "Lithuanian A" },
  {
    value: "Lithuanian A: Literature",
    label: "Lithuanian A: Literature",
  },
  { value: "Lozi A", label: "Lozi A" },
  { value: "Luganda A", label: "Luganda A" },
  {
    value: "Luganda A: Literature",
    label: "Luganda A: Literature",
  },
  { value: "Lunda A", label: "Lunda A" },
  { value: "Macedonian A", label: "Macedonian A" },
  {
    value: "Macedonian A: Literature",
    label: "Macedonian A: Literature",
  },
  { value: "Malagasy A", label: "Malagasy A" },
  {
    value: "Malagasy A: Literature",
    label: "Malagasy A: Literature",
  },
  { value: "Malay A", label: "Malay A" },
  { value: "Malay A: Literature", label: "Malay A: Literature" },
  { value: "Malay AB", label: "Malay AB" },
  { value: "Malayalam A", label: "Malayalam A" },
  { value: "Mandarin AB", label: "Mandarin AB" },
  { value: "Maori A", label: "Maori A" },
  { value: "Maori A: Literature", label: "Maori A: Literature" },
  { value: "Marathi A", label: "Marathi A" },
  {
    value: "Marathi A: Literature",
    label: "Marathi A: Literature",
  },
  { value: "Marine Science", label: "Marine Science" },
  {
    value: "Mathematical Studies",
    label: "Mathematical Studies",
  },
  { value: "Mathematics", label: "Mathematics" },
  {
    value: "Mathematics Analysis and Approaches",
    label: "Mathematics Analysis and Approaches",
  },
  {
    value: "Mathematics Applications and Interpretations",
    label: "Mathematics Applications and Interpretations",
  },
  {
    value: "Mathematics Calculus",
    label: "Mathematics Calculus",
  },
  {
    value: "Mathematics Discrete",
    label: "Mathematics Discrete",
  },
  { value: "Mathematics Sets", label: "Mathematics Sets" },
  {
    value: "Mathematics Statistics",
    label: "Mathematics Statistics",
  },
  { value: "Modern Greek A", label: "Modern Greek A" },
  {
    value: "Modern Greek A: Language and Literature",
    label: "Modern Greek A: Language and Literature",
  },
  {
    value: "Modern Greek A: Literature",
    label: "Modern Greek A: Literature",
  },
  {
    value: "Modern History of Kazakhstan",
    label: "Modern History of Kazakhstan",
  },
  { value: "Mongolian A", label: "Mongolian A" },
  { value: "Mongolian A", label: "Mongolian A" },
  { value: "Music", label: "Music" },
  { value: "Music Creating", label: "Music Creating" },
  {
    value: "Music Group Performance",
    label: "Music Group Performance",
  },
  {
    value: "Music Solo Performance",
    label: "Music Solo Performance",
  },
  { value: "Nature of Science", label: "Nature of Science" },
  { value: "Ndebele A", label: "Ndebele A" },
  {
    value: "Ndebele A: Literature",
    label: "Ndebele A: Literature",
  },
  { value: "Nepali A", label: "Nepali A" },
  {
    value: "Nepali A: Literature",
    label: "Nepali A: Literature",
  },
  { value: "Norwegian A", label: "Norwegian A" },
  {
    value: "Norwegian A: Language and Literature",
    label: "Norwegian A: Language and Literature",
  },
  {
    value: "Norwegian A: Literature",
    label: "Norwegian A: Literature",
  },
  { value: "Norwegian B", label: "Norwegian B" },
  { value: "Oromo A: Literature", label: "Oromo A: Literature" },
  {
    value: "Oshindonga A: Literature",
    label: "Oshindonga A: Literature",
  },
  { value: "Pashto A", label: "Pashto A" },
  {
    value: "Pashto A: Literature",
    label: "Pashto A: Literature",
  },
  {
    value: "Peace and Conflict Studies",
    label: "Peace and Conflict Studies",
  },
  { value: "Persian A", label: "Persian A" },
  {
    value: "Persian A: Literature",
    label: "Persian A: Literature",
  },
  { value: "Philosophy", label: "Philosophy" },
  { value: "Physics", label: "Physics" },
  { value: "Polish A", label: "Polish A" },
  {
    value: "Polish A: Literature",
    label: "Polish A: Literature",
  },
  { value: "Political Thought", label: "Political Thought" },
  { value: "Politics", label: "Politics" },
  { value: "Portuguese A", label: "Portuguese A" },
  {
    value: "Portuguese A: Language and Literature",
    label: "Portuguese A: Language and Literature",
  },
  {
    value: "Portuguese A: Literature",
    label: "Portuguese A: Literature",
  },
  { value: "Portuguese B", label: "Portuguese B" },
  { value: "Psychology", label: "Psychology" },
  { value: "Punjabi A", label: "Punjabi A" },
  {
    value: "Punjabi A: Literature",
    label: "Punjabi A: Literature",
  },
  { value: "Romanian A", label: "Romanian A" },
  {
    value: "Romanian A: Literature",
    label: "Romanian A: Literature",
  },
  { value: "Romansch A", label: "Romansch A" },
  { value: "Russian A", label: "Russian A" },
  {
    value: "Russian A: Language and Literature",
    label: "Russian A: Language and Literature",
  },
  {
    value: "Russian A: Literature",
    label: "Russian A: Literature",
  },
  { value: "Russian AB", label: "Russian AB" },
  { value: "Russian B", label: "Russian B" },
  { value: "Samoan A", label: "Samoan A" },
  {
    value: "Science, Technology and Society",
    label: "Science, Technology and Society",
  },
  { value: "Serbian A", label: "Serbian A" },
  {
    value: "Serbian A: Literature",
    label: "Serbian A: Literature",
  },
  { value: "Sesotho A", label: "Sesotho A" },
  {
    value: "Sesotho A: Literature",
    label: "Sesotho A: Literature",
  },
  { value: "Setswana A", label: "Setswana A" },
  { value: "Shona A", label: "Shona A" },
  { value: "Shona A: Literature", label: "Shona A: Literature" },
  { value: "Sinhalese A", label: "Sinhalese A" },
  {
    value: "Sinhalese A: Literature",
    label: "Sinhalese A: Literature",
  },
  { value: "Siswati A", label: "Siswati A" },
  {
    value: "Siswati A: Literature",
    label: "Siswati A: Literature",
  },
  { value: "Slovak A", label: "Slovak A" },
  {
    value: "Slovak A: Literature",
    label: "Slovak A: Literature",
  },
  { value: "Slovene A", label: "Slovene A" },
  {
    value: "Slovene A: Literature",
    label: "Slovene A: Literature",
  },
  {
    value: "Social and Cultural Anthropology",
    label: "Social and Cultural Anthropology",
  },
  { value: "Somali A", label: "Somali A" },
  {
    value: "Somali A: Literature",
    label: "Somali A: Literature",
  },
  { value: "Spanish A", label: "Spanish A" },
  {
    value: "Spanish A: Language and Literature",
    label: "Spanish A: Language and Literature",
  },
  {
    value: "Spanish A: Literature",
    label: "Spanish A: Literature",
  },
  { value: "Spanish AB", label: "Spanish AB" },
  { value: "Spanish B", label: "Spanish B" },
  {
    value: "Sports Exercise and Health Science",
    label: "Sports Exercise and Health Science",
  },
  { value: "Swahili A", label: "Swahili A" },
  {
    value: "Swahili A: Literature",
    label: "Swahili A: Literature",
  },
  { value: "Swahili AB", label: "Swahili AB" },
  { value: "Swahili B", label: "Swahili B" },
  { value: "Swedish A", label: "Swedish A" },
  {
    value: "Swedish A: Language and Literature",
    label: "Swedish A: Language and Literature",
  },
  {
    value: "Swedish A: Literature",
    label: "Swedish A: Literature",
  },
  { value: "Swedish AB", label: "Swedish AB" },
  { value: "Swedish B", label: "Swedish B" },
  { value: "Tajik A", label: "Tajik A" },
  { value: "Tajik A: Literature", label: "Tajik A: Literature" },
  { value: "Tamil A", label: "Tamil A" },
  { value: "Tamil A: Literature", label: "Tamil A: Literature" },
  { value: "Tamil B", label: "Tamil B" },
  { value: "Telugu A", label: "Telugu A" },
  {
    value: "Telugu A: Literature",
    label: "Telugu A: Literature",
  },
  { value: "Thai A", label: "Thai A" },
  {
    value: "Thai A: Language and Literature",
    label: "Thai A: Language and Literature",
  },
  { value: "Thai A: Literature", label: "Thai A: Literature" },
  { value: "Theatre", label: "Theatre" },
  { value: "Theatre (Pilot)", label: "Theatre (Pilot)" },
  { value: "Tibetan A", label: "Tibetan A" },
  {
    value: "Tibetan A: Literature",
    label: "Tibetan A: Literature",
  },
  { value: "Tigrinya A", label: "Tigrinya A" },
  {
    value: "Tigrinya A: Literature",
    label: "Tigrinya A: Literature",
  },
  { value: "Tonga A", label: "Tonga A" },
  {
    value: "Turkey In The 20th Century",
    label: "Turkey In The 20th Century",
  },
  { value: "Turkish A", label: "Turkish A" },
  {
    value: "Turkish A: Literature",
    label: "Turkish A: Literature",
  },
  { value: "Ukrainian A", label: "Ukrainian A" },
  {
    value: "Ukrainian A: Literature",
    label: "Ukrainian A: Literature",
  },
  { value: "Urdu A", label: "Urdu A" },
  { value: "Urdu A: Literature", label: "Urdu A: Literature" },
  { value: "Uzbek A", label: "Uzbek A" },
  { value: "Vietnamese A", label: "Vietnamese A" },
  {
    value: "Vietnamese A: Literature",
    label: "Vietnamese A: Literature",
  },
  { value: "Visual Arts", label: "Visual Arts" },
  { value: "Welsh A", label: "Welsh A" },
  { value: "Welsh A: Literature", label: "Welsh A: Literature" },
  {
    value: "World Arts and Cultures",
    label: "World Arts and Cultures",
  },
  { value: "World Politics", label: "World Politics" },
  { value: "World Religions", label: "World Religions" },
  { value: "Xhosa A", label: "Xhosa A" },
  { value: "Yoruba A", label: "Yoruba A" },
  { value: "Zulu A", label: "Zulu A" },
  { value: "Zulu A: Literature", label: "Zulu A: Literature" },
]
