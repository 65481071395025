import { BoxProps, FormHelperText, useStyleConfig } from "@chakra-ui/react"
import { StringToHTMLParser } from "./StringToHTMLParser"

interface HelperTextProps extends BoxProps {
  helperText: string
}

export const HelperText = ({ helperText, ...rest }: HelperTextProps) => {
  const styles = useStyleConfig("HelperText")
  return (
    <StringToHTMLParser
      string={helperText}
      as={FormHelperText}
      __css={styles}
      {...rest}
    />
  )
}
