import { PageData } from "types"
import { SectionDataParser } from "components/SectionDataParser"
import { useContext, useEffect } from "react"
import { TealiumViewTrack } from "tealium"
import uniqid from "uniqid"
import {
  NextPageButton,
  PreviousPageButton,
} from "components/PageProgressButtons"
import { ClientContext } from "context/ClientContext"
import { Flex } from "@chakra-ui/react"
interface PageDataParserProps {
  pageData: PageData
}

/**
 * A component for generating a page given the page data in JSON format
 *
 * @param pageData The JSON representing the page information
 * @returns The component representation of the page data
 */
export const PageDataParser = ({ pageData }: PageDataParserProps) => {
  const { client } = useContext(ClientContext)

  useEffect(() => {
    TealiumViewTrack({
      tealium_event: "page_view",
      page: `${pageData.slug}`,
    })
    //@ts-ignore
  }, [pageData.slug])

  return (
    <>
      {pageData.sections.map(section => {
        return <SectionDataParser key={uniqid()} sectionData={section} />
      })}
      <Flex
        justifyContent="space-between"
        pt={{ base: 6, md: 8 }}
        pb={{ base: 8, md: 12 }}
        px={4}
        mx={{ base: 2 }}
      >
        <PreviousPageButton currentPage={pageData} pages={client.data.pages} />
        <NextPageButton currentPage={pageData} pages={client.data.pages} />
      </Flex>
    </>
  )
}
