export const typography = {
  fonts: {
    heading: "'futura-pt-bold', sans-serif",
    body: "'futura-pt', sans-serif",
    subheading: "'futura-pt', sans-serif",
    futura: "'futura-pt', sans-serif",
    futuraBold: "'futura-pt-bold', sans-serif",
    sabon: "'linotype-sabon', serif",
  },
  fontSizes: {
    bodyMobile: "1rem",
    bodyDesktop: "1.125rem",
    helperTextMobile: "0.75rem",
    helperTextDesktop: "0.8125rem",
    h1Mobile: "2.3rem",
    h1Desktop: "2.0625rem",
  },
  lineHeights: {
    baseMobile: 1.55,
    baseDesktop: 1.75,
    button: 1,
  },
}
