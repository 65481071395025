import { useEffect, useState } from "react"
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup as ChakraInputGroup,
  InputLeftAddon as ChakraInputLeftAddon,
  InputRightAddon as ChakraInputRightAddon,
  InputLeftElement as ChakraInputLeftElement,
  InputRightElement as ChakraInputRightElement,
  InputAddonProps as ChakraInputAddonProps,
  InputElementProps as ChakraInputElementProps,
  forwardRef,
} from "@chakra-ui/react"
import { FormControl, SharedFormControlProps } from "./FormControl"
import { DateInput } from "./DateInput"

export interface InputProps extends ChakraInputProps, SharedFormControlProps {
  /**
   * Text added to left side of input
   */
  inputLeftAddon?: ChakraInputAddonProps
  /**
   * Add an icon, button or other element to the left side of the input
   */
  inputLeftElement?: ChakraInputElementProps
  /**
   * Text added to right side of input
   */
  inputRightAddon?: ChakraInputAddonProps
  /**
   * Add an icon, button or other element to the right side of the input
   */
  inputRightElement?: ChakraInputElementProps
}

export const Input = forwardRef<InputProps, "input">(
  (
    {
      errorText,
      helperText,
      inputLeftAddon,
      inputLeftElement,
      inputRightAddon,
      inputRightElement,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      size,
      type = "text",
      ...rest
    },
    ref
  ) => {
    const [isDateInputDisabled, setIsDateInputDisabled] =
      useState<boolean>(false)
    const isDateInput = type.match(/^(date|month|year)$/)

    useEffect(() => {
      if (
        isDateInput &&
        rest.name.includes("base") &&
        rest.name.includes("to_date") &&
        !rest.value
      ) {
        setIsDateInputDisabled(true)
      }
      // eslint-disable-next-line
    }, [])

    const FormControlValues = {
      errorText,
      helperText: helperText,
      isDisabled: isDateInputDisabled || isDisabled,
      isInvalid: !isDateInputDisabled && isInvalid,
      isReadOnly,
      isRequired,
      label,
      isDateInput,
    }

    const InputLeftAddon = () =>
      inputLeftAddon ? (
        <ChakraInputLeftAddon {...inputLeftAddon} />
      ) : inputLeftElement ? (
        <ChakraInputLeftElement {...inputLeftElement} />
      ) : null

    const InputRightAddon = () =>
      inputRightAddon ? (
        <ChakraInputRightAddon {...inputRightAddon} />
      ) : inputRightElement ? (
        <ChakraInputRightElement {...inputRightElement} />
      ) : null

    return (
      <FormControl {...(FormControlValues as SharedFormControlProps)}>
        <ChakraInputGroup size={size}>
          <InputLeftAddon />
          {isDateInput ? (
            <DateInput
              type={type}
              {...{
                setIsDateInputDisabled,
                isDateInputDisabled,
                errorText,
                ...rest,
              }}
            />
          ) : (
            <ChakraInput type={type} ref={ref} {...rest} />
          )}
          <InputRightAddon />
        </ChakraInputGroup>
      </FormControl>
    )
  }
)
