import { useContext, useEffect, useMemo, useState } from "react"
import { useWatch } from "react-hook-form"
import { Box, Stack, StackProps, useStyleConfig } from "@chakra-ui/react"
import { SectionData } from "types"
import FormContext from "context/FormContext"
import {
  determineShowField,
  getConditionalFieldsToWatch,
} from "utilities/conditions"
import { SectionHeader } from "components/SectionHeader"
import { FormFieldsDisplay } from "components/FormFieldsDisplay"
import { useMaxWidth } from "hooks"
import { SectionDescription } from "./SectionDescription"

interface SectionDataParserProps extends StackProps {
  isNested?: boolean
  sectionData: SectionData
  nestedSectionsMax?: number
  nestedIndex?: number
  removeFieldGroup?: () => void
  canRemoveFieldGroup?: boolean // This property is necessary for now since removing any field group removes the last one anyway
  isFinalCheck?: boolean
}

/**
 * A component for generating a section given the section data in JSON format
 *
 * @param sectionData The JSON representing the section information
 * @returns The component representation of the section data
 */

export const SectionDataParser = ({
  isNested,
  nestedIndex,
  removeFieldGroup,
  sectionData,
  canRemoveFieldGroup,
  isFinalCheck,
}: SectionDataParserProps) => {
  const {
    form: { control, getValues },
  } = useContext(FormContext)

  const [showSection, setShowSection] = useState<boolean>(true)

  // Watch your field data, and the fields in your conditions
  const relevantFieldNames: string[] = useMemo(() => {
    return getConditionalFieldsToWatch(sectionData)
  }, [sectionData])
  const relevantFormValues = useWatch({ control, name: relevantFieldNames })

  useEffect(() => {
    if (relevantFieldNames.length > 0) {
      const relevantFields = {}
      const allValues = getValues()

      Object.keys(allValues).forEach(value => {
        if (relevantFieldNames.includes(value)) {
          relevantFields[value] = allValues[value]
        }
      })

      setShowSection(
        determineShowField(sectionData, relevantFields, nestedIndex)
      )
    }
    // eslint-disable-next-line
  }, [sectionData, relevantFieldNames, relevantFormValues])

  const styles = useStyleConfig(
    isNested ? "NestedSectionDataParser" : "SectionDataParser"
  )

  const { isMobileView } = useMaxWidth()
  const hasDescription = sectionData.description?.length > 0 && !isNested
  if (!showSection) {
    return null
  }

  if (sectionData.conditionalSection) {
    styles.paddingBottom = { base: 4, md: 4 }
  }

  return (
    <Box __css={styles}>
      {sectionData.title && (
        <Stack spacing={isMobileView ? 2 : 1} marginBottom={6}>
          <SectionHeader
            title={sectionData.title}
            isNested={isNested}
            canRemove={canRemoveFieldGroup}
            onClickRemove={isNested ? removeFieldGroup : null}
          />
          {hasDescription && (
            <SectionDescription
              description={sectionData.description}
              maxWidth={isNested ? "58ch" : null}
            />
          )}
        </Stack>
      )}
      <FormFieldsDisplay
        sectionData={sectionData}
        nestedIndex={nestedIndex}
        isFinalCheck={isFinalCheck}
      />
    </Box>
  )
}
