import { useContext, useEffect } from "react"
import { useFieldArray, useWatch } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import { motion, AnimatePresence, AnimatePresenceProps } from "framer-motion"
import FormContext from "context/FormContext"
import { SectionData } from "types"
import { DEFAULT_ADDITIONAL_SECTION_LIMIT } from "utilities/constants"
import { SectionDataParser } from "components/SectionDataParser"

interface NestedSectionDataParserProps {
  sectionData: SectionData
  isFinalCheck?: boolean
}

interface NewAnimatePresenceProps
  extends Omit<AnimatePresenceProps, "children"> {
  children: React.ReactNode
}

export const NestedSectionDataParser = ({
  sectionData,
  isFinalCheck,
}: NestedSectionDataParserProps) => {
  const {
    title: sectionTitle,
    description: sectionDescription,
    fields: sectionFields,
    nestedSectionsMax,
    nestedSectionTitle,
    nestedSectionDescription,
    nestedSectionItemName,
    controlSectionName,
  } = sectionData

  const {
    form: { control, getValues },
    onSave,
  } = useContext(FormContext)

  /**
    This creates an array on the form. If the section name is siblings, this adds the field: IGNORE_PREFIXsiblings: [{},{}] where inside the array is x empty objects where x is the number of additional fields we should display. We can ignore this object, as it won't be useable data, but is merely for getting the hook to display the proper number of fields on rerender.
    This is not how RHF wants you to do it, they want the array to be populated with each fields groups field data. That doesn't work for us since our fields flatmap themselves onto the form to get one "depth" on the form.
   */

  const { fields, append, remove, replace } = useFieldArray({
    name: controlSectionName,
    control,
  })

  const sectionValue = useWatch({
    control,
    name: controlSectionName,
  })

  const getDefaultFileds = () => {
    const formValues = getValues()

    const defaultFileds = {}

    sectionFields.forEach(
      el => (defaultFileds[el.name] = formValues[el.name] || ""),
    )

    return defaultFileds
  }

  const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence

  useEffect(
    function alwaysDisplayAtLeastOneField() {
      if (fields?.length < 1) {
        append(getDefaultFileds())
      }
    },
    // eslint-disable-next-line
    [fields],
  )

  const AddFieldGroupButton = () => {
    const maxCount = nestedSectionsMax || DEFAULT_ADDITIONAL_SECTION_LIMIT
    const isSectionLimitReached = fields.length >= maxCount
    let itemName = nestedSectionItemName?.toLowerCase() || ""

    const addFieldGroup = () => {
      append(getDefaultFileds())

      onSave()
    }

    return (
      <Button
        alignSelf="flex-start"
        isDisabled={isSectionLimitReached}
        onClick={addFieldGroup}
        variant="solid"
        title={
          isSectionLimitReached
            ? `You've reached the maximum of ${maxCount} ${itemName}s`
            : null
        }
      >
        Add another {itemName}
      </Button>
    )
  }

  const removeFieldGroup = index => {
    //somehow remove build in mehtod is not working correctly with the last object of an array - it removes it partially
    //although with every other index remove method works correctly
    //so as a temporary desicion we check whether we want to remove the last obj in arr
    //if it is NOT the last one
    if (index !== sectionValue.length - 1) {
      //we use remove method as it works correctly
      remove(index)
    } else {
      //if it is the last one
      //we use replace method and replace the array with the same array (but without the last obj)
      replace(sectionValue.slice(0, -1))
    }

    onSave()
  }

  /**
   * getGeneratedSectionData() is the meat and potatoes of this parser
   */
  const getGeneratedSectionData = (index: number): SectionData => {
    const displayIndex = index + 1

    // Sections can have custom titles based on index like: Sibling 1
    const title = nestedSectionTitle
      ? nestedSectionTitle.replaceAll("{{x}}", displayIndex.toString())
      : `${sectionTitle} ${displayIndex}`

    // TODO: In future, make description blank unless specified
    // This will affect all colleges, so plan accordingly
    const description =
      nestedSectionDescription === null
        ? ""
        : nestedSectionDescription || sectionDescription

    // prettier-ignore
    return { ...sectionData, fields: sectionFields, title, description, nestedSectionsMax: undefined }
  }

  return (
    <NewAnimatePresence>
      {fields.map((item, index) => (
        <motion.div
          key={item.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <SectionDataParser
            isNested
            nestedIndex={index}
            nestedSectionsMax={nestedSectionsMax}
            canRemoveFieldGroup={fields.length > 1}
            removeFieldGroup={() => removeFieldGroup(index)}
            sectionData={getGeneratedSectionData(index)}
            isFinalCheck={isFinalCheck}
          />
        </motion.div>
      ))}
      {nestedSectionsMax > 1 && <AddFieldGroupButton />}
    </NewAnimatePresence>
  )
}
