import { Box, useStyleConfig } from "@chakra-ui/react"

export const OptionalTag = ({ children = "(optional)", ...rest }) => {
  const styles = useStyleConfig("OptionalTag")
  return (
    <Box as="span" __css={styles} {...rest}>
      {children}
    </Box>
  )
}
