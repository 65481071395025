export const CREIGHTON_MAJORS = [
  {
    label: "Accounting",
    value: "Accounting",
  },
  {
    label: "Accounting - Accelerated Bachelor's to Master's Program",
    value: "Accounting - Accelerated Bachelor's to Master's Program",
  },
  {
    label: "American Studies",
    value: "American Studies",
  },
  {
    label: "Anthropology - Cultural",
    value: "Anthropology - Cultural",
  },
  {
    label: "Anthropology - Medical",
    value: "Anthropology - Medical",
  },
  {
    label: "Art - Art History",
    value: "Art - Art History",
  },
  {
    label: "Art - Studio Art",
    value: "Art - Studio Art",
  },
  {
    label: "Biochemistry",
    value: "Biochemistry",
  },
  {
    label: "Biology",
    value: "Biology",
  },
  {
    label: "Business Intelligence & Analytics - Business Analytics Track",
    value: "Business Intelligence & Analytics - Business Analytics Track",
  },
  {
    label: "Business Intelligence & Analytics - Digital Media and Design Track",
    value: "Business Intelligence & Analytics - Digital Media and Design Track",
  },
  {
    label: "Business Intelligence & Analytics - Information Technology Track",
    value: "Business Intelligence & Analytics - Information Technology Track",
  },
  {
    label: "Business Pre-Health Science Program",
    value: "Business Pre-Health Science Program",
  },
  {
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    label: "Classical and Near Eastern Civilizations",
    value: "Classical and Near Eastern Civilizations",
  },
  {
    label: "Classical Languages - Greek & Latin",
    value: "Classical Languages - Greek & Latin",
  },
  {
    label: "Communication Studies",
    value: "Communication Studies",
  },
  {
    label:
      "Communication Studies/Integrative Health & Wellness Accelerated Bachelor's to Master's Program",
    value:
      "Communication Studies/Integrative Health & Wellness Accelerated Bachelor's to Master's Program",
  },
  {
    label:
      "Communication Studies/Organizational Leadership Accelerated Bachelor's to Master's Program",
    value:
      "Communication Studies/Organizational Leadership Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Computer Science",
    value: "Computer Science",
  },
  {
    label: "Criminal Justice",
    value: "Criminal Justice",
  },
  {
    label: "Data Science",
    value: "Data Science",
  },
  {
    label: "Economics - College of Art & Sciences",
    value: "Economics - College of Art & Sciences",
  },
  {
    label: "Economics - Heider College of Business",
    value: "Economics - Heider College of Business",
  },
  {
    label: "Education - Elementary",
    value: "Education - Elementary",
  },
  {
    label: "Education - Elementary with Early Childhood Education Endorsement",
    value: "Education - Elementary with Early Childhood Education Endorsement",
  },
  {
    label:
      "Education-Secondary Education Accelerated Bachelor's to Master's Program",
    value:
      "Education-Secondary Education Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Engineering/Physics 3+2 Dual Degree Program",
    value: "Engineering/Physics 3+2 Dual Degree Program",
  },
  {
    label: "Engineering/Chemistry 3+2 Dual Degree Program",
    value: "Engineering/Chemistry 3+2 Dual Degree Program",
  },
  {
    label: "Engineering/Computer Science 3+2 Dual Degree Program",
    value: "Engineering/Computer Science 3+2 Dual Degree Program",
  },
  {
    label: "Engineering/Math 3+2 Dual Degree Program",
    value: "Engineering/Math 3+2 Dual Degree Program",
  },
  {
    label: "English",
    value: "English",
  },
  {
    label: "Envrionmental Science - Environmental Policy & Society Track",
    value: "Envrionmental Science - Environmental Policy & Society Track",
  },
  {
    label: "Envrionmental Science - Global & Environmental Systems Track",
    value: "Envrionmental Science - Global & Environmental Systems Track",
  },
  {
    label: "Envrionmental Science - Organismal/Population Ecology Track",
    value: "Envrionmental Science - Organismal/Population Ecology Track",
  },
  {
    label: "Exercise Science",
    value: "Exercise Science",
  },
  {
    label: "Finance & Technology",
    value: "Finance & Technology",
  },
  {
    label: "Finance - Financial Analysis Track",
    value: "Finance - Financial Analysis Track",
  },
  {
    label: "Finance - Financial Planning Track",
    value: "Finance - Financial Planning Track",
  },
  {
    label: "Finance - Financial Services Track",
    value: "Finance - Financial Services Track",
  },
  {
    label: "Finance - Insurance & Risk Management",
    value: "Finance - Insurance & Risk Management",
  },
  {
    label:
      "Finance/Financial Planning and Psychology - Accelerated Bachelor’s to Master’s Program",
    value:
      "Finance/Financial Planning and Psychology - Accelerated Bachelor’s to Master’s Program",
  },
  {
    label:
      "Finance/Investment Management and Financial Analysis Accelerated Bachelor’s to Master’s Program",
    value:
      "Finance/Investment Management and Financial Analysis Accelerated Bachelor’s to Master’s Program",
  },
  {
    label: "French & Francophone Studies",
    value: "French & Francophone Studies",
  },
  {
    label: "German Studies",
    value: "German Studies",
  },
  {
    label: "Graphic Design & Film - Filmmaking",
    value: "Graphic Design & Film - Filmmaking",
  },
  {
    label: "Graphic Design & Film - Graphic Design Track",
    value: "Graphic Design & Film - Graphic Design Track",
  },
  {
    label: "Health Administration & Policy",
    value: "Health Administration & Policy",
  },
  {
    label: "History",
    value: "History",
  },
  {
    label: "International Business",
    value: "International Business",
  },
  {
    label: "International Relations",
    value: "International Relations",
  },
  {
    label: "Journalism - Advertising Track",
    value: "Journalism - Advertising Track",
  },
  {
    label: "Journalism - News Track",
    value: "Journalism - News Track",
  },
  {
    label: "Journalism - Public Relations Track",
    value: "Journalism - Public Relations Track",
  },
  {
    label: "Justice & Society",
    value: "Justice & Society",
  },
  {
    label: "Law - College of Arts and Sciences 3-3 Combined Degree Program",
    value: "Law - College of Arts and Sciences 3-3 Combined Degree Program",
  },
  {
    label: "Law - Heider College of Business 3-3 Combined Degree Program",
    value: "Law - Heider College of Business 3-3 Combined Degree Program",
  },
  {
    label: "Law - Classical Civilizations 3-3 Combined Program",
    value: "Law - Classical Civilizations 3-3 Combined Program",
  },
  {
    label: "Law - Classical Languages 3-3 Combined Degree Program",
    value: "Law - Classical Languages 3-3 Combined Degree Program",
  },
  {
    label: "Law - Communication Studies 3-3 Combined Degree Program",
    value: "Law - Communication Studies 3-3 Combined Degree Program",
  },
  {
    label: "Law - Criminal Justice 3-3 Combined Degree Program",
    value: "Law - Criminal Justice 3-3 Combined Degree Program",
  },
  {
    label: "Law - English 3-3 Combined Degree Program",
    value: "Law - English 3-3 Combined Degree Program",
  },
  {
    label: "Law - French Studies 3-3 Combined Degree Program",
    value: "Law - French Studies 3-3 Combined Degree Program",
  },
  {
    label: "Law - German Studies 3-3 Combined Degree Program",
    value: "Law - German Studies 3-3 Combined Degree Program",
  },
  {
    label: "Law - History 3-3 Combined Degree Program",
    value: "Law - History 3-3 Combined Degree Program",
  },
  {
    label: "Law - Philosophy 3-3 Combined Degree Program",
    value: "Law - Philosophy 3-3 Combined Degree Program",
  },
  {
    label: "Law - Political Science 3-3 Combined Degree Program",
    value: "Law - Political Science 3-3 Combined Degree Program",
  },
  {
    label: "Law - Psychology 3-3 Combined Degree Program",
    value: "Law - Psychology 3-3 Combined Degree Program",
  },
  {
    label: "Law - Spanish Studies 3-3 Combined Degree Program",
    value: "Law - Spanish Studies 3-3 Combined Degree Program",
  },
  {
    label: "Law - Theology 3-3 Combined Degree Program",
    value: "Law - Theology 3-3 Combined Degree Program",
  },
  {
    label: "Management - Business Ethics Track",
    value: "Management - Business Ethics Track",
  },
  {
    label: "Management - Entrepreneurship Track",
    value: "Management - Entrepreneurship Track",
  },
  {
    label: "Management - Human Resources Track",
    value: "Management - Human Resources Track",
  },
  {
    label: "Management - Pre-Law Track",
    value: "Management - Pre-Law Track",
  },
  {
    label: "Management - Social Entrepreneurship Track",
    value: "Management - Social Entrepreneurship Track",
  },
  {
    label:
      "Management/Integrative Health & Wellness Accelerated Bachelor's to Master's Program",
    value:
      "Management/Integrative Health & Wellness Accelerated Bachelor's to Master's Program",
  },
  {
    label:
      "Management/Organizational Leadership Accelerated Bachelor's to Master's Program",
    value:
      "Management/Organizational Leadership Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Marketing",
    value: "Marketing",
  },
  {
    label: "Marketing - Customer Analytics Specialization",
    value: "Marketing - Customer Analytics Specialization",
  },
  {
    label: "Marketing - Strategic Communications Specialization",
    value: "Marketing - Strategic Communications Specialization",
  },
  {
    label:
      "Marketing/Organizational Leadership Accelerated Bachelor's to Master's Program",
    value:
      "Marketing/Organizational Leadership Accelerated Bachelor's to Master's Program",
  },
  {
    label:
      "Master's Program to Paramedicine -  Accelerated Bachelor's to Master's Program",
    value:
      "Master's Program to Paramedicine -  Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Mathematics",
    value: "Mathematics",
  },
  {
    label: "Mathematics - Medical Mathematics",
    value: "Mathematics - Medical Mathematics",
  },
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Musical Theatre",
    value: "Musical Theatre",
  },
  {
    label: "Neuroscience",
    value: "Neuroscience",
  },
  {
    label: "Neuroscience - Accelerated Bachelor's to Master's Program",
    value: "Neuroscience - Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Nursing",
    value: "Nursing",
  },
  {
    label: "Nursing - Traditional 4-year Program - Omaha",
    value: "Nursing - Traditional 4-year Program - Omaha",
  },
  {
    label: "Paramedicine",
    value: "Paramedicine",
  },
  {
    label: "Paramedicine - Accelerated Bachelor's to Master's Program",
    value: "Paramedicine - Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Philosophy",
    value: "Philosophy",
  },
  {
    label: "Philosophy - Ethics Specialization",
    value: "Philosophy - Ethics Specialization",
  },
  {
    label: "Physics",
    value: "Physics",
  },
  {
    label: "Physics - Accelerated Bachelor's to Master's Program",
    value: "Physics - Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Physics - Applied Physics & Pre-Engineering",
    value: "Physics - Applied Physics & Pre-Engineering",
  },
  {
    label: "Physics - Biomedical Physics Track",
    value: "Physics - Biomedical Physics Track",
  },
  {
    label:
      "Physics/Medical Physics - Accelerated Bachelor's to Master's Program",
    value:
      "Physics/Medical Physics - Accelerated Bachelor's to Master's Program",
  },
  {
    label: "Political Science - Legal Studies Track",
    value: "Political Science - Legal Studies Track",
  },
  {
    label: "Political Science - Political Science Track",
    value: "Political Science - Political Science Track",
  },
  {
    label: "Political Science - Public Policy Track",
    value: "Political Science - Public Policy Track",
  },
  {
    label: "Political Science - Research Design & Analysis Track",
    value: "Political Science - Research Design & Analysis Track",
  },
  {
    label: "Psychology",
    value: "Psychology",
  },
  {
    label: "Social Work",
    value: "Social Work",
  },
  {
    label: "Sociology",
    value: "Sociology",
  },
  {
    label: "Spanish and Hispanic Studies",
    value: "Spanish and Hispanic Studies",
  },
  {
    label: "Sustainability",
    value: "Sustainability",
  },
  {
    label: "Theatre - Performance Track",
    value: "Theatre - Performance Track",
  },
  {
    label: "Theatre - Technical Theatre Track",
    value: "Theatre - Technical Theatre Track",
  },
  {
    label: "Theology",
    value: "Theology",
  },
  {
    label: "Theology - Biblical Studies Specialization",
    value: "Theology - Biblical Studies Specialization",
  },
  {
    label: "Theology - Christian Life Studies Specialization",
    value: "Theology - Christian Life Studies Specialization",
  },
  {
    label: "Theology - Doctrinal, Historical, or Liturgical Specialization",
    value: "Theology - Doctrinal, Historical, or Liturgical Specialization",
  },
  {
    label: "Undecided - College of Arts & Sciences",
    value: "Undecided - College of Arts & Sciences",
  },
  {
    label: "Undecided - Heider College of Business",
    value: "Undecided - Heider College of Business",
  },
]
