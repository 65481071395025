import { extendTheme } from "@chakra-ui/react"
import { baseTheme } from "themes"

import { colors } from "./colors"
import { components } from "./components"
import { shadows } from "./shadows"
import { typography } from "./typography"

const sienaTheme = {
  colors,
  components,
  shadows,
  ...typography,

  styles: {
    global: {
      body: {
        fontWeight: "normal",
      },
      "#root": {
        background: {
          md: `linear-gradient(165deg, rgba(229,229,229,0.4) 50%, transparent 50%) no-repeat`,
        },
        backgroundColor: "pageBgColor",
        backgroundSize: {
          md: "100% 100vh",
        },
      },

      // Sign-Up / Sign-In Page Styles
      "#root.auth": {
        background: {
          md: `linear-gradient(165deg, #FCD344 40%, #FFC325 40%, #FFC325 45%, #FCD344 45%, #FCD344 50%, transparent 50%) no-repeat`,
        },
        "& .site-header": {
          borderBottom: { md: "none" },
          marginBottom: { md: 0 },
        },
      },

      ".selected-flag:focus-within + .form-control, .selected-flag:hover, .selected-flag":
        {
          backgroundColor: "#e5f0ee !important",
        },
      ".country-list::-webkit-scrollbar": {
        width: "6px",
      },

      ".country-list::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px white",
      },

      " .country-list::-webkit-scrollbar-thumb": {
        backgroundColor: "#e4e7ed",
        borderRadius: "4px",
        height: "60px",
      },

      ".form-control:focus + .flag-dropdown .selected-flag, .flag-dropdown .selected-flag:focus + .form-control, .form-control:focus, .open, .selected-flag:hover.open":
        {
          backgroundColor: "white !important",
        },
      ".search": {
        width: "100%",
        borderBottom: "1px solid #ced0d4",
      },
      ".selected-flag": {
        paddingLeft: "15px !important",
      },
      ".country-list li": {
        marginTop: "8px !important",
        paddingRight: "10px !important",
      },
      // ".react-tel-input:focus-within": {
      //   border: "1px solid #006B54 !important",
      // },
      ".error.react-tel-input:focus-within": {
        border: "2px solid #B01C2E !important",
        boxShadow: "0 0 6px 0 #B01C2E",
      },
      ".error": {
        border: "2px solid #B01C2E !important",
      },

      ".error .form-control, .error .flag-dropdown, .error .selected-flag": {
        border: "none",
        background: "white !important",
      },

      ".country:hover": {
        backgroundColor: "#E5F0EE !important",
      },

      ".country.highlight, .country.highlight .dial-code": {
        backgroundColor: "#40907F !important",
        color: "white !important",
      },

      ".react-tel-input .selected-flag": {
        borderRadius: "0 !important",
      },

      ".form-control:hover+.flag-dropdown, .form-control:hover": {
        border: "1px solid #006B54 !important",
      },

      ".form-control:focus, .form-control:focus+.flag-dropdown, .form-control:focus+.flag-dropdown, .form-control.open, .flag-dropdown.open, .form-control.open:hover+.flag-dropdown":
        {
          border: "2px solid #006B54 !important",
        },

      ".react-tel-input:hover .flag-dropdown, .form-control:focus+.flag-dropdown, .flag-dropdown.open, .form-control.open:hover+.flag-dropdown":
        {
          borderRight: "0px !important",
        },

      ".country-list": {
        marginTop: "3px !important",
      },
    },
  },
}

const theme = extendTheme({ ...baseTheme }, sienaTheme)

export default theme
