import {
  Flex,
  Icon,
  Spinner,
  Text,
  forwardRef,
  FormErrorMessage,
} from "@chakra-ui/react"
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons"

import { MdPublish } from "react-icons/md"
import { useContext, useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FormControl, Input, Span } from "components/common"
import { IconButton } from "components/IconButton"
import { UserApi } from "api/user"
import { UserContext } from "context/UserContext"
import { AuthContext } from "context/AuthContext"
import { isAuthorizationError } from "utilities/helpers"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "utilities/constants"

import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg"

interface CustomFile extends File {
  path?: string
}

function extractFilenameFromPath(path) {
  const keyword = "\\fakepath\\"
  const index = path.lastIndexOf(keyword)
  if (index !== -1) {
    return path.substring(index + keyword.length)
  }
  return path
}

// Built with help from https://react-dropzone.js.org/
export const FileUpload = forwardRef<any, "div">((inputProps, ref) => {
  const [fileName, setFileName] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const { token } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleDrop = (files: File[]) => {
    if (!files[0]) {
      return
    }

    const formData = new FormData()
    formData.append("file", files[0])

    setIsLoading(true)

    UserApi.uploadEssay({ token: token, file: formData })
      .then(res => {
        setFileName(files[0]?.name)

        if (error) {
          setError("")
        }
        inputProps.onBlur()
      })
      .catch(err => {
        if (isAuthorizationError(err)) {
          navigate(AppRoutes.logout)
        } else {
          console.error(err)
        }
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    inputProps.onChange(fileName)
  }, [fileName])

  // TODO: Fix error in console related to forwardRef() on this component
  const {
    acceptedFiles,
    isDragAccept,
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps,
    fileRejections,
    open,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    onDrop: handleDrop,
    maxFiles: 1,
    maxSize: 10485760,
    noClick: !!fileName,
    noKeyboard: !!fileName,
  })

  useEffect(() => {
    if (inputProps.value && fileRejections.length === 0) {
      const parsedValue = extractFilenameFromPath(inputProps.value)
      setFileName(parsedValue)
    }
  }, [])

  useEffect(() => {
    if (fileRejections[0] && fileRejections[0].errors[0]) {
      const currentError = fileRejections[0].errors[0]
      setError(
        currentError.code === "file-too-large"
          ? "File size must be less than 10 MB"
          : currentError.message
      )
    }
  }, [fileRejections])

  const dropzoneStyles = useMemo(
    () => ({
      alignItems: "center",
      background: "formInputBackgroundColor",
      border: "1px dashed",
      borderColor: "formInputBorderColor",
      borderRadius: "lg",
      //   boxShadow:"default" // TODO: Confirm design decision,
      color: "buttonLinkColor",
      // direction="column" // Needs to be set on the component for unknown reason
      outline: "none",
      padding: 6,
      transitionDuration: "normal",
      transitionProperty: "common",
      transitionTimingFunction: "ease-in-out",
      _focus: {
        borderColor: "formInputFocusedBorderColor",
        boxShadow: "inputFocused",
      },
      _hover: {
        borderColor: "formInputHoveredBorderColor",
        cursor: "pointer",
      },
      _groupHover: {
        "& .link": { textDecoration: "underline" },
      },
      ...(isDragActive
        ? {
            borderColor: "buttonLinkHoverColor",
            borderStyle: "solid",
            color: "buttonLinkHoverColor",
          }
        : {}),
      ...(isDragAccept
        ? {
            borderColor: "success",
          }
        : {}),
      ...(isDragReject
        ? {
            borderColor: "error",
          }
        : {}),
    }),
    [isDragAccept, isDragActive, isDragReject]
  )

  useEffect(() => {
    const customFile: CustomFile = acceptedFiles[0]
    !!customFile?.name && setFileName(customFile?.name)
    // eslint-disable-next-line
  }, [acceptedFiles])

  useEffect(() => {
    const essayFileName = user?.applicationData?.essay_file_upload
    !!essayFileName && setFileName(essayFileName)
    // eslint-disable-next-line
  }, [])

  return (
    <FormControl
      {...inputProps}
      helperText="Upload a .pdf, .doc, or .docx"
      isInvalid={!!error}
    >
      <Flex
        direction="column"
        {...getRootProps(!fileName ? { ...dropzoneStyles } : {})}
      >
        <Input {...getInputProps()} size="md" />
        {!fileName ? (
          <>
            <Icon boxSize="6" mb={1} as={MdPublish} />
            <Text>
              <Span className="link">Choose a file</Span>
              <Span color="bodyColor">{` or drag it here `}</Span>
            </Text>
          </>
        ) : (
          <Input
            value={fileName}
            accept=".pdf, .doc, .docx"
            readOnly
            inputRightElement={{
              children: isLoading ? (
                <Spinner />
              ) : (
                <IconButton
                  icon={<Icon as={PencilIcon} />}
                  onClick={open}
                  ariaLabel="Edit File"
                  background="none!important"
                />
              ),
            }}
            inputLeftElement={{
              children:
                !isLoading &&
                (!error ? (
                  <CheckCircleIcon color="green.400" cursor="default" />
                ) : (
                  <WarningIcon color="red.400" />
                )),
            }}
            sx={{ pl: !isLoading ? "40px" : "" }}
          />
        )}
      </Flex>
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  )
})
