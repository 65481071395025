import { extendTheme } from "@chakra-ui/react"
import { baseTheme } from "themes"

import { colors } from "./colors"
import { components } from "./components"
import { shadows } from "./shadows"
import { typography } from "./typography"

const hollinsTheme = {
  colors,
  components,
  shadows,
  ...typography,

  styles: {
    global: {
      body: {
        fontWeight: "normal",
      },
      "#root": {
        backgroundColor: "pageBgColor",
        backgroundSize: {
          md: "100% 100vh",
        },
      },

      // Sign-Up / Sign-In Page Styles
      "#root.auth": {
        ".site-header": {
          borderBottom: { md: "none" },
          marginBottom: { md: 0 },
        },
      },
    },
  },
}

const theme = extendTheme({ ...baseTheme }, hollinsTheme)

export default theme
