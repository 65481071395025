import { Box, BoxProps, useStyleConfig, forwardRef } from "@chakra-ui/react"

export interface ContentWrapperProps extends BoxProps {
  baseStyle?: { [key: string]: any }
  variant?: string
  /**
   * wrapperProps modifies inner container of a component
   */
  wrapperProps?: BoxProps
}

export const ContentWrapper = forwardRef<ContentWrapperProps, "div">(
  ({ ...rest }, ref) => {
    const styles = useStyleConfig("ContentWrapper")
    return (
      <Box ref={ref} __css={styles} className="content-wrapper" {...rest} />
    )
  },
)

export const SiteHeader = forwardRef<ContentWrapperProps, "div">(
  ({ children, wrapperProps, ...rest }, ref) => {
    const styles = useStyleConfig("SiteHeader")
    return (
      <Box
        as="header"
        ref={ref}
        __css={styles}
        gridArea="header"
        className="site-header"
        {...rest}
      >
        <ContentWrapper {...wrapperProps}>{children}</ContentWrapper>
      </Box>
    )
  }
)

export const Main = forwardRef<ContentWrapperProps, "div">(
  ({ children, wrapperProps, ...rest }, ref) => {
    const styles = useStyleConfig("Main")
    return (
      <Box
        as="main"
        ref={ref}
        __css={styles}
        gridArea="main"
        className="main"
        {...rest}
      >
        <ContentWrapper {...wrapperProps}>{children}</ContentWrapper>
      </Box>
    )
  }
)

export const PageHeader = forwardRef<ContentWrapperProps, "div">(
  (props, ref) => {
    const { baseStyle, variant, wrapperProps, children, ...rest } = props
    const styles = useStyleConfig("PageHeader", { baseStyle, variant })
    return (
      <Box ref={ref} __css={styles} className="page-header" {...rest}>
        <ContentWrapper {...wrapperProps}>{children}</ContentWrapper>
      </Box>
    )
  }
)

export const PageContent = forwardRef<ContentWrapperProps, "div">(
  ({ children, wrapperProps, ...rest }, ref) => {
    const styles = useStyleConfig("PageContent")
    return (
      <Box ref={ref} __css={styles} className="page-content" {...rest}>
        <ContentWrapper {...wrapperProps}>{children}</ContentWrapper>
      </Box>
    )
  },
)

export const SiteFooter = forwardRef<ContentWrapperProps, "div">(
  ({ children, wrapperProps, ...rest }, ref) => {
    const styles = useStyleConfig("SiteFooter")
    return (
      <Box
        as="footer"
        ref={ref}
        __css={styles}
        gridArea="footer"
        className="site-footer"
        {...rest}
      >
        <ContentWrapper {...wrapperProps}>{children}</ContentWrapper>
      </Box>
    )
  }
)

export const AuthFormWrapper = ({ ...rest }: BoxProps) => {
  const styles = useStyleConfig("AuthFormWrapper")
  return <Box __css={styles} className="form-wrapper" {...rest} />
}

export const AuthTextContent = ({ ...rest }: BoxProps) => {
  const styles = useStyleConfig("AuthTextContent")
  return <Box __css={styles} className="text-content" {...rest} />
}
