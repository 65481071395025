import { ChakraProvider as ThemeProvider } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { ClientContext } from "context/ClientContext"
import {
  BaseFonts,
  baseTheme,
  SienaFonts,
  sienaTheme,
  VanguardFonts,
  vanguardTheme,
  creightonTheme,
  CreightonFonts,
  hollinsTheme,
  HollinsFonts,
  ThemeOption
} from "themes"

const ThemeWrapper = ({ children }) => {
  const { client } = useContext(ClientContext)
  const [theme, setTheme] = useState<ThemeOption>("base")

  // TODO: Do something more efficient for multiple themes than this.
  // ... but this works for now and I'm having trouble with other solutions
  const Fonts = getFonts(theme)

  useEffect(() => {
    if (client?.theme) {
      setTheme(client.theme)
    }
  }, [client?.theme, theme])

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Fonts />
      {children}
    </ThemeProvider>
  )
}

export default ThemeWrapper

const getTheme = (theme: ThemeOption) => {
  switch (theme) {
    case "siena":
      return sienaTheme
    case "vanguard":
      return vanguardTheme
    case "creighton":
      return creightonTheme
    case "hollins":
      return hollinsTheme
    default:
      return baseTheme
  }
}

const getFonts = (theme: ThemeOption) => {
  switch (theme) {
    case "siena":
      return SienaFonts
    case "vanguard":
      return VanguardFonts
    case "creighton":
      return CreightonFonts
    case "hollins":
      return HollinsFonts
    default:
      return BaseFonts
  }
}
