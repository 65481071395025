import React from "react"
import { Link } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

export const RouterNavLink = ({
  to = "",
  children,
  _hover,
  activeStyle,
  ...rest
}) => {
  return (
    <NavLink to={to} style={{ textDecoration: "none" }}>
      {({ isActive }) => (
        <Link
          as="span"
          {...rest} // Default styles and other props are applied here
          _hover={_hover}
          style={isActive ? activeStyle : {}} // Apply active styles when isActive is true
        >
          {children}
        </Link>
      )}
    </NavLink>
  )
}
