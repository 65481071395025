import { IS_PRODUCTION } from "utilities/constants"

interface TealiumData {
  tealium_event: string
  page?: string
  email_address?: string
  customer_id?: string
}

// For tracking page changes (e.g. from a link, refresh, etc.)
export function TealiumViewTrack(tealiumData: TealiumData) {
  !IS_PRODUCTION && console.log(tealiumData)
  // @ts-ignore
  window.utag?.view({
    ...tealiumData,
  })
}

// For tracking events (on submit, on click, on validation, etc)
export function TealiumLinkTrack(tealiumData: TealiumData) {
  !IS_PRODUCTION && console.log(tealiumData)
  // @ts-ignore
  window.utag?.link({
    ...tealiumData,
  })
}
