export const typography = {
  fonts: {
    heading: "'oswald', sans-serif",
    body: "'hedley-new-web', sans-serif",
  },
  fontSizes: {
    bodyDesktop: "1.125rem",
    helperText: "0.875rem",
  },
  fontWeights: {
    normal: 300,
    medium: 500,
    bold: 700,
  },
  lineHeights: {},
}
