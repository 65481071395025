import React from "react"
import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormLabel as ChakraFormLabel,
  FormErrorMessage as ChakraFormErrorMessage,
  forwardRef,
  Text,
} from "@chakra-ui/react"
import { ErrorIcon } from "components/ErrorIcon"
import { HelperText } from "components/HelperText"

export interface SharedFormControlProps {
  /**
   * Text to display if there is an error.
   */
  errorText?: string | React.ReactNode
  /**
   * Additional text to describe a form field.
   */
  helperText?: string
  /**
   * Is this form control disabled?
   */
  isDisabled?: boolean
  /**
   * Is this form control invalid?
   */
  isInvalid?: boolean
  /**
   * Read-only does not look disabled but can't be edited.
   */
  isReadOnly?: boolean
  /**
   * Is this field required?
   */
  isRequired?: boolean
  /**
   * Label text describes the form field.
   */
  label?: string | React.ReactNode

  info?: string
}

export interface FormControlProps
  extends Omit<ChakraFormControlProps, "label">,
    SharedFormControlProps {
  /**
   * Sets label to be inside, above or beside input.
   */
  labelStyle?: "standard" | "modern"
  /**
   * Used for wrapping radio or checkbox groups
   */
  asFieldSet?: boolean
  /**
   * Only used for InfoComponentTypes
   */
  value?: string

  isDateInput?: boolean
}

export const FormControl = forwardRef<FormControlProps, "fieldset">(
  (
    {
      label,
      asFieldSet,
      helperText,
      errorText,
      value,
      children,
      isDateInput,
      info,
      ...rest
    },
    ref
  ) => {
    return (
      <ChakraFormControl
        as={asFieldSet ? "fieldset" : undefined}
        {...rest}
        ref={ref}
      >
        {label && (
          <ChakraFormLabel
            as={asFieldSet ? "legend" : undefined}
            sx={{ marginBottom: "4px" }}
          >
            {label}
          </ChakraFormLabel>
        )}
        {helperText && <HelperText helperText={helperText} />}
        {info && <Text>{info}</Text>}
        {children || value}
        {errorText && !isDateInput && (
          <ChakraFormErrorMessage color="errorText">
            <ErrorIcon color="errorText" mr={1} w={4} h={4} /> {errorText}
          </ChakraFormErrorMessage>
        )}
      </ChakraFormControl>
    )
  }
)
