const primaryColor = "#0054A6"

const secondaryColor = "#00245D"

const white = {
  100: "#FFFFFF",
  99: "#FCFCFC",
  98: "#FAFAFA",
  96: "#F5F5F5",
  92: "#EBEBEB",
}

const black = "#000000"

// 👆 variables only used in this file
// -------------------------------------
// 👇 named variables used in the theme

export const colors = {
  bodyColor: "#323B48",
  bodyColorLight: "#5B6470",

  borderColor: primaryColor,

  buttonLinkColor: secondaryColor,
  buttonLinkActiveColor: primaryColor,
  buttonLinkFocusColor: primaryColor,
  buttonLinkHoverColor: primaryColor,
  buttonLinkDisabledColor: "#BEC1C3",
  buttonLinkTextDisabledColor: "#7D8386",

  black: black,
  white: white[100],

  checkedBoxMarkColor: white[100],
  checkedBoxBackgroundColor: primaryColor,

  error: "#E4270C",
  errorText: "#E4270C",

  formInputBackgroundColor: "#FFFFF",
  formInputBorderColor: "#61A2E3",
  formInputFocusedBackgroundColor: white,
  formInputFocusedBorderColor: primaryColor,
  formInputHoveredBorderColor: primaryColor,
  formInputHoveredBackgroundColor: "#C5E1FD",
  formInputTextColor: black,
  formLabelColor: black,

  headingColor: primaryColor,

  navLinkColor: "#2E82D7",
  navLinkActiveColor: primaryColor,
  navLinkActiveAccentColor: secondaryColor,
  navLinkHoverBackgroundColor: white[96],
  navLinkHoverColor: primaryColor,

  offWhite: white[99],

  pageBgColor: white[100],
  pageBgColorShade1: white[98],
  pageBgColorShade2: white[96],
  pageBgColorShade3: white[92],

  primaryColor: primaryColor,

  secondaryColor: secondaryColor,

  siteFooterBackgroundColor: primaryColor,
  siteFooterLinkColor: "#0D736A",
  siteFooterLinkHoverColor: "#1BA094",

  siteHeaderBackgroundColor: primaryColor,

  helperTextColor: "#383C3E",
}
