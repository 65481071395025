import { RouterButton } from "components/common"
import { AppRoutes } from "utilities/constants"
import { useNextPrevPage, useNextPrevPageProps } from "hooks/useNextPrevPage"
import { Button, ButtonProps } from "@chakra-ui/react"

export interface PageProgressButtonProps
  extends ButtonProps,
    useNextPrevPageProps {}

const PageProgressButton = ({ ...rest }: ButtonProps) => {
  return <Button className="page-progress-button" {...rest} />
}

export const NextPageButton = ({
  currentPage,
  pages,
  children = "Next Page",
}: PageProgressButtonProps) => {
  const { isLastPage, handleNextPageButtonClicked } = useNextPrevPage({
    currentPage,
    pages,
  })
  return isLastPage ? (
    <RouterButton
      to={`/${AppRoutes.finalCheck}`}
      className="page-progress-button"
    >
      Review Application
    </RouterButton>
  ) : (
    <PageProgressButton onClick={() => handleNextPageButtonClicked()}>
      {children}
    </PageProgressButton>
  )
}

export const PreviousPageButton = ({
  currentPage,
  pages,
  children = "Previous Page",
}: PageProgressButtonProps) => {
  const { isFirstPage, handlePreviousPageButtonClicked } = useNextPrevPage({
    currentPage,
    pages,
  })

  return (
    <PageProgressButton
      variant="outline"
      onClick={() => handlePreviousPageButtonClicked()}
      isDisabled={isFirstPage}
    >
      {children}
    </PageProgressButton>
  )
}
