import {
  StringToHTMLParser,
  StringToHTMLParserProps,
} from "./StringToHTMLParser"

interface SectionDescriptionProps
  extends Omit<StringToHTMLParserProps, "string"> {
  description: string
}

export const SectionDescription = ({
  description,
  ...rest
}: SectionDescriptionProps) => {
  if (!description) return null
  return <StringToHTMLParser string={description} {...rest} />
}
