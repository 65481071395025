import { Box, useStyleConfig } from "@chakra-ui/react"
import { SiteLogo } from "components/SiteLogo"
import { FullWidthFooter } from "components/FullWidthFooter"
import { LayoutProps } from "components/Layout"
import { LoadingScreen } from "components/LoadingScreen"
import { motion } from "framer-motion"

export const TWO_COL_AUTH_BREAKPOINT = "lg"

export const HollinsAuthLayout = (props: LayoutProps) => {
  const { applicationData, children, showLoadingScreen } = props
  const styles = useStyleConfig("AuthPage")

  if (showLoadingScreen) {
    return (
      <LoadingScreen
        height="full"
        as={motion.div}
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.5 }}
      />
    )
  }

  return (
    <Box
      __css={styles} // "AuthPage"
      display={{ base: "flex", [TWO_COL_AUTH_BREAKPOINT]: "grid" }}
      alignItems={{ base: "start", [TWO_COL_AUTH_BREAKPOINT]: "stretch" }}
      gridTemplateAreas={{
        [TWO_COL_AUTH_BREAKPOINT]: `
            " . top-spacer "
            "logo form"
            "heading form"
            "text-content form"
            "text-content footer"
            " . bottom-spacer"
        `,
      }}
      gridTemplateColumns={{
        [TWO_COL_AUTH_BREAKPOINT]: "1fr 33.819%",
      }}
      gridAutoRows={{
        [TWO_COL_AUTH_BREAKPOINT]: `
            1fr
            auto
            auto
            auto
            auto
            1fr
        `,
      }}
    >
      <SiteLogo
        gridArea="logo"
        alt={props.applicationData.meta.title}
        src={props.applicationData.meta.logo}
      />

      {/* HollinsAuthPage content here */}
      {children}

      <FullWidthFooter
        gridArea="footer"
        id="auth-footer"
        applicationData={applicationData}
        hideSignOut
      />
    </Box>
  )
}
