import { Center, Spinner } from "@chakra-ui/react"

export const LoadingScreen = ({ ...rest }) => {
  const { spinnerProps, children } = rest
  return (
    <Center data-testid="loading-screen" flex={1} {...rest}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primaryColor"
        size="xl"
        {...spinnerProps}
      />
      {children}
    </Center>
  )
}
