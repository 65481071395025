import { Stack, Text } from "@chakra-ui/react"
import { ApplicationData } from "types"
import { H1 } from "components/common"
import { LoadingScreen } from "components/LoadingScreen"
interface ConfirmationPageProps {
  applicationData: ApplicationData
}

const ConfirmationPage = ({ applicationData }: ConfirmationPageProps) => {
  if (!applicationData || applicationData.meta.submissionRedirectUrl) {
    applicationData &&
      window.location.replace(applicationData.meta.submissionRedirectUrl)
    return <LoadingScreen />
  }

  // Placeholder screen if theres no redirect url
  return (
    <>
      <Stack paddingY={{ base: 4, md: 8, lg: 12 }} spacing={{ base: 4, md: 6 }}>
        <H1>We've received your {applicationData.meta.name} application!</H1>
        <Text>
          Thank you! We'll be in contact soon to collect a few remaining items
          to complete your file.
        </Text>
      </Stack>
    </>
  )
}

export default ConfirmationPage
