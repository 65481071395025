import { useContext } from "react"
import { UserContext } from "context/UserContext"
import { Box, BoxProps, Flex, Text } from "@chakra-ui/react"
import { SiteFooter } from "components/LayoutComponents"
import { ExternalLink, Strong } from "components/common"
import { SiteLogo } from "components/SiteLogo"
import SignOutButton from "components/SignOutButton"
import { dehyphenate } from "utilities/formatters"
import { ApplicationData } from "types"
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons"

interface FullWidthFooterProps extends BoxProps {
  applicationData: ApplicationData
  hideSignOut?: boolean
}

export const FullWidthFooter = ({
  applicationData,
  hideSignOut = false,
  ...rest
}: FullWidthFooterProps) => {
  const { user } = useContext(UserContext)

  return (
    <SiteFooter {...rest}>
      <Flex
        id="footer-meta"
        direction={{ base: "column", md: "row" }}
        justify={{ md: "space-between" }}
        className="footer-container"
      >
        {/* Column 1 */}
        <SiteLogo
          alt={applicationData.meta.title}
          src={applicationData.meta.footerLogo}
          mb={{ base: 6, md: 0 }}
        />

        {/* Column 2 */}
        <Box className="contact-details">
          <ExternalLink
            href={`tel:${dehyphenate(applicationData.meta.contact.phone)}`}
          >
            <PhoneIcon
              mr="1.5"
              w="4"
              h="4"
              display="none"
              className="phone-icon"
            />
            {applicationData.meta.contact.phone}
          </ExternalLink>
          <ExternalLink href={`mailto:${applicationData.meta.contact.email}`}>
            <EmailIcon
              mr="1.5"
              w="4"
              h="4"
              display="none"
              className="email-icon"
            />
            {applicationData.meta.contact.email}
          </ExternalLink>
        </Box>

        {/* Column 3 */}
        <ExternalLink
          className="privacy-policy"
          href={applicationData.meta.contact.website}
          children="View our privacy policy"
        />

        {/* Column 4 */}
        <Flex
          id="user-signout"
          direction="column"
          align={{ base: "flex-start", md: "flex-end" }}
          className="footer-user-signout"
        >
          {!hideSignOut && user?.email && (
            <>
              <Text mt={{ base: 6, md: 0 }} mb="0!important">
                Signed in as{" "}
                <Strong>{user.email}</Strong>
              </Text>
              <SignOutButton />
            </>
          )}
        </Flex>
      </Flex>
    </SiteFooter>
  )
}
