import React from "react"
import {
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  NumberInputField as ChakraNumberInputField,
  NumberInputFieldProps as ChakraNumberInputFieldProps,
  NumberInputStepper as ChakraNumberInputStepper,
  NumberInputStepperProps as ChakraNumberInputStepperProps,
  NumberIncrementStepper as ChakraNumberIncrementStepper,
  NumberIncrementStepperProps as ChakraNumberIncrementStepperProps,
  NumberDecrementStepper as ChakraNumberDecrementStepper,
  NumberDecrementStepperProps as ChakraNumberDecrementStepperProps,
  forwardRef,
} from "@chakra-ui/react"

import { FormControl, SharedFormControlProps } from "./FormControl"
import { filterKeyboardInput } from "utilities/helpers"

export interface NumberInputProps
  extends ChakraNumberInputProps,
    SharedFormControlProps {
  disallowDecimals?: boolean
  disallowExponents?: boolean
  hideStepper?: boolean
  /**
   * Props for the actual <input> element
   */
  inputElementProps?: ChakraNumberInputFieldProps
  /**
   * Props for the up and down increment/decrement arrows
   */
  stepperProps?: NumberInputStepperProps
}

export const NumberInput = forwardRef<NumberInputProps, "input">(
  (
    {
      errorText, // start SharedFormControlProps
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label, // end SharedFormControlProps
      disallowDecimals,
      disallowExponents,
      placeholder,
      inputElementProps,
      hideStepper,
      stepperProps,
      ...rest
    },
    forwardRef
  ) => {
    const FormControlValues = {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
    }

    const handleKeyPress = (
      keyPress: React.KeyboardEvent<HTMLInputElement>
    ) => {
      let disallowedCharacters: string[] = []
      disallowDecimals && disallowedCharacters.push(".")
      disallowExponents && disallowedCharacters.push("e")

      return filterKeyboardInput(keyPress, "disallow", disallowedCharacters)
    }

    return (
      <FormControl {...FormControlValues}>
        <ChakraNumberInput {...rest}>
          <ChakraNumberInputField
            ref={forwardRef}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            min={0}
            {...inputElementProps}
          />
          <NumberInputStepper hideStepper={hideStepper} {...stepperProps} />
        </ChakraNumberInput>
      </FormControl>
    )
  }
)

export interface NumberInputFieldProps extends ChakraNumberInputFieldProps {}
export const NumberInputField = ({ ...rest }: NumberInputFieldProps) => (
  <ChakraNumberInputField {...rest} />
)

export interface NumberInputStepperProps extends ChakraNumberInputStepperProps {
  hideStepper?: boolean
}
export const NumberInputStepper = ({
  hideStepper,
  ...rest
}: NumberInputStepperProps) => {
  return !hideStepper ? (
    <ChakraNumberInputStepper {...rest}>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </ChakraNumberInputStepper>
  ) : null
}

export interface NumberIncrementStepperProps
  extends ChakraNumberIncrementStepperProps {}
export const NumberIncrementStepper = ({ ...rest }) => (
  <ChakraNumberIncrementStepper {...rest} />
)

export interface NumberDecrementStepperProps
  extends ChakraNumberDecrementStepperProps {}
export const NumberDecrementStepper = ({ ...rest }) => (
  <ChakraNumberDecrementStepper {...rest} />
)
