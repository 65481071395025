// Tealium Initialization
export default function TealiumInit(clientName) {
  // Data and theme used during development

  const tealiumAccount = "rvaed"
  const tealiumEnv = process.env.REACT_APP_TEALIUM_TARGET_ENV
  const tealiumProfile = tealiumEnv === "prod" ? clientName : "sandbox"

  // https://docs.tealium.com/platforms/javascript/install/
  const tealiumUniversalTag = `(function(a,b,c,d) {
      a='//tags.tiqcdn.com/utag/${tealiumAccount}/${tealiumProfile}/${tealiumEnv}/utag.js';
      b=document;c='script';d=b.createElement(c);d.src=a;
      d.type='text/java'+c;d.async=true;
      a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)})()`

  const script = document.createElement("script")
  script.type = "text/javascript"
  script.text = tealiumUniversalTag
  document.body.appendChild(script)
}
