import {
  Accordion,
  AccordionProps,
  AccordionItem,
  AccordionItemProps,
  AccordionButton,
  AccordionButtonProps,
  AccordionPanel,
  AccordionIcon,
  Box,
  Stack,
  StackProps,
} from "@chakra-ui/react"
import { CSSObject } from "@emotion/react"

/**
 * See https://chakra-ui.com/docs/disclosure/accordion for documentation
 */

export interface AccordionCardProps extends AccordionItemProps {}
/**
 * AccordionCard component MUST be a child of AccordionCardStack component!
 */
export const AccordionCard = ({ ...rest }: AccordionCardProps) => (
  <AccordionItem {...rest} />
)

export const AccordionCardBody = ({ ...rest }) => <AccordionPanel {...rest} />

export const AccordionCardStack = ({
  children,
  spacing,
  ...rest
}: AccordionProps & StackProps) => (
  <Accordion {...rest}>
    <Stack spacing={spacing}>{children}</Stack>
  </Accordion>
)

export interface AccordionCardHeaderProps extends AccordionButtonProps {
  iconStyles?: CSSObject
}
export const AccordionCardHeader = ({
  children,
  iconStyles = { height: 8, width: 14, marginTop: -1 },
  sx,
  ...rest
}: AccordionCardHeaderProps) => {
  return (
    <Box role="heading">
      <AccordionButton {...rest}>
        {typeof children === "string" ? (
          children
        ) : (
          <Box flex="1" textAlign="left" children={children} />
        )}
        <AccordionIcon sx={iconStyles} />
      </AccordionButton>
    </Box>
  )
}
