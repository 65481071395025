import InputMask, { Props as InputMaskProps } from "react-input-mask"
import {
  Flex,
  Box,
  Input as ChakraInput,
  FormErrorMessage as ChakraFormErrorMessage,
} from "@chakra-ui/react"
import { DateFormat, DateFieldTypes } from "types"
import { ErrorIcon } from "components/ErrorIcon"

import { Checkbox } from "./Checkbox"

export interface DateInputMaskProps extends Omit<InputMaskProps, "mask"> {
  type: DateFieldTypes
  isDateInputDisabled?: boolean
  setIsDateInputDisabled?: (boolean) => void
  errorText?: string
}

const DateInputMask = ({
  type,
  onChange,
  disabled,
  isDateInputDisabled,
  errorText,
  setIsDateInputDisabled,
  value,
  ...rest
}: DateInputMaskProps) => {
  const handleCheckboxClick = e => {
    if (!isDateInputDisabled) {
      onChange(e.target.value)
    }
    setIsDateInputDisabled(!isDateInputDisabled)
  }

  const dateFormat = DateFormat[type]

  const formatToMask = dateFormat.replace(/[A-Za-z]/g, "9")

  const isEndDate = rest.name.includes("to_date")

  return (
    <Box w="100%">
      <InputMask
        onChange={originalEvent => {
          const parsedEvent = originalEvent
          //  if there are no numbers in string originalEvent.target.value, return empty
          if (!originalEvent.target.value.match(/\d/g)) {
            parsedEvent.target.value = ""
          }
          return onChange(parsedEvent)
        }}
        mask={formatToMask}
        alwaysShowMask={true}
        maskPlaceholder={dateFormat}
        disabled={isDateInputDisabled || disabled}
        value={value || ""}
        {...rest}
      />
      {errorText && (
        <ChakraFormErrorMessage color="errorText">
          <ErrorIcon color="errorText" mr={1} w={4} h={4} /> {errorText}
        </ChakraFormErrorMessage>
      )}
      {isEndDate && (
        <Flex ml="2" mt="2">
          <Checkbox
            label="I’m currently attending this school"
            value=""
            isChecked={isDateInputDisabled}
            onChange={handleCheckboxClick}
            disabled={false}
            isInvalid={false}
          >
            I’m currently attending this school.
          </Checkbox>
        </Flex>
      )}
    </Box>
  )
}

export const DateInput = ({ ...rest }) => {
  return <ChakraInput as={DateInputMask} {...{ ...rest }} />
}
