export const RELIGIONS = [
  {
    label: "African Methodist Episcopal",
    value: "African Methodist Episcopal",
  },
  { label: "Agnostic", value: "Agnostic" },
  { label: "Anglican", value: "Anglican" },
  { label: "Assembly of God", value: "Assembly of God" },
  { label: "Atheist", value: "Atheist" },
  { label: "Baha'i", value: "Baha'i" },
  { label: "Baptist", value: "Baptist" },
  { label: "Buddhism", value: "Buddhism" },
  { label: "Catholic (Roman)", value: "Catholic (Roman)" },
  { label: "Caodaist", value: "Caodaist" },
  { label: "Cheondoist", value: "Cheondoist" },
  { label: "Christian", value: "Christian" },
  { label: "Christian Scientist", value: "Christian Scientist" },
  { label: "Disciples of Christ", value: "Disciples of Christ" },
  {
    label: "Christian Reformed Church US",
    value: "Christian Reformed Church US",
  },
  { label: "Church of Christ", value: "Church of Christ" },
  {
    label: "Church of Christ, Scientist",
    value: "Church of Christ, Scientist",
  },
  { label: "Church of God", value: "Church of God" },
  { label: "Church of God in Christ", value: "Church of God in Christ" },
  { label: "Church of the Brethren", value: "Church of the Brethren" },
  { label: "Church of the Nazarene", value: "Church of the Nazarene" },
  { label: "Church of World Messianity", value: "Church of World Messianity" },
  { label: "Confucianism", value: "Confucianism" },
  { label: "Congregational", value: "Congregational" },
  { label: "Eastern Orthodox Churches", value: "Eastern Orthodox Churches" },
  { label: "Eastern Rite Catholicism", value: "Eastern Rite Catholicism" },
  { label: "Episcopal", value: "Episcopal" },
  { label: "Evangelical", value: "Evangelical" },
  {
    label: "Evangelical Lutheran Church in America",
    value: "Evangelical Lutheran Church in America",
  },
  { label: "Greek Orthodox", value: "Greek Orthodox" },
  { label: "Hindu", value: "Hindu" },
  { label: "Hmong", value: "Hmong" },
  { label: "Islam (Muslim)", value: "Islam (Muslim)" },
  { label: "Jainism", value: "Jainism" },
  { label: "Jehovah's Witness", value: "Jehovah's Witness" },
  { label: "Judaism", value: "Judaism" },
  { label: "Latter-Day Saints", value: "Latter-Day Saints" },
  { label: "Lutheran", value: "Lutheran" },
  {
    label: "Lutheran Church-Missouri Synod",
    value: "Lutheran Church-Missouri Synod",
  },
  { label: "Lutheran—Wisconsin Synod", value: "Lutheran—Wisconsin Synod" },
  { label: "Mennonite", value: "Mennonite" },
  { label: "Methodist", value: "Methodist" },
  {
    label: "African Methodist Episcopal Church",
    value: "African Methodist Episcopal Church",
  },
  { label: "Missouri Synod Lutheran", value: "Missouri Synod Lutheran" },
  { label: "Moravian", value: "Moravian" },
  { label: "Muslim", value: "Muslim" },
  { label: "None", value: "None" },
  { label: "Nondenominational", value: "Nondenominational" },
  { label: "Orthodox", value: "Orthodox" },
  { label: "Other", value: "Other" },
  { label: "Other-Christian", value: "Other-Christian" },
  { label: "Other-Non-Christian", value: "Other-Non-Christian" },
  { label: "Pentecostalism", value: "Pentecostalism" },
  { label: "Presbyterian", value: "Presbyterian" },
  { label: "Protestant", value: "Protestant" },
  { label: "Rastafari", value: "Rastafari" },
  { label: "Reformed", value: "Reformed" },
  { label: "Reformed Church in America", value: "Reformed Church in America" },
  { label: "Roman Orthodox", value: "Roman Orthodox" },
  { label: "Seicho-no-le-ist", value: "Seicho-no-le-ist" },
  { label: "Seventh-Day Adventist", value: "Seventh-Day Adventist" },
  { label: "Sikhism", value: "Sikhism" },
  {
    label: "Society of Friends (Quakers)",
    value: "Society of Friends (Quakers)",
  },
  {
    label: "Southern Baptist Convention",
    value: "Southern Baptist Convention",
  },
  { label: "Taoism", value: "Taoism" },
  { label: "Tenrikyoist", value: "Tenrikyoist" },
  {
    label: "Unitarian Universalist Assoc",
    value: "Unitarian Universalist Assoc",
  },
  { label: "United Church of Christ", value: "United Church of Christ" },
  { label: "United Methodist", value: "United Methodist" },
  { label: "Wesleyan Church", value: "Wesleyan Church" },
  { label: "Wicca (Pagan)", value: "Wicca (Pagan)" },
  {
    label: "Wisconsin Evangelical Lutheran Synod",
    value: "Wisconsin Evangelical Lutheran Synod",
  },
  { label: "Worldwide Church of God", value: "Worldwide Church of God" },
  { label: "Yazidi", value: "Yazidi" },
]
