import {
  Box,
  Button,
  ButtonProps,
  useStyleConfig,
  ComponentWithAs,
} from "@chakra-ui/react"
import { ReactElement } from "react"

export interface EditButtonProps extends ButtonProps {
  /**
   * Describe this button's action for screen readers
   */
  ariaLabel: string
  onClick?: () => void
  icon: ReactElement | ComponentWithAs<"div", {}>
}

export const IconButton = ({
  ariaLabel,
  onClick,
  icon,
  ...rest
}: EditButtonProps) => {
  const styles = useStyleConfig("IconButton")
  return (
    <Box
      as={Button}
      aria-label={ariaLabel}
      onClick={onClick}
      __css={styles}
      {...rest}
    >
      {icon}
    </Box>
  )
}
