import { Image, ImageProps } from "@chakra-ui/react"

export interface SiteLogoProps extends ImageProps {
  alt: string
  src: string
}

export const SiteLogo = ({ alt, src, ...rest }: SiteLogoProps) => {
  return (
    <Image
      alt={alt}
      className="logo"
      loading="eager"
      objectFit="contain"
      src={src}
      {...rest}
    />
  )
}
