export const Button = {
  baseStyle: {
    borderRadius: "none",
    boxShadow: "buttonRest",
    fontFamily: "heading",
    fontWeight: "bold",
    lineHeight: "button",
    paddingY: 5,
    textTransform: "uppercase",
    whiteSpace: "break-spaces",
    _hover: {
      boxShadow: "buttonHover",
      _disabled: {
        boxShadow: "none",
      },
    },
    _focus: {
      boxShadow: "buttonFocus",
    },
    _active: {
      boxShadow: "buttonActive",
    },

    "&.page-progress-button": {
      paddingY: "0.5625rem",
      paddingX: "1rem",
    },
  },
  sizes: {
    xs: {
      height: "auto",
      paddingX: 2,
      paddingY: 1,
    },
    sm: {
      height: "auto",
      paddingX: 3,
      paddingY: 2,
    },
    md: {
      fontSize: "lg",
      height: "auto",
      paddingX: 4,
      paddingY: "11px",
    },
    lg: {
      fontSize: "lg",
      height: "auto",
      paddingX: 5,
      paddingY: 4,
    },
    xl: {
      fontSize: "lg",
      height: "auto",
      paddingX: 8,
      paddingY: 5,
    },
  },
  variants: {
    solid: {
      background: "buttonColor",
      border: "2px solid transparent",
      color: "white",
      _disabled: {
        background: "#f5f5f5",
        boxShadow: "0 0 2px 0 #f5f5f5 inset",
        color: "#858B93",
        opacity: 1,
      },
      _hover: {
        background: "buttonHoverColor",
        _disabled: {
          background: "#f5f5f5",
          opacity: 1,
        },
      },
      _focus: {
        background: "buttonFocusColor",
        borderColor: "buttonActiveColor",
      },
      _active: {
        background: "buttonActiveColor",
      },
    },
    outline: {
      border: "2px",
      backgroundColor: "offWhite",
      borderColor: "buttonColor",
      color: "buttonColor",
      _hover: {
        backgroundColor: "offWhite",
        borderColor: "buttonHoverColor",
        color: "buttonHoverColor",
        _disabled: {
          borderColor: "buttonColor",
          color: "buttonColor",
        },
      },
      _active: {
        background: "offWhite",
        color: "buttonColor",
      },
    },
    ghost: {
      background: "offWhite",
      color: "buttonColor",
      _hover: {
        backgroundColor: "offWhite",
        color: "buttonHoverColor",
        _disabled: {
          color: "buttonColor",
        },
      },
      _active: {
        color: "buttonColor",
      },
    },
    link: {
      boxShadow: "none",
      color: "linkColor",
      textTransform: "capitalize",
      _hover: {
        color: "primaryColor",
        boxShadow: "none",
        _disabled: {
          color: "linkColor",
        },
      },
      _active: {
        boxShadow: "none",
        color: "linkColor",
      },
    },
  },
}

const ContentWrapper = {
  baseStyle: {
    maxWidth: "90rem",
    paddingX: { base: 4, md: 0 },
  },
}

const Checkbox = {
  parts: ["control", "icon", "label"],
  baseStyle: {
    control: {
      borderColor: "checkboxBorder",
      border: "2px",
      borderRadius: "none",
      _checked: {
        background: "primaryColor",
        color: "primaryColor",
      },
      _hover: {
        borderColor: "checkboxHoverBorder",
        background: "checkboxHoverBackground",
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: 5,
        width: 5,
        borderRadius: "sm",
      },
      icon: {
        fontSize: "0.675rem",
      },
      label: {
        fontSize: "md",
      },
    },
    lg: {
      label: {
        fontSize: { base: "bodyMobile", md: "bodyDesktop" },
      },
    },
  },
  defaultProps: {
    size: "sm",
  },
}

const FinalCheckCard = {
  baseStyle: {
    backgroundColor: "#fafafa",
    borderRadius: "none",
    borderColor: { base: "borderColor", md: "secondaryColor" },
    borderStyle: "solid",
    borderTopWidth: { base: "none", md: "2px " },
    borderRightWidth: { base: "none", md: "2px " },
    borderBottomWidth: "2px!important",
    borderLeftWidth: { base: "none", md: "2px " },
    boxShadow: "none",

    "& .chakra-collapse": {
      overflow: "visible!important",
    },
  },
}

const FinalCheckCardHeader = {
  baseStyle: {
    background: "white",
    borderRadius: "none",
    paddingY: 6,

    "& h2": {
      fontWeight: "500",
    },
    "&[aria-expanded='false'] > svg": {
      transform: " rotate(0) translateY(4px)",
    },
    "&[aria-expanded='true']": {
      backgroundColor: "#fafafa",
      "& > svg": {
        transform: " rotate(-180deg) translateY(-2px)",
      },
    },

    "& .card-header-eyebrow": {
      color: "buttonColor",
      fontFamily: "sabon",
      fontWeight: "normal",
      fontSize: "xl",
      fontStyle: "italic",
    },
    "& .error-badge": {
      background: "transparent",
      borderRadius: "none",
      boxShadow: "none",
      color: "error",
      fontSize: { base: "md", md: "lg" },
      paddingY: 1,
      paddingX: 2,
    },
  },
}

const FormLabel = {
  baseStyle: {
    fontFamily: "futuraBold",
    fontSize: { base: "bodyMobile", md: "bodyDesktop" },
    marginBottom: 2,
  },
}

const Headings = {
  H2: {
    baseStyle: {
      fontFamily: "futura",
      fontWeight: "normal",
    },
  },
  H3: {
    baseStyle: {
      fontFamily: "sabon",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  },
}

const HelperText = {
  baseStyle: {
    fontSize: { base: "bodyMobile", md: "bodyDesktop" },
  },
}

const Input = {
  baseStyle: {
    field: {
      borderRadius: "none",
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: "bodyDesktop",
        height: 12,
        borderRadius: "none",
      },
    },
    lg: {
      field: {
        fontSize: { base: "bodyMobile", md: "bodyDesktop" },
        height: { base: "3.5rem", md: "3.75rem" },
      },
    },
  },
  variants: {
    outline: {
      field: {
        background: "formInputBackgroundColor",
        borderColor: "formInputBorderColor",
        borderRadius: "none",
        fontFamily: "futura",
        fontSize: { base: "bodyMobile", md: "bodyDesktop" },
        lineHeight: { base: "baseMobile", md: "baseDesktop" },
        _focus: {
          background: "formInputFocusedBackgroundColor",
          borderColor: "formInputBorderColor",
          borderWidth: "2px",
          boxShadow: "none",
        },
        _hover: {
          borderColor: "formInputHoveredBorderColor",
        },
        _invalid: {
          backgroundColor: "formInputFocusedBackgroundColor",
          borderColor: "error",
          borderWidth: "2px",
          boxShadow: "none",
          _focus: {
            borderColor: "error",
            boxShadow: "inputFocusedError",
          },
        },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
}

const LayoutCentered = {
  baseStyle: {
    "& > main": {
      // Inherits from base theme
      // flex: 1,
      // marginX: "auto",
      // maxWidth: "4xl",
      // width: "full",
      // wrapperProps: { paddingX: { base: 0, md: 6 } },
      "& .final-check, & .final-check ~ div": {
        marginLeft: { base: "-1rem", md: 0 },
        width: { base: "calc(100% + 2rem)", md: "100%" },
      },
    },
  },
}

const Nav = {
  baseStyle: {
    letterSpacing: "1px",
    fontWeight: "bold",
    fontSize: "1.125rem",
    paddingTop: { md: 6, lg: 14 },
    textTransform: "uppercase",

    "& a": {
      paddingX: 5,
      paddingY: "calc(0.75rem + 2px)",

      "&.active:hover .nav-numbers, &.active .nav-numbers ": {
        color: "navLinkActiveColor",
      },
    },

    "& .nav-numbers": {
      color: "navLinkColor",
      fontFamily: "futura",
      fontSize: "1.75rem",
      fontWeight: "normal",
      lineHeight: 1,
      marginRight: 2,
    },
  },
}

const NestedSectionDataParser = {
  baseStyle: {
    border: "4px solid",
    borderColor: "borderColorAlt",
    borderRadius: "none",
  },
}

const OptionalTag = {
  baseStyle: {
    fontFamily: "futura",
    letterSpacing: "0.5px",
  },
}

const PageHeader = {
  baseStyle: {
    "&.final-check": {
      paddingTop: { base: 4, md: 8 },
    },
  },
}

const Radio = {
  parts: ["control", "label"],
  baseStyle: {
    control: {
      border: "2px",
      borderColor: "checkboxBorder",
      _checked: {
        boxShadow: "radioHeavy",
      },
      _hover: {
        borderColor: "checkboxHoverBorder",
        background: "checkboxHoverBackground",
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: 5,
        width: 5,
      },
      label: {
        fontSize: "md",
        _checked: {
          fontWeight: "600",
        },
      },
    },
    lg: {
      label: {
        fontSize: { base: "bodyMobile", md: "bodyDesktop" },
      },
    },
  },
  defaultProps: {
    size: "sm",
  },
}

const SectionDataParser = {
  baseStyle: {
    backgroundColor: "transparent",
    borderBottom: { base: "2px", md: "none" },
    borderBottomColor: "borderColor",
    borderRadius: "none",
    boxShadow: "none",
    paddingX: { base: 4, md: 0 },
    paddingTop: { base: 0 },
    paddingBottom: { base: 4, md: 12 },
  },
}

const SelectMenuNav = {
  baseStyle: {
    "&#mobile-nav": {
      "& > div": {
        minWidth: "max-content",
        width: "35%",
      },
    },
    "&#progress-bar-nav": {
      paddingX: 2,
      paddingY: 0,
    },
    "&#mobile-nav, &#progress-bar-nav": {
      "& select": {
        borderRadius: "none",
        borderColor: "borderColorAlt",
        borderWidth: "2px",
        color: "bodyColor",
        height: "2.75rem",
      },
    },
  },
}

const SiteFooter = {
  baseStyle: {
    paddingX: { md: 2 },
    fontSize: { base: "bodyMobile" },
    lineHeight: { base: "baseMobile" },

    "& .logo": {
      height: "auto",
      marginLeft: { md: 4, lg: 8 },
      width: "135px",
    },

    "& a, & .sign-out-button": {
      color: "linkColorDark",
      _hover: { color: "linkHoverColorDark" },
    },
  },
}

const SiteHeader = {
  baseStyle: {
    paddingTop: { base: 5 },
    paddingBottom: { base: 4 },
    paddingY: {
      md: 5,
      lg: 5,
    },
    "& .logo": {
      height: { base: "auto", md: "60px" },
      marginX: "auto",
      padding: {
        base: "12px 10px",
        md: "10px",
        lg: "7px 9px",
      },

      width: {
        base: "166px",
        md: "230px",
        lg: "294px",
      },
    },
  },
}

const Textarea = {
  sizes: {
    xs: {
      fontSize: { base: "bodyMobile", md: "bodyDesktop" },
    },
    sm: {
      field: {
        fontSize: { base: "bodyMobile", md: "bodyDesktop" },
      },
    },
    md: {
      field: {
        fontSize: { base: "bodyMobile", md: "bodyDesktop" },
      },
    },
    lg: {
      field: {
        fontSize: { base: "bodyMobile", md: "bodyDesktop" },
      },
    },
  },
  variants: {
    outline: {
      borderRadius: 0,
    },
  },
}

const XButton = {
  baseStyle: {
    background: "buttonColor",
    borderRadius: "full",
    boxShadow: "none",
  },
  variants: {
    solid: {
      background: "buttonColor",
      border: "2px solid transparent",
      color: "white",
      fontSize: "sm",
      height: 6,
      minWidth: 6,
      padding: 0,
      width: 6,
      _hover: {
        background: "buttonHoverColor",
        _disabled: {
          background: "buttonColor",
        },
      },
      _focus: {
        background: "buttonFocusColor",
        borderColor: "buttonActiveColor",
      },
      _active: {
        background: "buttonActiveColor",
      },
    },
  },
  defaultProps: {
    variant: "solid",
  },
}

// // ------------------------------------------------------------------------------
// // AuthPage and AuthFormWrapper use styles from components above.
const AuthFormWrapper = {
  baseStyle: {
    borderRadius: "none",
    boxShadow: "none",

    "& #api": {
      input: {
        ...Input.baseStyle.field,
        ...Input.sizes.lg.field,
      },
      button: {
        ...Button.baseStyle,
        ...Button.variants.solid,
        textTransform: "uppercase",
      },
      label: {
        ...FormLabel.baseStyle,
      },
      // Sign Up, Sign In, & Forgot Password input groups
      "#attributeList.attr li + li, #localAccountForm .entry-item + .entry-item,#emailVerificationControl li + li .attrEntry, button#continue":
        {
          marginTop: { base: 8 },
        },
    },
  },
}

const borderedBoxPx = {
  base: "1.5rem",
}
const borderedBoxPy = {
  base: "1rem",
  lg: "1.5rem",
}
const AuthPage = {
  baseStyle: {
    maxWidth: "90rem",
    paddingX: { base: 4, md: 7 },

    "& .text-content > *:not(h1)": {
      color: "black",
    },

    "& .break-lg": {
      display: { lg: "block" },
    },

    "& .nowrap": {
      whitespace: "nowrap",
      "&-md": {
        whiteSpace: { md: "nowrap" },
      },
      "&-lg": {
        whiteSpace: { lg: "nowrap" },
      },
    },

    "&#sign-up": {
      paddingX: { base: 0 },

      "& #authPageWrapper": {
        columnGap: {
          base: 0,
          lg: "5.25vw",
        },
        gridTemplateAreas: {
          base: `
            "heading"
            "description"
            "form"`,
          lg: `
            "heading form"
            "description form"`,
        },
        gridTemplateColumns: {
          md: "1fr max-content",
          lg: "1fr 30.25rem",
        },
        padding: { base: 6, md: 12 },
      },
      "& ": {
        "h1, h2, #form-heading": {
          color: "headingColor",
        },
        h1: {
          fontSize: { lg: "2.75rem", xl: "3.375rem" },
          textAlign: { base: "center", md: "left" },
        },
        h2: {
          fontFamily: "body",
          fontSize: { base: "1.42rem", md: "2xl" },
          lineHeight: { base: 1.61 },
          marginBottom: { base: 2, md: 6 },
        },
        "#form-heading": {
          display: "block",
          fontFamily: "sabon",
          fontSize: { base: "1.25rem" },
          fontStyle: "italic",
          lineHeight: { base: 1.7 },
          marginBottom: { base: 4 },
        },
        "#form-subtext": {
          position: "relative",
          textAlign: "left",

          "&::before": {
            content: "''",
            display: "block",
            height: "1px",
            width: "100%",
            background: "#C2C2C2",
            position: "relative",
            marginY: { base: 8 },
          },

          "& a.userflow": {
            color: "primaryColor",
            display: "inline",
            marginLeft: 1,
            fontFamily: "futuraBold",
            _hover: {
              color: "linkColor",
            },
          },
        },
        ".grid": {
          display: "flex", // just kidding about that .grid thing
          gap: 6,

          section: {
            marginY: borderedBoxPy,
          },
          figure: {
            display: {
              base: "none",
              sm: "block",
            },
            flexShrink: 0,
            flexBasis: {
              sm: "35%",
              md: "44%",
            },
            background:
              "url(https://www.vanguard-university.org/vu-susi.jpg) top center no-repeat",
            backgroundSize: "cover",
            position: "relative",

            "&::before": {
              content: "''",
              position: "absolute",
              width: { base: `calc(100% - calc(${borderedBoxPx.base} * 2))` },
              height: {
                base: `calc(100% - calc(${borderedBoxPy.base} * 2))`,
                lg: `calc(100% - calc(${borderedBoxPy.lg} * 2))`,
              },
            },
          },

          "section, figure::before": {
            border: "2px solid",
            borderColor: "secondaryColor",
          },

          "section, figure": {
            paddingX: borderedBoxPx,
            paddingY: borderedBoxPy,
          },

          "+ p": {
            textAlign: { base: "center", md: "left" },
          },
        },
      },
    },

    "&#forgot-password": {
      "& #attributeList + .buttons": {
        "& #cancel": {
          ...Button.variants.outline,
        },
      },
    },
  },
}

const PhoneInput = {
  baseStyle: {
    containerStyle: {
      border: "1px solid #003767",
    },
    inputStyle: {
      border: "none",
    },
    buttonStyle: {
      border: "none",
    },
  },
}

const MultiSelect = {
  baseStyle: {
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? "navLinkColor" : "none",
        "&:active, &:focus": { backgroundColor: "selectHoverColor" },
        "&:hover": {
          backgroundColor: state.isSelected
            ? "navLinkColor"
            : "selectHoverColor",
        },
      }
    },
  },
}

export const components = {
  AuthFormWrapper,
  AuthPage,
  Button,
  ContentWrapper,
  Checkbox,
  FinalCheckCard,
  FinalCheckCardHeader,
  MultiSelect,
  FormLabel,
  ...Headings,
  HelperText,
  Input,
  LayoutCentered,
  Nav,
  NestedSectionDataParser,
  NumberInput: {
    ...Input,
  },
  OptionalTag,
  PageHeader,
  Radio,
  SectionDataParser,
  Select: {
    ...Input,
  },
  SelectMenuNav,
  SiteFooter,
  SiteHeader,
  Textarea,
  XButton,
  PhoneInput,
}
