import { useNavigate } from "react-router-dom"
import { PageData } from "types"

export interface useNextPrevPageProps {
  currentPage?: PageData
  pages: PageData[]
}

export function useNextPrevPage({ currentPage, pages }: useNextPrevPageProps) {
  const navigate = useNavigate()
  const pageIndex = pages.findIndex(page => page.id === currentPage?.id)
  const nextPage = pages[pageIndex + 1]
  const previousPage = pageIndex >= 0 ? pages[pageIndex - 1] : undefined

  const currentPageNumber = pageIndex + 1
  const isFirstPage = currentPageNumber === 1
  const isLastPage = currentPageNumber === pages.length

  const handleNextPageButtonClicked = () => {
    return navigate(`/${nextPage.slug}`)
  }

  const handlePreviousPageButtonClicked = () => {
    if (previousPage) {
      return navigate(`/${previousPage.slug}`)
    }
  }

  return {
    currentPageNumber,
    isFirstPage,
    isLastPage,
    handleNextPageButtonClicked,
    handlePreviousPageButtonClicked,
  }
}
