import { forwardRef } from "@chakra-ui/react"
import { InfoComponentTypes } from "types"
import { BASE_FIELD_SPACING } from "utilities/constants"
import { FormControl, SharedFormControlProps } from "./FormControl"

export interface InfoBoxProps extends SharedFormControlProps {
  /**
   * type = "subtext" will appear below fields, styled as helper text.
   * type = "info" will appear at the bottom of a section.
   */
  type?: InfoComponentTypes
  value?: string
}
/**
 * A component with no input, purely for displaying information. Displayed when using "info" or "subtext" as field type. */
export const InfoBox = forwardRef<InfoBoxProps, "legend">(
  ({ type, value, ...rest }, ref) => {
    if (type === "subtext") {
      return <Subtext helperText={value} ref={ref} {...rest} />
    }
    return <FormControl children={value} ref={ref} {...rest} />
  }
)

/**
 *
 */
export const Subtext = forwardRef<InfoBoxProps, "legend">(
  ({ ...rest }, ref) => {
    return (
      <FormControl
        marginTop={{ base: -(BASE_FIELD_SPACING / 2) }}
        ref={ref}
        {...rest}
      />
    )
  }
)
