import { extendTheme } from "@chakra-ui/react"
import { baseTheme } from "themes"

import { colors } from "./colors"
import { components } from "./components"
import { shadows } from "./shadows"
import { typography } from "./typography"

const vanguardTheme = {
  colors,
  components,
  shadows,
  ...typography,

  styles: {
    global: {
      body: {
        lineHeight: { base: "baseMobile", md: "baseDesktop" },
      },
      a: {
        color: "linkColor",
        textDecoration: "underline",
        _hover: { color: "linkHoverColor" },
      },
      "a, button": {
        transition: "all .1s",

        "&#double-check": {
          textTransform: "uppercase",
        },
      },
      strong: {
        fontFamily: "futuraBold",
      },
      em: {
        fontFamily: "sabon",
        fontStyle: "italic",
      },

      ".country-list::-webkit-scrollbar": {
        width: "6px",
      },

      ".country-list::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px white",
      },

      ".country-list::-webkit-scrollbar-thumb": {
        backgroundColor: "#e4e7ed",
        borderRadius: "4px",
        height: "60px",
      },
      ".search": {
        width: "100%",
        borderBottom: "1px solid #ced0d4",
      },
      ".selected-flag": {
        paddingLeft: "15px !important",
      },
      ".country-list li": {
        marginTop: "8px !important",
        paddingRight: "10px !important",
      },
      ".react-tel-input:focus-within": {
        border: "2px solid #013767 !important",
      },
      ".error.react-tel-input:focus-within": {
        border: "2px solid #B01C2E !important",
        boxShadow: "0 0 6px 0 #B01C2E",
      },
      ".error": {
        border: "2px solid #B01C2E !important",
      },

      ".error .form-control, .error .flag-dropdown, .error .selected-flag": {
        border: "none",
        background: "white !important",
      },

      ".country:hover": {
        backgroundColor: "#E6EBF0 !important",
      },
      ".country.highlight, .country.highlight .dial-code": {
        backgroundColor: "#41698D !important",
        color: "white !important",
      },
    },
  },
}

const theme = extendTheme({ ...baseTheme }, vanguardTheme)

export default theme
