import { Box, Grid, Link, Stack, Text, useTheme } from "@chakra-ui/react"
import {
  Main,
  PageContent,
  PageHeader,
  SiteFooter,
  SiteHeader,
} from "components/LayoutComponents"
import { Nav } from "./Nav"
import { H1, Strong } from "components/common"
import { SiteLogo } from "components/SiteLogo"
import { dehyphenate } from "utilities/formatters"
import SignOutButton from "components/SignOutButton"
import { useContext } from "react"
import { UserContext } from "context/UserContext"

/**
 * 2: Fixed Sidebar, Scrolling Content
 * ----------------------------------
 * [Branding  ] [  PageHeader (if shown)  ]
 * [Navigation] [                   ]
 * [          ] [      Content      ]
 * [          ] [                   ]
 * [SiteFooter    ] [                   ]
 */

export const Layout2 = (props: any) => {
  const theme = useTheme()
  const { user } = useContext(UserContext)

  return (
    <Grid
      backgroundColor="primaryColor"
      width="full"
      height={{ md: "100vh" }}
      overflow={{ md: "hidden" }}
      templateColumns={{
        md: "minmax(min-content, 1fr) 2.65fr",
        lg: "minmax(348px, 1fr) 4fr",
      }}
      templateRows={{ md: "auto 1fr auto" }}
      templateAreas={{
        base: `
      "header"
      "nav"
      "main"
      "footer"`,

        md: `
      "header main"
      "nav main"
      "footer main"`,
      }}
    >
      <SiteHeader marginBottom={{ md: 4 }} paddingY={{ base: 4, md: 6 }}>
        <SiteLogo
          alt={props.applicationData.meta.title}
          src={props.applicationData.meta.logo}
          marginX="auto"
        />
      </SiteHeader>

      <Nav
        ref={props.navRef}
        pages={props.applicationData.pages}
        layoutLinkStyles={{
          color: "navLinkColor",
          activeStyle: {
            borderLeftColor: theme.colors["navLinkColor"],
            color: theme.colors["navLinkActiveColor"],
          },
          _hover: {
            backdropFilter: "brightness(0.92)",
            borderLeftColor: theme.colors["navLinkHoverColor"],
            color: theme.colors["navLinkHoverColor"],
          },
        }}
      />

      <Main
        ref={props.mainRef}
        background="pageBgColor"
        paddingBottom={{ md: props.progressBarHeight }}
        overflow="auto"
        paddingX={{ md: 2, lg: 8 }}
        wrapperProps={{ maxWidth: "5xl", paddingX: { base: 0, md: 4 } }}
      >
        <PageHeader>
          <H1>{props.currentPage?.title}</H1>
          {props.currentPage?.description && (
            <Text>{props.currentPage?.description}</Text>
          )}
        </PageHeader>
        <PageContent py={2}>{props.children}</PageContent>
      </Main>

      <SiteFooter
        fontSize="13px"
        lineHeight={1.85}
        marginBottom={{ base: props.progressBarHeight, md: 0 }}
        paddingY={{ base: 4, md: 6 }}
        sx={{
          "& a": {
            display: "block",
            whiteSpace: "nowrap",
          },
          "& a, & button, & p": {
            fontSize: "inherit",
            lineHeight: "inherit",
            // unique to this layout
            color: "navLinkColor",
            _hover: {
              color: "navLinkColor",
            },
            _active: {
              color: "navLinkColor",
            },
          },
        }}
      >
        <Stack spacing={6}>
          <Box>
            <Link
              href={`tel:${dehyphenate(
                props.applicationData.meta.contact.phone
              )}`}
              children={props.applicationData.meta.contact.phone}
            />
            <Link
              href={`mailto:${props.applicationData.meta.contact.email}`}
              children={props.applicationData.meta.contact.email}
            />
            <Link
              href={props.applicationData.meta.contact.website}
              children="View our privacy policy"
            />
          </Box>
          {user && (
            <Box>
              <Text>
                Signed in as <Strong children={user.email} />
              </Text>
              <SignOutButton />
            </Box>
          )}
        </Stack>
      </SiteFooter>
    </Grid>
  )
}
