import { User } from "types/user"
import { getApiHeaders } from "utilities/helpers"
import ApiRoot from "./apiRoot"

export class UserApi extends ApiRoot {
  static me({ token }: { token: string }): Promise<User> {
    return this.connection
      .get(`/ca/me`, getApiHeaders({ token }))
      .then(response => {
        return getUserValuesFromUserApi(response.data)
      })
  }

  static replace({
    user,
    token,
  }: {
    token: string
    user: User
  }): Promise<User> {
    return this.connection
      .put(`/ca`, user, getApiHeaders({ token }))
      .then(response => {
        return response.data
      })
  }

  static finalize({
    user,
    token,
  }: {
    token: string
    user: User
  }): Promise<User> {
    return this.connection
      .put(`/ca/finalize`, user, getApiHeaders({ token }))
      .then(response => {
        return response.data
      })
  }

  static deleteEssay({ token }: { token: string }): Promise<{
    [key: string]: any
  }> {
    return this.connection
      .delete(`/ca/essay`, getApiHeaders({ token }))
      .then(response => {
        return response.data.applicationData
      })
  }

  static uploadEssay({
    token,
    file,
  }: {
    token: string
    file: FormData
  }): Promise<any> {
    return this.connection
      .post(
        `/ca/essay/upload`,
        file,
        getApiHeaders({ token, isFileUpload: true })
      )
      .then(response => {
        return response.data.applicationData
      })
  }
}

export const getUserValuesFromUserApi = (user: User): User => {
  const {
    createdAt,
    id,
    userId,
    email,
    applicationData,
    lastUpdated,
    submittedAt,
  } = user

  return {
    createdAt,
    id,
    userId,
    email,
    applicationData: {
      ...applicationData,
      email_address: email,
    },
    lastUpdated,
    submittedAt,
  }
}
