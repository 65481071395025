export const Button = {
  baseStyle: {
    borderRadius: "none",
    boxShadow: "buttonRest",
    fontFamily: "heading",
    fontWeight: "medium",
    lineHeight: "button",
    _hover: {
      boxShadow: "buttonHover",
      _disabled: {
        boxShadow: "none",
      },
    },
    _focus: {
      boxShadow: "buttonFocus",
    },
    _active: {
      boxShadow: "buttonActive",
    },
  },
  sizes: {
    xs: {
      height: "auto",
      paddingX: 2,
      paddingY: 1,
    },
    sm: {
      height: "auto",
      paddingX: 3,
      paddingY: 2,
    },
    md: {
      fontSize: "md",
      height: "auto",
      paddingX: 4,
      paddingY: "10px",
    },
    lg: {
      fontSize: "md",
      height: "auto",
      lineHeight: 1,
      paddingX: 5,
      paddingY: 4,
    },
    xl: {
      fontSize: "md",
      height: "auto",
      paddingX: 8,
      paddingY: 5,
    },
  },
  variants: {
    solid: {
      background: "buttonLinkColor",
      color: "offWhite",
      _hover: {
        background: "buttonLinkHoverColor",
        _disabled: {
          background: "buttonLinkColor",
        },
      },
      _focus: {
        background: "buttonLinkFocusColor",
      },
      _active: {
        background: "buttonLinkActiveColor",
      },
    },
    outline: {
      border: "2px",
      backgroundColor: "offWhite",
      borderColor: "buttonLinkColor",
      color: "buttonLinkColor",
      _hover: {
        backgroundColor: "offWhite",
        borderColor: "buttonLinkHoverColor",
        color: "buttonLinkHoverColor",
        _disabled: {
          borderColor: "buttonLinkColor",
          color: "buttonLinkColor",
        },
      },
      _active: {
        background: "offWhite",
        color: "buttonLinkColor",
      },
    },
    ghost: {
      background: "offWhite",
      color: "buttonLinkColor",
      _hover: {
        backgroundColor: "offWhite",
        color: "buttonLinkHoverColor",
        _disabled: {
          color: "buttonLinkColor",
        },
      },
      _active: {
        color: "buttonLinkColor",
      },
    },
    link: {
      boxShadow: "none",
      color: "buttonLinkColor",
      _hover: {
        color: "buttonLinkHoverColor",
        boxShadow: "none",
        _disabled: {
          color: "buttonLinkColor",
        },
      },
      _active: {
        boxShadow: "none",
        color: "buttonLinkColor",
      },
    },
  },
}

const Checkbox = {
  parts: ["control", "icon", "label"],
  baseStyle: {
    control: {
      background: "white",
      border: "1px",
      borderColor: "formInputBorderColor",
      borderRadius: "none",
      _hover: {
        borderColor: "formInputHoveredBorderColor",
        background: "formInputBackgroundColor",
      },
      _checked: {
        background: "checkedBoxBackgroundColor",
        borderColor: "checkedBoxBackgroundColor",

        _hover: {
          background: "checkedBoxBackgroundColor",
          borderColor: "checkedBoxBackgroundColor",
        },
        _focus: {
          _hover: {
            background: "checkedBoxBackgroundColor",
            borderColor: "checkedBoxBackgroundColor",
          },
        },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: 5,
        width: 5,
        borderRadius: "sm",
      },
      icon: {
        fontSize: "0.675rem",
      },
      label: {
        fontSize: "md",
      },
    },
    md: {
      control: {
        height: 8,
        width: 8,
      },
      icon: {
        fontSize: "0.795rem",
      },
      label: {
        fontSize: "sm",
      },
    },
    lg: {
      control: {
        height: 10,
        width: 10,
      },
      icon: {
        fontSize: "0.915rem",
      },
      label: {
        fontSize: "md",
      },
    },
  },
  defaultProps: {
    size: "sm",
  },
}

const FinalCheckCard = {
  baseStyle: {
    "& .chakra-collapse": {
      overflow: "visible!important",
    },
  },
}

const FinalCheckCardHeader = {
  baseStyle: {
    background: "pageBgColorShade1",
    paddingY: 6,
    "& > svg": {
      transform: "translateY(2px)",
    },
    "& .card-header-eyebrow": {
      fontWeight: "medium",
      fontSize: "xl",
    },
    "& .error-badge": {
      borderRadius: "xl",
      fontSize: { base: "md", md: "lg" },
      paddingY: { base: 1, md: 2 },
      paddingX: { base: 2 },
    },
  },
}

const Headings = {
  H1: {
    baseStyle: {
      fontWeight: "medium",
    },
  },
  H2: {
    baseStyle: {
      fontWeight: "medium",
    },
  },
  H3: {
    baseStyle: {
      fontWeight: "medium",
    },
  },
}

const Input = {
  baseStyle: {
    field: {
      borderRadius: "none",
    },
  },
  sizes: {
    xs: {
      fontSize: "bodyMobile",
      height: 9,
      borderRadius: "none",
    },
    bodyMobile: {
      field: {
        fontSize: "bodyMobile",
        height: 10,
        borderRadius: "none",
      },
    },
    md: {
      field: {
        fontSize: "bodyDesktop",
        height: 12,
        borderRadius: "none",
      },
    },
    lg: {
      field: {
        fontSize: "bodyDesktop",
        height: 12,
        borderRadius: "none",
      },
    },
  },
  variants: {
    outline: {
      field: {
        background: "formInputBackgroundColor",
        borderColor: "formInputBorderColor",
        _focus: {
          background: "white",
          borderColor: "formInputFocusedBorderColor",
          borderWidth: "2px",
          boxShadow: "formInputBorderColor",
        },
        _hover: {
          borderColor: "formInputHoveredBorderColor",
          background: "formInputBackgroundColor",
        },
        _invalid: {
          backgroundColor: "white",
          borderColor: "error",
          borderWidth: "2px",
          boxShadow: "none",
          _focus: {
            borderColor: "error",
            boxShadow: "inputFocusedError",
          },
        },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
}

const LayoutCentered = {
  baseStyle: {
    "& > main": {
      maxWidth: "812px",

      // Inherits from base theme
      // flex: 1,
      // marginX: "auto",
      // maxWidth: "4xl",
      // width: "full",
      // wrapperProps: { paddingX: { base: 0, md: 6 } },
    },
  },
}

const Nav = {
  baseStyle: {
    fontWeight: "medium",
    fontSize: "1.25rem",
  },
}

const NestedSectionDataParser = {
  baseStyle: {
    backgroundColor: "pageBgColor",
    border: "1px solid",
    borderColor: "formInputBorderColor",
    borderRadius: "none",
    padding: { base: 4, md: 6 },
  },
}

const PageHeader = {
  baseStyle: {
    paddingTop: {
      base: 6,
      md: 0,
    },
    "&.final-check": {
      paddingTop: { base: 4, md: 0 },
    },
  },
}

const Radio = {
  parts: ["control", "label"],
  baseStyle: {
    control: {
      _checked: {
        background: "checkedBoxMarkColor",
        boxShadow: "none",
        _hover: {
          borderColor: "formInputBorderColor",
        },
        _before: {
          background: "checkedBoxBackgroundColor",
          height: "55%",
          width: "55%",
        },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: 5,
        width: 5,
      },
      label: {
        fontSize: "md",
        _checked: {
          fontWeight: "600",
        },
      },
    },
    md: {
      control: {
        height: 8,
        width: 8,
      },
      label: {
        fontSize: "sm",
      },
    },
    lg: {
      control: {
        height: 10,
        width: 10,
      },
      label: {
        fontSize: "md",
      },
    },
  },
  defaultProps: {
    colorScheme: "whiteAlpha",
    size: "sm",
  },
}

const SectionDataParser = {
  baseStyle: {
    backgroundColor: "transparent",
    borderBottom: { base: "2px", md: "none" },
    borderBottomColor: "borderColor",
    borderRadius: "none",

    paddingX: { base: 4, md: 6 },
    paddingTop: { base: 0, md: 0 },
    paddingBottom: { base: 12 },
  },
}

const SiteFooter = {
  baseStyle: {
    borderTopColor: "secondaryColor",
    borderTopStyle: "solid",
    borderTopWidth: { base: "8px", md: "12px" },
    boxShadow: "siteFooter",
    lineHeight: 1.845,
    paddingTop: {
      base: 10,
      md: 12,
    },
    paddingBottom: {
      base: 6,
      md: 12,
    },
    "& a, & p, & .sign-out-button, & a:hover": {
      color: "white",
    },
    "& .sign-out-button": {
      textDecoration: "underline",
    },
    "& .logo": {
      height: "auto",
      width: {
        base: "130px",
        md: "100px",
      },
    },
  },
}

const HelperText = {
  baseStyle: {
    fontSize: "helperText",
    marginBottom: "4px !important",
  },
}

const SiteHeader = {
  baseStyle: {
    borderBottomColor: "secondaryColor",
    borderBottomStyle: "solid",
    borderBottomWidth: { base: "8px", md: "12px" },
    boxShadow: "siteHeader",
    marginBottom: {
      base: 0,
      md: 11,
    },
    paddingTop: {
      base: 5,
      md: 8,
    },
    paddingBottom: {
      base: 7,
      md: 8,
    },
    "& .logo": {
      height: {
        base: "auto",
        md: "48px",
      },
      width: {
        base: "148px",
        md: "145px",
      },
    },
  },
}

const Textarea = {
  sizes: {
    xs: {
      fontSize: "sm",
      borderRadius: "none",
    },
    sm: {
      fontSize: "sm",
      borderRadius: "none",
    },
    md: {
      fontSize: "md",
      borderRadius: "none",
    },
    lg: {
      fontSize: "md",
      borderRadius: "none",
    },
  },

  variants: {
    outline: {
      _focus: {
        boxShadow: "lg",
        borderWidth: "2px",
        background: "white",
        borderColor: "formInputBorderColor",
      },
    },
  },
  defaultProps: {
    size: "lg",
    variant: "outline",
  },
}

const XButton = {
  defaultProps: {
    variant: "link",
  },
}

// ------------------------------------------------------------------------------
// AuthPage and AuthFormWrapper use styles from components above.
const AuthFormWrapper = {
  baseStyle: {
    borderRadius: "none",
    boxShadow: "card",
    marginBottom: { base: 4, md: 0 },

    "& #api": {
      input: {
        ...Input.baseStyle.field,
      },
      button: {
        ...Button.baseStyle,
        textTransform: "capitalize",
      },
    },
  },
}

const AuthPage = {
  baseStyle: {
    maxWidth: "7xl", // 7xl = 80rem = 1280px
    paddingX: { base: 4, md: 7 },

    "& .text-content > *": {
      color: "black",
    },

    "&#sign-up": {
      // Inherits from base theme
    },

    "&#forgot-password": {
      "& #attributeList + .buttons": {
        "& #cancel": {
          ...Button.variants.ghost,
        },
      },
    },
  },
}

const PhoneInput = {
  baseStyle: {
    buttonStyle: {
      borderColor: "#026b5480",
      backgroundColor: "#E5F0EE",
    },
    inputStyle: {
      borderColor: "#026b5480",
      backgroundColor: "#E5F0EE",
    },
  },
}

const MultiSelect = {
  baseStyle: {
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? "navLinkColor" : "none",
        "&:active, &:focus": { backgroundColor: "formInputBackgroundColor" },
        "&:hover": {
          backgroundColor: state.isSelected
            ? "navLinkColor"
            : "formInputBackgroundColor",
        },
      }
    },
    menuList: provided => {
      return {
        ...provided,
        maxHeight: "150px",
      }
    },
  },
}

export const components = {
  AuthFormWrapper,
  AuthPage,
  Button,
  Checkbox,
  FinalCheckCard,
  FinalCheckCardHeader,
  ...Headings,
  Input,
  LayoutCentered,
  Nav,
  NestedSectionDataParser,
  NumberInput: {
    ...Input,
  },
  PageHeader,
  Radio,
  SectionDataParser,
  Select: {
    ...Input,
  },
  SiteFooter,
  SiteHeader,
  Textarea,
  XButton,
  PhoneInput,
  MultiSelect,
  HelperText,
}
