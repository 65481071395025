import { forwardRef } from "@chakra-ui/react"
import { MultiSelect, MultiSelectProps } from "components/common"
import { alphabetize } from "utilities/formatters"
import debounce from "debounce-promise"

export interface SearchOption {
  label: string
  value: string
  [key: string]: string
}

export const filterOptionsBySearchTerm = (
  options: SearchOption[],
  searchTerm = "",
  key = "label" // one of the SearchOption keys
) => {
  return alphabetize(
    options.filter(option => {
      return option[key].toLowerCase().includes(searchTerm.toLowerCase())
    }),
    key
  )
}

export interface SearchboxProps extends MultiSelectProps {}

export const Searchbox = forwardRef<SearchboxProps, "div">(
  ({ loadOptions, ...rest }, ref) => {
    const handleLoadOptions = debounce(loadOptions, 500)

    return (
      <MultiSelect
        ref={ref}
        cacheOptions
        isAsync
        isClearable
        isMulti={false}
        isSearchable
        loadOptions={handleLoadOptions}
        noOptionsMessage={() => null}
        placeholder={null}
        {...rest}
        size="md"
      />
    )
  }
)
