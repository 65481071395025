export const SPORTS_OPTIONS = [
  {
    label: "Baseball - Men's",
    value: "Baseball - Men's",
  },
  {
    label: "Basketball - Men's",
    value: "Basketball - Men's",
  },
  {
    label: "Basketball - Women's",
    value: "Basketball - Women's",
  },
  {
    label: "Beach Volleyball - Women's",
    value: "Beach Volleyball - Women's",
  },
  {
    label: "Cross Country - Men's",
    value: "Cross Country - Men's",
  },
  {
    label: "Cross Country - Women's",
    value: "Cross Country - Women's",
  },
  {
    label: "Dance/Sideline Cheer - Women's",
    value: "Dance/Sideline Cheer - Women's",
  },
  {
    label: "Golf - Men's",
    value: "Golf - Men's",
  },
  {
    label: "Golf - Women's",
    value: "Golf - Women's",
  },
  {
    label: "Soccer - Men's",
    value: "Soccer - Men's",
  },
  {
    label: "Soccer - Women's",
    value: "Soccer - Women's",
  },
  {
    label: "Softball - Women's",
    value: "Softball - Women's",
  },
  {
    label: "Stunt - Women's",
    value: "Stunt - Women's",
  },
  {
    label: "Track & Field - Men's",
    value: "Track & Field - Men's",
  },
  {
    label: "Track & Field - Women's",
    value: "Track & Field - Women's",
  },
  {
    label: "Volleyball - Men's",
    value: "Volleyball - Men's",
  },
  {
    label: "Volleyball - Women's",
    value: "Volleyball - Women's",
  },
  {
    label: "Wrestling - Men's",
    value: "Wrestling - Men's",
  },
  {
    label: "Wrestling - Women's",
    value: "Wrestling - Women's",
  },
]
