import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family:"hedley-new-web";
      src:url("https://use.typekit.net/af/4aa859/000000000000000077359c5b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/4aa859/000000000000000077359c5b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/4aa859/000000000000000077359c5b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
      font-display:auto; font-style:normal; font-weight:700; font-stretch:normal;
    }

    @font-face {
      font-family:"hedley-new-web";
      src:url("https://use.typekit.net/af/8f046d/000000000000000077359c70/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/8f046d/000000000000000077359c70/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/8f046d/000000000000000077359c70/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
      font-display:auto; font-style:normal; font-weight:300; font-stretch:normal;
    }

    @font-face {
      font-family:"hedley-new-web";
      src:url("https://use.typekit.net/af/58f06a/000000000000000077359c84/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"), url("https://use.typekit.net/af/58f06a/000000000000000077359c84/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"), url("https://use.typekit.net/af/58f06a/000000000000000077359c84/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
      font-display:auto; font-style:italic; font-weight:300; font-stretch:normal;
    }

    @font-face {
      font-family:"hedley-new-web";
      src:url("https://use.typekit.net/af/df1646/000000000000000077359c7f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/df1646/000000000000000077359c7f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/df1646/000000000000000077359c7f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
      font-display:auto; font-style:normal; font-weight:500; font-stretch:normal;
    }

    @font-face {
      font-family:"hedley-new-web";
      src:url("https://use.typekit.net/af/3ef5bd/000000000000000077359c87/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/3ef5bd/000000000000000077359c87/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/3ef5bd/000000000000000077359c87/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
      font-display:auto; font-style:italic; font-weight:500; font-stretch:normal;
    }

    @font-face {
      font-family:"oswald";
      src:url("https://use.typekit.net/af/d81a8f/00000000000000007735a0e3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/d81a8f/00000000000000007735a0e3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/d81a8f/00000000000000007735a0e3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
      font-display:auto; font-style:normal; font-weight:400; font-stretch:normal;
    }

    @font-face {
      font-family:"oswald";
      src:url("https://use.typekit.net/af/bed39a/00000000000000007735a0f4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/bed39a/00000000000000007735a0f4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/bed39a/00000000000000007735a0f4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
      font-display:auto; font-style:normal; font-weight:500; font-stretch:normal;
    }
    `}
  />
)

export default Fonts
