export const SIENA_MAJORS = [
  {
    label: "Not Sure - Undecided",
    value: "UNDE - Not Sure - Undecided",
  },
  {
    label: "Accounting",
    value: "ACBS - Accounting",
  },
  {
    label: "Accounting (3+1 Bachelor's/Master's)",
    value: "ACBS - Accounting (3+1 Bachelor's/Master's)",
  },
  {
    label: "Actuarial Science",
    value: "ATSC - Actuarial Science",
  },
  {
    label: "American Studies",
    value: "AMST - American Studies",
  },
  {
    label: "Applied Physics - Electrical",
    value: "APEL - Applied Physics - Electrical",
  },
  {
    label: "Applied Physics - Mechanical",
    value: "APML - Applied Physics - Mechanical",
  },
  {
    label: "Applied Physics (Pre-Engineering)",
    value: "APPH - Applied Physics (Pre-Engineering)",
  },
  {
    label: "Art",
    value: "VADS - Art",
  },
  {
    label: "Artificial Intelligence",
    value: "CSAI - Artificial Intelligence",
  },
  {
    label: "Astrophysics",
    value: "APHY - Astrophysics",
  },
  {
    label: "Biochemistry",
    value: "BICM - Biochemistry",
  },
  {
    label: "Biology",
    value: "BIBS - Biology",
  },
  {
    label: "Business",
    value: "BUSI - Business",
  },
  {
    label: "Business Analytics",
    value: "BABS - Business Analytics",
  },
  {
    label: "Chemistry",
    value: "CHEM - Chemistry",
  },
  {
    label: "Chemistry-Business",
    value: "CBBS - Chemistry-Business",
  },
  {
    label: "Childhood Education",
    value: "ECCE - Childhood Education",
  },
  {
    label: "Classics",
    value: "CLAS - Classics",
  },
  {
    label: "Communications",
    value: "COMM - Communications",
  },
  {
    label: "Communications - Journalism",
    value: "CMJR - Communications - Journalism",
  },
  {
    label: "Communications - Marketing Communications & Digital Marketing",
    value:
      "CMMK - Communications - Marketing Communications & Digital Marketing",
  },
  {
    label: "Communications - Political Communications",
    value: "CMPL - Communications - Political Communications",
  },
  {
    label: "Communications - Sports Communications",
    value: "CMSP - Communications - Sports Communications",
  },
  {
    label: "Computational Physics",
    value: "PYCP - Computational Physics",
  },
  {
    label: "Computer Science",
    value: "CSIS - Computer Science",
  },
  {
    label: "Computer Science - Artificial Intelligence",
    value: "CSAI - Computer Science - Artificial Intelligence",
  },
  {
    label: "Computer Science - Cybersecurity",
    value: "CSCY - Computer Science - Cybersecurity",
  },
  {
    label: "Computer Science - Education",
    value: "CSET - Computer Science - Education",
  },
  {
    label: "Computer Science - Engineering 3/2",
    value: "CSEN - Computer Science - Engineering 3/2",
  },
  {
    label: "Computer Science - Entrepreneurship",
    value: "CSEC - Computer Science - Entrepreneurship",
  },
  {
    label: "Computer Science - Game Development",
    value: "CSGD - Computer Science - Game Development",
  },
  {
    label: "Computer Science - Information Systems",
    value: "CSIN - Computer Science - Information Systems",
  },
  {
    label: "Computer Science - Software Development",
    value: "CSSD - Computer Science - Software Development",
  },
  {
    label: "Creative Arts",
    value: "CREA - Creative Arts",
  },
  {
    label: "Criminal Justice",
    value: "CRJS - Criminal Justice",
  },
  {
    label: "Criminal Justice - Forensics",
    value: "CRJF - Criminal Justice - Forensics",
  },
  {
    label: "Criminal Justice - Human Rights",
    value: "CRHR - Criminal Justice - Human Rights",
  },
  {
    label: "Criminal Justice - Policy, Practice and Administration",
    value: "CRJP - Criminal Justice - Policy, Practice and Administration",
  },
  {
    label: "Cyber Security",
    value: "CSCY - Cyber Security",
  },
  {
    label: "Data Science",
    value: "DASC - Data Science",
  },
  {
    label: "Data Science, Applied",
    value: "DSAP - Data Science, Applied",
  },
  {
    label: "Design",
    value: "VADS - Design",
  },
  {
    label: "Early Childhood Education",
    value: "ECCE - Early Childhood Education",
  },
  {
    label: "Economics - BA",
    value: "ECAR - Economics - BA",
  },
  {
    label: "Economics - BS",
    value: "ECBU - Economics - BS",
  },
  {
    label: "Education - American Studies",
    value: "ASED - Education - American Studies",
  },
  {
    label: "Education - Biology",
    value: "BIED - Education - Biology",
  },
  {
    label: "Education - Chemistry",
    value: "CHED - Education - Chemistry",
  },
  {
    label: "Education - Computer Science",
    value: "CSED - Education - Computer Science",
  },
  {
    label: "Education - Early Childhood Education",
    value: "ECCE - Education - Early Childhood Education",
  },
  {
    label: "Education - Elementary Education",
    value: "ECCE - Education - Elementary Education",
  },
  {
    label: "Education - English",
    value: "ENED - Education - English",
  },
  {
    label: "Education - Exploring Business",
    value: "UNBE - Education - Exploring Business",
  },
  {
    label: "Education - Exploring Liberal Arts",
    value: "UNAE - Education - Exploring Liberal Arts",
  },
  {
    label: "Education - Exploring Science",
    value: "UNSE - Education - Exploring Science",
  },
  {
    label: "Education - French",
    value: "FRED - Education - French",
  },
  {
    label: "Education - History",
    value: "HYED - Education - History",
  },
  {
    label: "Education - Mathematics",
    value: "MAED - Education - Mathematics",
  },
  {
    label: "Education - Physics",
    value: "PYED - Education - Physics",
  },
  {
    label: "Education - Spanish",
    value: "SPED - Education - Spanish",
  },
  {
    label: "Elementary Education",
    value: "ECCE - Elementary Education",
  },
  {
    label: "Engineering - 3/2 Program",
    value: "EN32 - Engineering - 3/2 Program",
  },
  {
    label: "Engineering - 4/1 Program",
    value: "EN41 - Engineering - 4/1 Program",
  },
  {
    label: "English",
    value: "ENGL - English",
  },
  {
    label: "Entrepreneurship",
    value: "MGEN - Entrepreneurship",
  },
  {
    label: "Environmental Science",
    value: "ENVS - Environmental Science",
  },
  {
    label: "Environmental Science: Conservation Science Track",
    value: "ENVS - Environmental Science: Conservation Science Track",
  },
  {
    label: "Environmental Science - Earth Systems Track",
    value: "ENVE - Environmental Science - Earth Systems Trackoption>",
  },
  {
    label: "Environmental Studies",
    value: "ENVA - Environmental Studies",
  },
  {
    label: "Exploring Business (Undecided)",
    value: "UNBU - Exploring Business (Undecided)",
  },
  {
    label: "Exploring Liberal Arts (Undecided)",
    value: "UNAR - Exploring Liberal Arts (Undecided)",
  },
  {
    label: "Exploring Science (Undecided)",
    value: "UNSC - Exploring Science (Undecided)",
  },
  {
    label: "Finance",
    value: "FINC - Finance",
  },
  {
    label: "Forensics",
    value: "CRJF - Forensics",
  },
  {
    label: "French",
    value: "FREN - French",
  },
  {
    label: "Game Development",
    value: "CSGD - Game Development",
  },
  {
    label: "Health Studies",
    value: "HLST - Health Studies",
  },
  {
    label: "Health Studies - Administration",
    value: "HSAD - Health Studies - Administration",
  },
  {
    label: "Health Studies - Health Science",
    value: "HSHS - Health Studies - Health Science",
  },
  {
    label: "History",
    value: "HIST - History",
  },
  {
    label: "Human Resources",
    value: "MGHR - Human Resources",
  },
  {
    label: "Information Systems",
    value: "CSIN - Information Systems",
  },
  {
    label: "International Management",
    value: "MGIM - International Management",
  },
  {
    label: "International Relations",
    value: "PSIR - International Relations",
  },
  {
    label: "Leadership",
    value: "MGLD - Leadership",
  },
  {
    label: "Management",
    value: "MGMT - Management",
  },
  {
    label: "Management - Entrepreneurship and Small Business Management Track",
    value:
      "MGEN - Management - Entrepreneurship and Small Business Management Track",
  },
  {
    label: "Management - International Management Track",
    value: "MGIM - Management - International Management Track",
  },
  {
    label: "Management - Leadership Track",
    value: "MGLD - Management - Leadership Track",
  },
  {
    label: "Management - Project Management Track",
    value: "MGPM - Management - Project Management Track",
  },
  {
    label: "Management - Strategic Human Resource Management Track",
    value: "MGHR - Management - Strategic Human Resource Management Track",
  },
  {
    label: "Management - Strategic Management Track",
    value: "MGSM - Management - Strategic Management Track",
  },
  {
    label: "Marketing",
    value: "MRKT - Marketing",
  },
  {
    label: "Mathematics - BA",
    value: "MTBA - Mathematics - BA",
  },
  {
    label: "Mathematics - BS",
    value: "MTBS - Mathematics - BS",
  },
  {
    label: "Mathematics, Applied - BA",
    value: "MACL - Mathematics, Applied - BA",
  },
  {
    label: "Mathematics, Applied - BS",
    value: "MAMA - Mathematics, Applied - BS",
  },
  {
    label: "Mathematics, Applied - Classical BS",
    value: "MTCL - Mathematics, Applied - Classical BS",
  },
  {
    label:
      "Mathematics, Applied - Modeling and Analytics in the Natural Science BS",
    value:
      "MTMA - Mathematics, Applied - Modeling and Analytics in the Natural Science BS",
  },
  {
    label: "Mathematics, Applied - Statistics BA",
    value: "MAST - Mathematics, Applied - Statistics BA",
  },
  {
    label: "Mathematics, Applied - Statistics BS",
    value: "MTST - Mathematics, Applied - Statistics BS",
  },
  {
    label: "Nursing",
    value: "NRMC - Nursing",
  },
  {
    label: "Philosophy",
    value: "PHIL - Philosophy",
  },
  {
    label: "Physics",
    value: "PHYS - Physics",
  },
  {
    label: "Political Science",
    value: "POSC - Political Science",
  },
  {
    label: "Political Science - American Politics & Justice",
    value: "PSAP - Political Science - American Politics & Justice",
  },
  {
    label: "Political Science - International Relations",
    value: "PSIR - Political Science - International Relations",
  },
  {
    label: "Political Science - Public Policy & Administration",
    value: "PSPA - Political Science - Public Policy & Administration",
  },
  {
    label: "Psychology",
    value: "PSYC - Psychology",
  },
  {
    label: "Religious Studies",
    value: "RELG - Religious Studies",
  },
  {
    label: "Social Work",
    value: "SWRK - Social Work",
  },
  {
    label: "Sociology",
    value: "SOCI - Sociology",
  },
  {
    label: "Software Development",
    value: "CSSD - Software Engineering",
  },
  {
    label: "Spanish",
    value: "SPAN - Spanish",
  },
  {
    label: "Statistic",
    value: "MTST - Statistic",
  },
  {
    label: "Theatre",
    value: "THTR - Theatre",
  },
  {
    label: "Theatre Design/Tech",
    value: "THTD - Theatre Design/Tech",
  },
  {
    label: "Theatre Management",
    value: "THMG - Theatre Management",
  },
  {
    label: "Theatre Performance",
    value: "THPF - Theatre Performance",
  },
  {
    label: "Undecided",
    value: "UNDE - Undecided",
  },
  {
    label: "Undecided - Business",
    value: "UNBU - Undecided - Business",
  },
  {
    label: "Undecided - Liberal Arts",
    value: "UNAR - Undecided - Liberal Arts",
  },
  {
    label: "Undecided - Science",
    value: "UNSC - Undecided - Science",
  },
  {
    label: "Visual Art and Design",
    value: "VADS - Visual Art and Design",
  },
]

export const SIENA_MAJORS_HEOP = SIENA_MAJORS.filter(
  major => major.label !== "Nursing",
)
