import { Box, Flex, Text, useStyleConfig } from "@chakra-ui/react"
import { SiteHeader, PageHeader, Main } from "components/LayoutComponents"
import { Nav } from "components/Nav"
import { H1 } from "components/common"
import { SiteLogo } from "components/SiteLogo"
import { useMaxWidth } from "hooks"
import { FullWidthFooter } from "components/FullWidthFooter"
import { LoadingScreen } from "./LoadingScreen"
import { motion } from "framer-motion"
import { PageData } from "types"

/**
 * 1: Full Width, Full Page Scroll
 * ----------------------------------
 * [            Branding            ]
 * [         PageHeader (if shown)        ]
 * [Navigation] [      Content      ]
 * [          ] [                   ]
 * [             SiteFooter             ]
 */

/**
 * Centered: Full Width, Full Page Scroll
 * ----------------------------------
 * [   Branding  ]
 * [   Content   ]
 * [  SiteFooter ]
 */

export const Layout1 = (props: any) => {
  const {
    applicationData,
    children,
    currentPage,
    layout,
    mainRef,
    navRef,
    progressBarHeight,
    showLoadingScreen,
  } = props

  const { isMaxTabletView } = useMaxWidth()

  const isCenteredLayout = layout.version === "centered"
  const isCenterAligned = isMaxTabletView || isCenteredLayout
  const centeredStyles = useStyleConfig("LayoutCentered")

  const baseStyles = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "full",
    // Fix layout nudge caused by scrollbar
    height: "calc(100% + 1px)",
  }

  const styles = isCenteredLayout
    ? { ...baseStyles, ...centeredStyles }
    : baseStyles

  return (
    <Box __css={styles}>
      <SiteHeader>
        <SiteLogo
          alt={applicationData.meta.title}
          src={applicationData.meta.logo}
        />
      </SiteHeader>

      {showLoadingScreen ? (
        <LoadingScreen
          data-testid="layout-1_loading-screen"
          height="full"
          as={motion.div}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.5 }}
        />
      ) : (
        <Flex
          as={isCenteredLayout ? motion.main : motion.div}
          alignItems={isCenterAligned ? null : "flex-start"}
          flexDirection={isCenterAligned ? "column" : "row"}
          maxWidth="6xl"
          width="full"
          paddingX={{ md: 4 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {isCenteredLayout ? (
            children
          ) : (
            <>
              <Nav
                ref={navRef}
                flex={isMaxTabletView ? null : "0 1 27%"}
                pages={applicationData.pages}
              />

              <Main
                ref={mainRef}
                flex="1"
                overflow="auto"
                wrapperProps={{ paddingX: { base: 0, md: 6 } }}
              >
                <ApplicationPage currentPage={currentPage}>
                  {children}
                </ApplicationPage>
              </Main>
            </>
          )}
        </Flex>
      )}
      <FullWidthFooter
        mb={progressBarHeight}
        applicationData={applicationData}
      />
    </Box>
  )
}

interface ApplicationPageProps {
  children: React.ReactNode
  currentPage: PageData
}
const ApplicationPage = ({ children, currentPage }: ApplicationPageProps) => {
  const { description, title } = currentPage
  return (
    <>
      <PageHeader>
        <H1>{title}</H1>
        {description && <Text>{description}</Text>}
      </PageHeader>
      {children}
    </>
  )
}
