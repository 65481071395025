import { createContext, PropsWithChildren } from "react"
import { ChildrenProps } from "./types/context.types"
import { useLocalStorage } from "hooks/"

interface AuthContextState {
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  adb2cToken: string
  setAdb2cToken: React.Dispatch<React.SetStateAction<string>>
  unsetTokens(): void
}

const initialValue: AuthContextState = {
  token: undefined,
  setToken: () => {},
  adb2cToken: undefined,
  setAdb2cToken: () => {},
  unsetTokens: () => {},
}

export const AuthContext = createContext(initialValue)

export const AuthProvider: React.FC<PropsWithChildren<ChildrenProps>> = ({
  children,
}) => {
  const dbToken = initialValue.token

  const [token, setToken] = useLocalStorage("token", dbToken)
  const [adb2cToken, setAdb2cToken] = useLocalStorage("adb2cToken", dbToken)

  const unsetTokens = () => {
    setToken(undefined)
    setAdb2cToken(undefined)
  }

  return (
    <AuthContext.Provider
      value={{ token, setToken, adb2cToken, setAdb2cToken, unsetTokens }}
    >
      {children}
    </AuthContext.Provider>
  )
}
