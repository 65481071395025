export const MAJOR_OPTIONS = [
  {
    label: "Undeclared",
    value: "Undeclared",
  },
  {
    label: "Accounting",
    value: "Accounting",
  },
  {
    label: "Biochemistry",
    value: "Biochemistry",
  },
  {
    label: "Biology",
    value: "Biology",
  },
  {
    label: "Biology: Biomedical Science",
    value: "Biology: Biomedical Science",
  },
  {
    label: "Biology: Ecology",
    value: "Biology: Ecology",
  },
  {
    label: "Biology: Neuroscience",
    value: "Biology: Neuroscience",
  },
  {
    label: "Biotechnology",
    value: "Biotechnology",
  },
  {
    label: "Business Administration",
    value: "Business Administration",
  },
  {
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    label: "Communication: Film/Visual Storytelling",
    value: "Communication: Film/Visual Storytelling",
  },
  {
    label: "Communication: Health Communication",
    value: "Communication: Health Communication",
  },
  {
    label: "Communication: Interpersonal/Intercultural Communication Studies",
    value: "Communication: Interpersonal/Intercultural Communication Studies",
  },
  {
    label: "Communication: Public Relations",
    value: "Communication: Public Relations",
  },
  {
    label: "Communication: Technology & Emerging Media",
    value: "Communication: Technology & Emerging Media",
  },
  {
    label: "Computer Science",
    value: "Computer Science",
  },
  {
    label: "Criminal Justice",
    value: "Criminal Justice",
  },
  {
    label: "Engineering Physics",
    value: "Engineering Physics",
  },
  {
    label: "English",
    value: "English",
  },
  {
    label: "History/Political Science",
    value: "History/Political Science",
  },
  {
    label: "Kinesiology: Health and Human Performance",
    value: "Kinesiology: Health and Human Performance",
  },
  {
    label: "Kinesiology: Pre-Health Professions",
    value: "Kinesiology: Pre-Health Professions",
  },
  {
    label: "Liberal Studies (Education)",
    value: "Liberal Studies (Education)",
  },
  {
    label: "Marketing",
    value: "Marketing",
  },
  {
    label: "Mathematics",
    value: "Mathematics",
  },
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Music: Worship Leadership",
    value: "Music: Worship Leadership",
  },
  {
    label: "Psychology",
    value: "Psychology",
  },
  {
    label: "Sociology",
    value: "Sociology",
  },
  {
    label: "Theatre Arts: Musical Theatre",
    value: "Theatre Arts: Musical Theatre",
  },
  {
    label: "Theatre Arts: Stage & Screen Acting/Directing",
    value: "Theatre Arts: Stage & Screen Acting/Directing",
  },
  {
    label: "Theatre Arts: Technical/Design",
    value: "Theatre Arts: Technical/Design",
  },
  {
    label: "Theology: Biblical Studies",
    value: "Theology: Biblical Studies",
  },
  {
    label: "Theology: Christian Formation/Discipleship Studies",
    value: "Theology: Christian Formation/Discipleship Studies",
  },
  {
    label: "Theology: General Christian Studies",
    value: "Theology: General Christian Studies",
  },
  {
    label: "Theology: Intercultural Studies",
    value: "Theology: Intercultural Studies",
  },
  {
    label: "Theology: Pastoral Leadership Studies",
    value: "Theology: Pastoral Leadership Studies",
  },
  {
    label: "Theology: Youth Leadership Studies",
    value: "Theology: Youth Leadership Studies",
  },
]
