import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  forwardRef,
} from "@chakra-ui/react"
import { Option } from "types"

export type CheckboxProps = Omit<ChakraCheckboxProps, "value"> & Option

export const Checkbox = forwardRef<CheckboxProps, "input">(
  ({ ...rest }, ref) => {
    return <ChakraCheckbox ref={ref} {...rest} />
  }
)
