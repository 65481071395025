import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

class Connection {
  private __axiosInstance__?: AxiosInstance
  private __apiRoot__: string

  constructor(apiRoot) {
    this.__apiRoot__ = apiRoot
  }

  get axiosInstance() {
    if (!this.__axiosInstance__) {
      this.__axiosInstance__ = axios.create({
        baseURL: `${this.__apiRoot__}/api`,
      })
    }

    return this.__axiosInstance__
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.axiosInstance.get(url, config)
  }

  post(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axiosInstance.post(url, data, config)
  }

  put(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axiosInstance.put(url, data, config)
  }

  patch(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axiosInstance.patch(url, data, config)
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.axiosInstance.delete(url, config)
  }
}

export default Connection
