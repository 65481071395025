export const ACTIVITIES = [
  { label: "Community Activities: 4H", value: "Community Activities: 4H" },
  {
    label: "Community Activities: Boy Scouts or Girl Scouts",
    value: "Community Activities: Boy Scouts or Girl Scouts",
  },
  {
    label: "Community Activities: Church or Religious",
    value: "Community Activities: Church or Religious",
  },
  {
    label: "Community Activities: Cultural or Ethnic",
    value: "Community Activities: Cultural or Ethnic",
  },
  {
    label: "Community Activities: Environmental",
    value: "Community Activities: Environmental",
  },
  {
    label: "Community Activities: Internship",
    value: "Community Activities: Internship",
  },
  { label: "Community Activities: Job", value: "Community Activities: Job" },
  {
    label: "Community Activities: Other",
    value: "Community Activities: Other",
  },
  {
    label: "Community Activities: Political",
    value: "Community Activities: Political",
  },
  {
    label: "Community Activities: Service",
    value: "Community Activities: Service",
  },
  {
    label: "Community Activities: Social Conscience",
    value: "Community Activities: Social Conscience",
  },
  { label: "Community Service: Family", value: "Community Service: Family" },
  {
    label: "Honors & Leadership: Academic Award",
    value: "Honors & Leadership: Academic Award",
  },
  {
    label: "Honors & Leadership: Athletics - Academic Award",
    value: "Honors & Leadership: Athletics - Academic Award",
  },
  {
    label: "Honors & Leadership: Athletics - MVP",
    value: "Honors & Leadership: Athletics - MVP",
  },
  {
    label: "Honors & Leadership: Athletics - Team Captain",
    value: "Honors & Leadership: Athletics - Team Captain",
  },
  {
    label: "Honors & Leadership: Boy Scouts - Eagle Scout",
    value: "Honors & Leadership: Boy Scouts - Eagle Scout",
  },
  {
    label: "Honors & Leadership: Boys or Girls State",
    value: "Honors & Leadership: Boys or Girls State",
  },
  {
    label: "Honors & Leadership: Girl Scouts - Gold Award",
    value: "Honors & Leadership: Girl Scouts - Gold Award",
  },
  {
    label: "Honors & Leadership: Honor Roll",
    value: "Honors & Leadership: Honor Roll",
  },
  {
    label: "Honors & Leadership: Leadership Award",
    value: "Honors & Leadership: Leadership Award",
  },
  {
    label: "Honors & Leadership: Leadership Conference",
    value: "Honors & Leadership: Leadership Conference",
  },
  {
    label: "Honors & Leadership: National Honor Society",
    value: "Honors & Leadership: National Honor Society",
  },
  {
    label: "Honors & Leadership: National Merit Commended",
    value: "Honors & Leadership: National Merit Commended",
  },
  {
    label: "Honors & Leadership: National Merit Semi-Finalist",
    value: "Honors & Leadership: National Merit Semi-Finalist",
  },
  { label: "Honors & Leadership: Other", value: "Honors & Leadership: Other" },
  {
    label: "Honors & Leadership: Who's Who",
    value: "Honors & Leadership: Who's Who",
  },
  {
    label: "HS Activities: Academic Club",
    value: "HS Activities: Academic Club",
  },
  { label: "HS Activities: Art", value: "HS Activities: Art" },
  {
    label: "HS Activities: Cheerleading",
    value: "HS Activities: Cheerleading",
  },
  { label: "HS Activities: Color Guard", value: "HS Activities: Color Guard" },
  {
    label: "HS Activities: Computer/Tech",
    value: "HS Activities: Computer/Tech",
  },
  { label: "HS Activities: Dance", value: "HS Activities: Dance" },
  { label: "HS Activities: Dance Team", value: "HS Activities: Dance Team" },
  { label: "HS Activities: DECA", value: "HS Activities: DECA" },
  { label: "HS Activities: Drill Team", value: "HS Activities: Drill Team" },
  { label: "HS Activities: FBLA", value: "HS Activities: FBLA" },
  {
    label: "HS Activities: Foreign Exchange",
    value: "HS Activities: Foreign Exchange",
  },
  { label: "HS Activities: Key Club", value: "HS Activities: Key Club" },
  { label: "HS Activities: LGBT", value: "HS Activities: LGBT" },
  {
    label: "HS Activities: Literary Magazine",
    value: "HS Activities: Literary Magazine",
  },
  { label: "HS Activities: Mock Trial", value: "HS Activities: Mock Trial" },
  { label: "HS Activities: Model UN", value: "HS Activities: Model UN" },
  {
    label: "HS Activities: Music - Band",
    value: "HS Activities: Music - Band",
  },
  {
    label: "HS Activities: Music - Choir",
    value: "HS Activities: Music - Choir",
  },
  {
    label: "HS Activities: Music - Jazz Band",
    value: "HS Activities: Music - Jazz Band",
  },
  {
    label: "HS Activities: Music - Orchestra",
    value: "HS Activities: Music - Orchestra",
  },
  {
    label: "HS Activities: Music - Show Choir",
    value: "HS Activities: Music - Show Choir",
  },
  { label: "HS Activities: Newspaper", value: "HS Activities: Newspaper" },
  { label: "HS Activities: Other", value: "HS Activities: Other" },
  { label: "HS Activities: Pom-Pom", value: "HS Activities: Pom-Pom" },
  { label: "HS Activities: Radio - TV", value: "HS Activities: Radio - TV" },
  { label: "HS Activities: ROTC", value: "HS Activities: ROTC" },
  {
    label: "HS Activities: Speech & Debate",
    value: "HS Activities: Speech & Debate",
  },
  {
    label: "HS Activities: Student Government Officer",
    value: "HS Activities: Student Government Officer",
  },
  {
    label: "HS Activities: Student Government Representative",
    value: "HS Activities: Student Government Representative",
  },
  {
    label: "HS Activities: Study Abroad",
    value: "HS Activities: Study Abroad",
  },
  {
    label: "HS Activities: Theater - Drama",
    value: "HS Activities: Theater - Drama",
  },
  {
    label: "HS Activities: Theater - Musical",
    value: "HS Activities: Theater - Musical",
  },
  { label: "HS Activities: Yearbook", value: "HS Activities: Yearbook" },
  { label: "HS Athletics: Baseball", value: "HS Athletics: Baseball" },
  { label: "HS Athletics: Basketball", value: "HS Athletics: Basketball" },
  { label: "HS Athletics: Crew", value: "HS Athletics: Crew" },
  { label: "HS Athletics: Cross County", value: "HS Athletics: Cross County" },
  { label: "HS Athletics: Football", value: "HS Athletics: Football" },
  { label: "HS Athletics: Golf", value: "HS Athletics: Golf" },
  { label: "HS Athletics: Hockey", value: "HS Athletics: Hockey" },
  { label: "HS Athletics: Lacrosse", value: "HS Athletics: Lacrosse" },
  { label: "HS Athletics: Other", value: "HS Athletics: Other" },
  { label: "HS Athletics: Polo", value: "HS Athletics: Polo" },
  { label: "HS Athletics: Rugby", value: "HS Athletics: Rugby" },
  { label: "HS Athletics: Soccer", value: "HS Athletics: Soccer" },
  { label: "HS Athletics: Softball", value: "HS Athletics: Softball" },
  { label: "HS Athletics: Swimming", value: "HS Athletics: Swimming" },
  { label: "HS Athletics: Tennis", value: "HS Athletics: Tennis" },
  { label: "HS Athletics: Track", value: "HS Athletics: Track" },
  { label: "HS Athletics: Volleyball", value: "HS Athletics: Volleyball" },
]
