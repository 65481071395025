import {
  Select as ChakraReactSelect,
  AsyncSelect as ChakraAsyncSelect,
  CreatableSelect as ChakraCreatableSelect,
  AsyncCreatableSelect as ChakraAsyncCreatableSelect,
  ChakraStylesConfig,
} from "chakra-react-select"
import { forwardRef, useStyleConfig } from "@chakra-ui/react"
import { Option } from "types"
import { FormControl, SharedFormControlProps } from "./FormControl"

/**
 * This component makes use of the third-party `chakra-react-select`:
 * https://www.npmjs.com/package/chakra-react-select
 *
 * ... which wraps a Chakra Select component around `react-select`:
 * https://react-select.com/home
 *
 * Consider replacing with own version that does the same.
 */

export interface MultiSelectOptionProps extends Option {
  /**
   * isFixed prevents an option from being removable once selected
   */
  isFixed?: boolean
  colorScheme?: string
  variant?: string
}

export interface MultiSelectProps extends SharedFormControlProps {
  /**
   * If true, use loadOptions() to return a promise with array of options
   */
  isAsync?: boolean
  /**
   * If true, a user may add a custom entry for an option not found in search
   */
  isCreatable?: boolean
  /**
   * If true, isMulti allows multiple options to be selected
   */
  isMulti?: boolean
  /**
   * If using async, loadOptions of "true" will
   */
  loadOptions?: () => Promise<Option[]>
  /**
   * If not using async, options will populate the dropdown
   */
  options?: readonly Option[]
  /**
   * If using async, defaultOptions will show dropdown values before a user has searched. If set to true, it will fetch the results of loadOptions()
   */
  defaultOptions?: boolean | readonly Option[]
}

export const MultiSelect = forwardRef(
  (
    {
      isAsync,
      isCreatable,
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      onChange,
      value,
      type,
      ...rest
    },
    forwardRef
  ) => {
    const styles = useStyleConfig("MultiSelect")

    const customStyles = {
      sx: {
        cursor: isAsync ? "text" : "pointer",
        "& .chakra-divider": {
          height: "60%",
          borderLeftWidth: "2px",
          borderColor: "#cccccc",
          display: isAsync || isReadOnly ? "none" : "inline",
        },
        "& .chakra-divider + div": {
          display: isAsync ? "none" : "flex",
        },
      },
    }

    const FormControlValues = {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
    }

    const ReactSelectProps = {
      ref: forwardRef,

      onChange: (value: any) => {
        if (value) {
          onChange(value)
        } else {
          onChange("")
        }
      },
      value: value
        ? typeof value === "object"
          ? value
          : { label: value, value }
        : "",
      chakraStyles: styles as ChakraStylesConfig,
      isInvalid,
      isDisabled: isReadOnly,
      ...rest,
    }

    if (!isAsync && !isCreatable) {
      return (
        <FormControl {...FormControlValues} {...customStyles}>
          <ChakraReactSelect {...ReactSelectProps} />
        </FormControl>
      )
    }

    return (
      <FormControl {...FormControlValues} {...customStyles}>
        {isAsync && isCreatable ? (
          <ChakraAsyncCreatableSelect {...ReactSelectProps} />
        ) : isAsync ? (
          <ChakraAsyncSelect {...ReactSelectProps} />
        ) : (
          <ChakraCreatableSelect {...ReactSelectProps} />
        )}
      </FormControl>
    )
  }
)
