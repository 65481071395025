export const SIENA_BUSINESS_CONCENTRATIONS = [
  {
    label: "Accounting Theory And Foundations",
    value: "ATAT - Accounting Theory and Foundations",
  },
  {
    label: "Brand Management",
    value: "BRMG - Brand Management",
  },
  {
    label: "Business Analytics",
    value: "BABA - Business Analytics",
  },
  {
    label: "Corporate Finance",
    value: "CFCF - Corporate Finance",
  },
  {
    label: "Economic Theory And Practice",
    value: "ETPR - Economic Theory And Practice",
  },
  {
    label: "Entrepreneurship",
    value: "ENEN - Entrepreneurship",
  },
  {
    label: "Financial Planning",
    value: "FPFP - Financial Planning",
  },
  {
    label: "Fundamentals of Finance",
    value: "FFFF - Fundamentals of Finance",
  },
  {
    label: "International Business",
    value: "IBIB - International Business",
  },
  {
    label: "Investment Fundamentals",
    value: "IFIF - Investment Fundamentals",
  },
  {
    label: "Leadership",
    value: "LDMG - Leadership",
  },
  {
    label: "Marketing Communications And Digital Marketing",
    value: "MCMC - Marketing Communications And Digital Marketing",
  },
  {
    label: "Marketing Research And Analytics",
    value: "MRMR - Marketing Research And Analytics",
  },
  {
    label: "Not-For-Profit Governmental Accounting",
    value: "NPGA - Not-For-Profit Governmental Accounting",
  },
  {
    label: "Project Management",
    value: "PRMG - Project Management",
  },
  {
    label: "Public Policy And Economics",
    value: "PPEC - Public Policy And Economics",
  },
  {
    label: "Sales And Business To Business Marketing",
    value: "BBBB - Sales And Business To Business Marketing",
  },
  {
    label: "Sports Marketing",
    value: "SPMK - Sports Marketing",
  },
  {
    label: "Strategic Decision Making",
    value: "STDM - Strategic Decision Making",
  },
  {
    label: "Strategic Human Resource Development",
    value: "SHRD - Strategic Human Resource Development",
  },
]
