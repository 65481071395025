export const DENOMINATION_OPTIONS = [
  {
    label: "Assemblies of God",
    value: "Assemblies of God",
  },
  {
    label: "Baptist",
    value: "Baptist",
  },
  {
    label: "Bible Church",
    value: "Bible Church",
  },
  {
    label: "Calvary Chapel",
    value: "Calvary Chapel",
  },
  {
    label: "Catholic",
    value: "Catholic",
  },
  {
    label: "Charismatic",
    value: "Charismatic",
  },
  {
    label: "Christian",
    value: "Christian",
  },
  {
    label: "Church of Christ",
    value: "Church of Christ",
  },
  {
    label: "Church of God",
    value: "Church of God",
  },
  {
    label: "Covenant",
    value: "Covenant",
  },
  {
    label: "Episcopal",
    value: "Episcopal",
  },
  {
    label: "Evangelical",
    value: "Evangelical",
  },
  {
    label: "Evangelical Free",
    value: "Evangelical Free",
  },
  {
    label: "Four Square",
    value: "Four Square",
  },
  {
    label: "Friends",
    value: "Friends",
  },
  {
    label: "Lutheran",
    value: "Lutheran",
  },
  {
    label: "Methodist",
    value: "Methodist",
  },
  {
    label: "Nazarene",
    value: "Nazarene",
  },
  {
    label: "Non-Denominational",
    value: "Non-Denominational",
  },
  {
    label: "Pentecostal",
    value: "Pentecostal",
  },
  {
    label: "Presbyterian",
    value: "Presbyterian",
  },
  {
    label: "Vineyard",
    value: "Vineyard",
  },
  {
    label: "None",
    value: "None",
  },
  {
    label: "Other",
    value: "Other",
  },
]
