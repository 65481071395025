import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      @font-face {
      font-family: "linux-libertine";
      src: url("/fonts/linux-libertine-bold.woff2") format("woff2"),
           url("/fonts/linux-libertine-bold.woff") format("woff"),
           url("https://apollolandingpagesprod.blob.core.windows.net/$web/hollins/webfonts/LinuxLibertineBold/font.woff2") format("woff2"),
           url("https://apollolandingpagesprod.blob.core.windows.net/$web/hollins/webfonts/LinuxLibertineBold/font.woff") format("woff"),
      font-display: auto;
      font-style: normal;
      font-weight: 700;
      font-stretch: normal;
    }
    @font-face {
      font-family: "open-sans";   
      font-display: auto;
      font-style: normal;
      font-weight: 700;
      font-stretch: normal;   
      src: url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    }
    @font-face {
      font-family: "open-sans";  
      font-display: auto;
      font-style: italic;
      font-weight: 700;
      font-stretch: normal;    
      src: url("https://use.typekit.net/af/cb3467/00000000000000007735a069/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/cb3467/00000000000000007735a069/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/cb3467/00000000000000007735a069/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    }
    @font-face {
      font-family: "open-sans";  
      font-display: auto;
      font-style: italic;
      font-weight: 400;
      font-stretch: normal;    
      src: url("https://use.typekit.net/af/d4e28f/00000000000000007735a072/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/d4e28f/00000000000000007735a072/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/d4e28f/00000000000000007735a072/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    }
    @font-face {
      font-family: "open-sans";    
      font-display: auto;
      font-style: normal;
      font-weight: 400;
      font-stretch: normal;  
      src: url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    }
    @font-face {
      font-family: "open-sans";
      font-display: auto;
      font-style: normal;
      font-weight: 600;
      font-stretch: normal;
      src: url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    }
    @font-face {
      font-family: "venn";
      font-display: auto;
      font-style: normal;
      font-weight: 700;
      font-stretch: normal;
      src: url("https://use.typekit.net/af/600731/00000000000000007735f77b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/600731/00000000000000007735f77b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/600731/00000000000000007735f77b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    }
    @font-face {
      font-family: "venn";
      font-display: auto;
      font-style: normal;
      font-weight: 300;
      font-stretch: normal;
      src: url("https://use.typekit.net/af/432713/00000000000000007735f787/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/432713/00000000000000007735f787/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/432713/00000000000000007735f787/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    }
    @font-face {
      font-family: "venn";
      font-display: auto;
      font-style: normal;
      font-weight: 400;
      font-stretch: normal;
      src: url("https://use.typekit.net/af/35dd1c/00000000000000007735f77e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/35dd1c/00000000000000007735f77e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/35dd1c/00000000000000007735f77e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    }
    `}
  />
)

export default Fonts
