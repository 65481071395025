import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * Usage:
 *
 * <Router>
 *  <ScrollToTop>
 *    <Routes>
 *      <Route path="/" element={Home} />
 *    </Routes>
 *  </ScrollToTop>
 * </Router>
 */

export const ScrollToTop = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{children}</>
}
