import { Box, BoxProps } from "@chakra-ui/react"
import { isValidHTMLString } from "utilities/formatters"

export interface StringToHTMLParserProps extends BoxProps {
  string: string
}

/** 
 * This component is used to convert a JSON string with HTML tags into HTML. 
 * For example, adding text emphasis and a link to a field label:
  
  field: {
    name: "Sample Field",
    label: "<p>Enter your <strong>email address</strong> and <strong>password</strong> below to fill out your application. <a class="https://www.helpful-link.com">More info</a>.</p>,"
  }
*/

export const StringToHTMLParser = ({
  string,
  ...rest
}: StringToHTMLParserProps) => {
  if (string && isValidHTMLString(string)) {
    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: decodeURIComponent(string),
        }}
        {...rest}
      />
    )
  }

  return <Box {...rest}>{string}</Box>
}
