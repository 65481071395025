import { colors } from "themes/vanguard/colors"

const blackAlpha = (alpha: number) => `rgba(0, 0, 0, ${alpha})`

export const shadows = {
  // lg: `0 2px 8px 0 ${blackAlpha(25)}`,
  buttonActive: `2px 2px 4px 0 ${blackAlpha(0.25)})`,
  buttonFocus: `0px 0px 4px ${colors.buttonActiveColor}`,
  buttonHover: `1px 1px 6px 0 ${blackAlpha(0.6)}`,
  buttonRest: `0 2px 8px 0 ${blackAlpha(0.25)}`,
  // card: `0 2px 8px 0 ${blackAlpha(25)}`,
  // inputFocused: `1px 2px 6px 0 ${blackAlpha(45)}`,
  inputFocusedError: `0 0 6px 0 ${colors.error}`,
  radioHeavy: `0 0 4px 0 ${colors.primaryColor}`,
  // radioLight: `1px 2px 4px 0 ${blackAlpha(25)}`,
  checkboxHeavy: `0 0 4px 0 ${colors.primaryColor}`,
  // checkboxLight: `1px 2px 6px 0 ${blackAlpha(25)}`,
  // siteFooter: `0px -4px 4px ${blackAlpha(15)}`,
  // siteHeader: `0 4px 4px 0 ${blackAlpha(25)}`,
}
