import { Tag, TagProps, TagLabel } from "@chakra-ui/react"
import { ErrorIcon, ErrorAlertIcon } from "components/ErrorIcon"
import { SuccessIcon } from "components/SuccessIcon"

export type Status = "ERROR" | "SUCCESS"

interface StatusBadgeProps extends TagProps {
  errorCount?: number
  size?: "sm" | "md" | "lg"
  status: Status
}

export const StatusBadge = ({
  errorCount,
  size = "md",
  status,
  ...rest
}: StatusBadgeProps) => {
  const hasErrors = status === "ERROR"
  const hasErrorCount = !!errorCount
  const iconProps = { boxSize: 4 }

  const minSize =
    size === "sm" ? "13px" : size === "md" ? 6 : size === "lg" && 8

  return (
    <Tag
      backgroundColor={hasErrors ? "error" : "success"}
      borderRadius="full"
      boxShadow="01"
      color="white"
      fontFamily="body"
      fontSize="xs"
      fontWeight="bold"
      justifyContent={hasErrorCount ? null : "center"}
      letterSpacing="wide"
      lineHeight="1.33"
      minHeight={minSize}
      minWidth={minSize}
      paddingX={hasErrorCount ? 2 : 0}
      size={size}
      {...rest}
    >
      {hasErrors ? (
        hasErrorCount ? (
          <>
            <ErrorIcon {...iconProps} />
            <TagLabel marginLeft="5px">
              {errorCount} {errorCount !== 1 ? "errors" : "error"}
            </TagLabel>
          </>
        ) : (
          <ErrorAlertIcon {...iconProps} />
        )
      ) : (
        <SuccessIcon {...iconProps} />
      )}
    </Tag>
  )
}
