import { ThemeOption } from "themes"
import { ApplicationData } from "types"
import ApiRoot from "./apiRoot"

export class ClientApi extends ApiRoot {
  static find({ cid }: { cid: string }): Promise<Client> {
    return this.connection.get(`/clients/${cid}`).then(response => {
      return response.data
    })
  }
}

export interface Client {
  cid: string
  data: ApplicationData
  redirectUrl: string
  adb2cUrl: string
  theme: ThemeOption
}
