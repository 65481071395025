import React from "react"
import { MdMenu, MdClose } from "react-icons/md"
import {
  IconButton,
  IconButtonProps,
  UseDisclosureProps,
  forwardRef,
} from "@chakra-ui/react"

export interface MobileMenuButtonProps
  extends IconButtonProps,
    UseDisclosureProps {
  /**
   * Use with useDisclosure() from "@chakra-ui/react"
   */
  isMenuOpen: boolean
  onToggleMenu?(): void
}
export const MobileMenuButton = forwardRef<MobileMenuButtonProps, "button">(
  ({ icon, isMenuOpen, ...rest }, ref) => {
    return (
      <IconButton
        ref={ref}
        icon={icon || (isMenuOpen ? <MdClose /> : <MdMenu />)}
        {...rest}
      />
    )
  }
)
