const shadow = (alpha: number) => `rgba(0, 0, 0, ${alpha})`

const a12 = 0.12
const a14 = 0.14
const a20 = 0.2
const a25 = 0.25

const s1 = shadow(a14)
const s2 = shadow(a12)
const s3 = shadow(a20)
const s4 = shadow(a25)

export const shadows = {
  // Default Chakra shadows available to all themes
  // ------------------------------------------------
  // xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
  // sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  // base: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  // md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  // lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  // xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  // "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  // outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
  // inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  // none: "none",
  // "dark-lg":
  //   "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px",

  // Custom Shadows available to all child themes
  // ------------------------------------------------
  // Search bar, card, switch
  "01": `0 1px 1px ${s1}, 0 2px 1px ${s2}, 0 1px 3px ${s3}`,

  // Inputs with inner shadows
  "01-inverted": `inset 0 1px 1px ${s1}, 0 2px 1px ${s2}, 0 1px 3px ${s3}`,

  // Buttons
  "02": `0 2px 2px ${s1}, 0 3px 1px ${s2}, 0 1px 5px ${s3}`,

  // Button hovered
  "02-light": `
    0 2px 2px ${shadow(a14 * 0.75)}, 
    0 3px 1px ${shadow(a12 * 0.75)}, 
    0 1px 5px ${shadow(a20 * 0.75)}`,

  // Refresh indicator, scrolled state of search bar
  "03": `0 3px 4px ${s1}, 0 3px 4px ${s2}, 0 1px 8px ${s3}`,

  // Scrolled state of top bar, options default state of top bar
  "04": `0 4px 5px ${s1}, 0 1px 10px ${s2}, 0 2px 4px ${s3}`,

  // FAB, snackbar, message banners
  "06": `0 6px 10px ${s1}, 0 1px 18px ${s2}, 0 3px 5px ${s3}`,

  // Button pressed state, standard sheets, bottom bars, menus, lifted cards
  "08": `0 8px 10px ${s1}, 0 3px 14px ${s2}, 0 5px 5px ${s3}`,

  // Pressed state for FAB
  "12": `0 12px 17px ${s1}, 0 5px 22px ${s2}, 0 7px 8px ${s3}`,

  // Modal sheets and nav drawers
  "16": `0 16px 24px ${s1}, 0 6px 30px ${s2}, 0 8px 10px ${s3}`,

  // Dialog boxes
  "24": `0 24px 38px ${s1}, 0 9px 46px ${s2}, 0 11px 15px ${s3}`,

  // Page section wrapper
  default: `0 1px 4px 0 ${s4}`,

  // Progress bar
  lg: `0 2px 8px 0 ${s4}`,

  // Focused inputs
  inputFocused: `0 0 0 1px #3182CE`, // blue.500 in Chakra theme

  // Error inputs
  error: `0 0 0 1px #e53e3e`, // red.500 in Chakra theme
}
