import { useContext, useEffect } from "react"
import { UserContext } from "context/UserContext"
import { API_ROOT_HEADER, CID_HEADER, IS_PRODUCTION } from "utilities/constants"
import { getHeaderFromHeaders } from "utilities/helpers"
import ApiRoot from "api/apiRoot"

interface CIDWrapperProps {
  children: React.ReactNode
  localCID: string
}

const CIDWrapper = ({ children, localCID }: CIDWrapperProps) => {
  const { cid, setCid } = useContext(UserContext)

  useEffect(
    function setCid_and_instantiateApi() {
      console.log("ENVIRONMENT:", process.env.REACT_APP_ENVIRONMENT)

      if (IS_PRODUCTION) {
        console.log("Requesting CDN headers")
        let req = new XMLHttpRequest()
        req.open("GET", window.location.href, false)
        req.send(null)

        let headers = req.getAllResponseHeaders().toLowerCase()
        if (headers) {
          const cid_header = getHeaderFromHeaders(headers, CID_HEADER)
          const api_root = getHeaderFromHeaders(headers, API_ROOT_HEADER)
          console.log("Using CID:", cid_header)
          console.log("Using API_ROOT:", api_root)
          setCid(cid_header)
          ApiRoot.connect(api_root)
        }
      } else {
        // NOT_PRODUCTION
        console.log("Using Local CID:", localCID)
        console.log("Using BASE_API:", process.env.REACT_APP_API_BASE)
        ApiRoot.connect(process.env.REACT_APP_API_BASE)
        setCid(localCID)
      }
    },
    [setCid, localCID]
  )

  if (!cid) {
    console.log("CID not found, showing loader")
    return <></>
  }

  console.log(`CID loaded: ${cid}`)
  return <>{children}</>
}

export default CIDWrapper
