export const LANGUAGES = [
  { label: "English", value: "English" },
  { label: "Spanish", value: "Spanish" },
  { label: "Aceh", value: "Aceh" },
  { label: "Afrikaans", value: "Afrikaans" },
  { label: "Akan", value: "Akan" },
  { label: "Albanian", value: "Albanian" },
  { label: "American Sign Language", value: "American Sign Language" },
  { label: "Amharic", value: "Amharic" },
  { label: "Arabic", value: "Arabic" },
  { label: "Armenian", value: "Armenian" },
  { label: "Assamese", value: "Assamese" },
  { label: "Awadhi", value: "Awadhi" },
  { label: "Azerbaijani", value: "Azerbaijani" },
  { label: "Bagheli", value: "Bagheli" },
  { label: "Bali", value: "Bali" },
  { label: "Baluchi", value: "Baluchi" },
  { label: "Banjar", value: "Banjar" },
  { label: "Bavarian", value: "Bavarian" },
  { label: "Belarusan", value: "Belarusan" },
  { label: "Bemba", value: "Bemba" },
  { label: "Bengali", value: "Bengali" },
  { label: "Bhojpuri", value: "Bhojpuri" },
  { label: "Bikol", value: "Bikol" },
  { label: "Bosnian", value: "Bosnian" },
  { label: "Bugis", value: "Bugis" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Burmese", value: "Burmese" },
  { label: "Cantonese", value: "Cantonese" },
  { label: "Catalan-Valencian-Balear", value: "Catalan-Valencian-Balear" },
  { label: "Cebuano", value: "Cebuano" },
  { label: "Chhattisgarhi", value: "Chhattisgarhi" },
  { label: "Chittagonian", value: "Chittagonian" },
  { label: "Creole", value: "Creole" },
  { label: "Croatian", value: "Croatian" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Deccan", value: "Deccan" },
  { label: "Dholuo", value: "Dholuo" },
  { label: "Dogri", value: "Dogri" },
  { label: "Domari", value: "Domari" },
  { label: "Dutch", value: "Dutch" },
  { label: "Estonian", value: "Estonian" },
  { label: "Éwé", value: "Éwé" },
  { label: "Filipino", value: "Filipino" },
  { label: "Finnish", value: "Finnish" },
  { label: "French", value: "French" },
  { label: "Fulah", value: "Fulah" },
  { label: "Galician", value: "Galician" },
  { label: "Ganda", value: "Ganda" },
  { label: "Georgian", value: "Georgian" },
  { label: "German, Standard", value: "German, Standard" },
  { label: "German, Swiss", value: "German, Swiss" },
  { label: "Gikuvu", value: "Gikuvu" },
  { label: "Gilaki", value: "Gilaki" },
  { label: "Godwari", value: "Godwari" },
  { label: "Greek", value: "Greek" },
  { label: "Guarani", value: "Guarani" },
  { label: "Haitian Creole", value: "Haitian Creole" },
  { label: "Haryanvi", value: "Haryanvi" },
  { label: "Hassaniyya", value: "Hassaniyya" },
  { label: "Hausa", value: "Hausa" },
  { label: "Hawaiian", value: "Hawaiian" },
  { label: "Hebrew", value: "Hebrew" },
  { label: "Hiligaynon", value: "Hiligaynon" },
  { label: "Hindi", value: "Hindi" },
  { label: "Hmong", value: "Hmong" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Hunsrik", value: "Hunsrik" },
  { label: "Igbo", value: "Igbo" },
  { label: "ilocano", value: "ilocano" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Italian", value: "Italian" },
  { label: "Jamaican Creole English", value: "Jamaican Creole English" },
  { label: "Japanese", value: "Japanese" },
  { label: "Javanese", value: "Javanese" },
  { label: "Kabyle", value: "Kabyle" },
  { label: "Kamba", value: "Kamba" },
  { label: "Kanauji", value: "Kanauji" },
  { label: "Kannada", value: "Kannada" },
  { label: "Kanuri", value: "Kanuri" },
  { label: "Kashmiri", value: "Kashmiri" },
  { label: "Kazakh", value: "Kazakh" },
  { label: "Khmer, Central", value: "Khmer, Central" },
  { label: "Kimbundu", value: "Kimbundu" },
  { label: "Kirundi", value: "Kirundi" },
  { label: "Kituba", value: "Kituba" },
  { label: "Kongo", value: "Kongo" },
  { label: "Konkani", value: "Konkani" },
  { label: "Korean", value: "Korean" },
  { label: "Kurdish", value: "Kurdish" },
  { label: "Kyrgyz", value: "Kyrgyz" },
  { label: "Lahnda", value: "Lahnda" },
  { label: "Lambadi", value: "Lambadi" },
  { label: "Lao", value: "Lao" },
  { label: "Latvian", value: "Latvian" },
  { label: "Lingala", value: "Lingala" },
  { label: "Lithuanian", value: "Lithuanian" },
  { label: "Lombard", value: "Lombard" },
  { label: "Luba-Kasai", value: "Luba-Kasai" },
  { label: "Macedonian", value: "Macedonian" },
  { label: "Madura", value: "Madura" },
  { label: "Magahi", value: "Magahi" },
  { label: "Mainfränkisch", value: "Mainfränkisch" },
  { label: "Maithili", value: "Maithili" },
  { label: "Makhuwa", value: "Makhuwa" },
  { label: "Malagasy", value: "Malagasy" },
  { label: "Malay", value: "Malay" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Mandarin", value: "Mandarin" },
  { label: "Mandingo", value: "Mandingo" },
  { label: "Marathi", value: "Marathi" },
  { label: "Marwari", value: "Marwari" },
  { label: "Mazanderani", value: "Mazanderani" },
  { label: "Mewati", value: "Mewati" },
  { label: "Mina", value: "Mina" },
  { label: "Minangkabau", value: "Minangkabau" },
  { label: "Mongolian", value: "Mongolian" },
  { label: "Mòoré", value: "Mòoré" },
  { label: "Musi", value: "Musi" },
  { label: "Napoletano-Calabrese", value: "Napoletano-Calabrese" },
  { label: "Nepali", value: "Nepali" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Nyanja", value: "Nyanja" },
  { label: "Oriva", value: "Oriva" },
  { label: "Oromo", value: "Oromo" },
  { label: "Other", value: "Other" },
  { label: "Persian, Farsi", value: "Persian, Farsi" },
  { label: "Piemontese", value: "Piemontese" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Pushto", value: "Pushto" },
  { label: "Quechua", value: "Quechua" },
  { label: "Rajasthani", value: "Rajasthani" },
  { label: "Rangpuri", value: "Rangpuri" },
  { label: "Romanian", value: "Romanian" },
  { label: "Rundi", value: "Rundi" },
  { label: "Russian", value: "Russian" },
  { label: "Rwanda, Kinyarwanda", value: "Rwanda, Kinyarwanda" },
  { label: "Santali", value: "Santali" },
  { label: "Serbian", value: "Serbian" },
  { label: "Shan", value: "Shan" },
  { label: "Shona", value: "Shona" },
  { label: "Sicilian", value: "Sicilian" },
  { label: "Sindhi", value: "Sindhi" },
  { label: "Sinhala", value: "Sinhala" },
  { label: "Slovak", value: "Slovak" },
  { label: "Slovene", value: "Slovene" },
  { label: "Somali", value: "Somali" },
  { label: "Sotho, Northern", value: "Sotho, Northern" },
  { label: "Sotho, Southern", value: "Sotho, Southern" },
  { label: "Sukuma", value: "Sukuma" },
  { label: "Sunda", value: "Sunda" },
  { label: "Swahili", value: "Swahili" },
  { label: "Swedish", value: "Swedish" },
  { label: "Sylheti", value: "Sylheti" },
  { label: "Tachelhit", value: "Tachelhit" },
  { label: "Tagalog", value: "Tagalog" },
  { label: "Tajiki", value: "Tajiki" },
  { label: "Tamazight, Central Atlas", value: "Tamazight, Central Atlas" },
  { label: "Tamil", value: "Tamil" },
  { label: "Tatar", value: "Tatar" },
  { label: "Telugu", value: "Telugu" },
  { label: "Thai", value: "Thai" },
  { label: "Thai, Northeastern", value: "Thai, Northeastern" },
  { label: "Thai, Northern", value: "Thai, Northern" },
  { label: "Thai, Southern", value: "Thai, Southern" },
  { label: "Tigrigna", value: "Tigrigna" },
  { label: "Tsonga", value: "Tsonga" },
  { label: "Tswana", value: "Tswana" },
  { label: "Turkish", value: "Turkish" },
  { label: "Turkmen", value: "Turkmen" },
  { label: "Twi", value: "Twi" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Umbundu", value: "Umbundu" },
  { label: "Urdu", value: "Urdu" },
  { label: "Uyghur", value: "Uyghur" },
  { label: "Uzbek", value: "Uzbek" },
  { label: "Varhadi-Nagpuri", value: "Varhadi-Nagpuri" },
  { label: "Venetian", value: "Venetian" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Vlaams", value: "Vlaams" },
  { label: "Wolof", value: "Wolof" },
  { label: "Xhosa", value: "Xhosa" },
  { label: "Yiddish", value: "Yiddish" },
  { label: "Yoruba", value: "Yoruba" },
  { label: "Zhuang", value: "Zhuang" },
  { label: "Zulu", value: "Zulu" },
]
