import React from "react"
import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  forwardRef,
} from "@chakra-ui/react"
import { FormControl, SharedFormControlProps } from "./FormControl"

export interface TextareaProps
  extends ChakraTextareaProps,
    SharedFormControlProps {}

export const Textarea = forwardRef<TextareaProps, "textarea">(
  (
    {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      ...rest
    },
    ref
  ) => {
    const FormControlValues = {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
    }
    return (
      <FormControl {...FormControlValues}>
        <ChakraTextarea ref={ref} {...rest} />
      </FormControl>
    )
  }
)
