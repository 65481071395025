import { useContext } from "react"
import { forwardRef } from "@chakra-ui/react"
import FormContext from "context/FormContext"
import { filterOptionsBySearchTerm, Searchbox } from "components/Searchbox"
import { CollegeSearchResponse, CustomSearch } from "api/customSearch"
import { titleize } from "utilities/formatters"
import { useLocalStorage } from "hooks/"

// I think this should be defined here since this is the only place I think it will be used
// But I could see removing this and defining the object explicitly on the
// CollegeSearchProps in customSearch.ts
export interface CollegeData {
  CollegeName: string
  City: string
  State: string
  CollegeCode: string
}

export const CollegeSearch = forwardRef<any, "div">((props, ref) => {
  const { name, ...rest } = props
  const {
    form: { getValues },
  } = useContext(FormContext)

  const token = useLocalStorage("token", undefined)
  const formValues = getValues()

  const searchColleges = (
    query
  ): Promise<{ label: string; value: string }[]> => {
    const mapResponseToOptions = (response: CollegeSearchResponse) => {
      return response.data.map(
        ({
          CollegeName: name,
          City: city,
          State: state,
          CollegeCode: code,
        }) => {
          const schoolName = titleize(name.trim())
          let schoolLocation = `${titleize(city || "")}`
          if (state) {
            schoolLocation += `, ${state}`
          }
          return {
            label: `${schoolName} ${schoolLocation}`,
            value: `${schoolName} ${schoolLocation}`,
            code,
          }
        }
      )
    }

    const schoolNotFound: { label: string; value: string } = {
      label: "I don't see my school",
      value: "I don't see my school",
    }

    return CustomSearch.collegeSearch(query, token).then(res => {
      if (!res) {
        return []
      }
      if (res.status !== 200) {
        return []
      }
      const options = mapResponseToOptions(res)
      const filteredOptions = filterOptionsBySearchTerm(options, query)

      return filteredOptions.concat(schoolNotFound)
    })
  }

  return (
    <Searchbox
      ref={ref}
      name={props.name}
      defaultValue={formValues[name]}
      loadOptions={searchColleges}
      {...rest}
    />
  )
})
