// FieldDataParser Dev
import { useContext, useEffect, useMemo, useState } from "react"
import FormContext from "context/FormContext"
import { Box, BoxProps } from "@chakra-ui/react"
import { FieldAttributes } from "types"
import { InputSwitch } from "components/InputSwitch"
import {
  determineIsRequired,
  determineShouldPrefill,
  determineShowField,
  getConditionalFieldsToWatch,
} from "utilities/conditions"
import { useWatch } from "react-hook-form"

interface FieldDataParserProps extends BoxProps {
  fieldData: FieldAttributes
  nestedIndex?: number
  isRequiredErrors?: boolean
  controlSectionName?: string
  isFinalCheck?: boolean
}

/**
 * A component for generating a field given the field data in JSON format
 *
 * @param fieldData The JSON representing the field information
 * @returns The component representation of the field data
 */
export const FieldDataParser = ({
  fieldData,
  nestedIndex,
  controlSectionName,
  isRequiredErrors,
  width,
  isFinalCheck,
  ...rest
}: FieldDataParserProps) => {
  const {
    form: {
      control,
      formState: { errors },
      setValue,
      getValues,
    },
  } = useContext(FormContext)

  const [showField, setShowField] = useState<boolean>(true)
  const [isRequired, setIsRequired] = useState<boolean>(false)
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>()
  const [hasPrefilled, setHasPrefilled] = useState<boolean>(false)
  const [label, setLabel] = useState<string>(fieldData.label)

  const fieldError = errors[fieldData.name]

  // Watch your field data, and the fields in your conditions
  const relevantFieldNames: string[] = useMemo(() => {
    return getConditionalFieldsToWatch(fieldData, fieldData.name, nestedIndex)
  }, [fieldData, nestedIndex])

  const relevantFieldValues = useWatch({ control, name: relevantFieldNames })

  useEffect(() => {
    if (nestedIndex !== undefined) {
      setLabel(fieldData.label?.replaceAll("{{x}}", `${nestedIndex + 1}`))
    }
  }, [fieldData.label, nestedIndex])

  useEffect(() => {
    if (!!errors[fieldData.name]) {
      setIsInvalid(true)
      setErrorText(String(errors[fieldData.name].message))
    } else {
      setIsInvalid(false)
      setErrorText(undefined)
    }
    // eslint-disable-next-line
  }, [fieldError])

  useEffect(() => {
    const relevantFields = {}
    let allValues = getValues()

    if (controlSectionName) {
      allValues = allValues[controlSectionName][nestedIndex]
    }

    !!allValues &&
      Object.keys(allValues).forEach(value => {
        if (relevantFieldNames.includes(value)) {
          relevantFields[value] = allValues[value]
        }
      })
    // When form values relevant to this field change, we must recalculate the following:
    // is the field shown
    // is the field required
    // is the field prefilled with a value

    if (relevantFieldNames.length > 0) {
      const isShown = determineShowField(fieldData, relevantFields, nestedIndex)
      setShowField(isShown)
      setIsRequired(
        isShown && determineIsRequired(fieldData, relevantFields, nestedIndex)
      )

      if (
        determineShouldPrefill(
          fieldData,
          hasPrefilled,
          relevantFields,
          nestedIndex
        )
      ) {
        setHasPrefilled(true)
        // TODO: Continue work on allowing multiple prefill values where another
        // prefill in the same field will override an existing prefill.
        // Currently it causes an infinite loop because fieldData changes with every update.

        // const newValue = fieldData.prefill.find(
        //   p => p.value !== relevantFields[fieldData.name]
        // ).value

        setValue(fieldData.name, fieldData.prefill[0].value)
      }
    } // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    getValues(),
    fieldData,
    relevantFieldNames,
    relevantFieldValues,
    nestedIndex,
  ])

  useEffect(() => {
    const elements = document.querySelectorAll(".combined-fields-wrapper")

    elements.forEach((element: any) => {
      if (!element.innerHTML) {
        element.style.display = "none"
      } else {
        element.style.display = "flex"
      }
    })
  }, [showField])

  if (!showField) {
    return null
  }

  let parsedFieldData = fieldData

  if (nestedIndex !== undefined) {
    parsedFieldData.label = fieldData.label?.replaceAll(
      "{{x}}",
      `${nestedIndex + 1}`
    )
  }

  return (
    <Box
      w={{ base: "100%", tab: "auto" }}
      flex={{ base: "1 1 0", tab: `1 1 ${width}` }}
      {...rest}
    >
      <InputSwitch
        control={control}
        fieldData={parsedFieldData}
        isRequired={isRequired}
        isInvalid={isInvalid}
        errorText={errorText}
        name={
          !!controlSectionName
            ? `${controlSectionName}[${nestedIndex}].${fieldData.name}`
            : fieldData.name
        }
        nestedIndex={nestedIndex}
        label={label}
        isRequiredErrors={isRequiredErrors}
        isFinalCheck={isFinalCheck}
      />
    </Box>
  )
}
