import React, { createContext, useState, PropsWithChildren } from "react"
import { ChildrenProps } from "./types/context.types"
import { User } from "types/user"
import { FullStory } from "@fullstory/browser"

interface UserContextState {
  user: User
  cid: string
  setUser: React.Dispatch<React.SetStateAction<User>>
  setCid: React.Dispatch<React.SetStateAction<string>>
}

const initialValue: UserContextState = {
  user: undefined,
  cid: undefined,
  setUser: () => {},
  setCid: () => {},
}

export const UserContext = createContext(initialValue)

export const UserProvider: React.FC<PropsWithChildren<ChildrenProps>> = ({
  children,
}) => {
  const [user, setUser] = useState<User>(initialValue.user)
  const [cid, setCid] = useState<string>(initialValue.cid)

  if (user?.email) {
    FullStory.identify(user.userId, { email: user.email })
  }

  return (
    <UserContext.Provider value={{ user, cid, setUser, setCid }}>
      {children}
    </UserContext.Provider>
  )
}
