import { uniq, isEmpty } from "lodash"
import {
  ApplicationData,
  FieldMetaData,
  FieldAttributes,
  SectionData,
} from "types"
import { IGNORE_PREFIX } from "utilities/constants"
import { determineIsRequired, determineShowField } from "./conditions"
import { toLowercaseString } from "./formatters"
import {
  COLLEGE_NESTED_FIELDS,
  COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT,
} from "data"

export function getAllFields(appData: ApplicationData) {
  return appData.pages.flatMap(page =>
    page.sections.flatMap(section => section.fields),
  )
}

export const getAllFieldsOnCard = (section: SectionData): string[] => {
  let cardFields: string[] = []

  if (isNestedSection(section)) {
    if (section.controlSectionName) {
      cardFields = [...cardFields, section.controlSectionName]
    } else {
      const fieldNames = section.fields.map(field => {
        return field.name
      })
      cardFields = [...cardFields, ...fieldNames]
    }
  } else {
    cardFields = section.fields.map(field => field.name)
  }

  return uniq(cardFields)
}

export const getRelevantSectionErrors = (
  sectionData: SectionData,
  errors: {
    [x: string]: any
  },
) => {
  const cardFields = getAllFieldsOnCard(sectionData)
  const realErrors = { ...errors }
  Object.keys(errors).forEach(error => {
    if (!cardFields.includes(error)) {
      delete realErrors[error]
    }
  })
  return realErrors
}

export function getAllSections(appData: ApplicationData): SectionData[] {
  return appData.pages.flatMap(page => page.sections)
}

export function getFieldMetaData(
  appData: ApplicationData,
  fieldName: string,
): FieldMetaData {
  let metaData: FieldMetaData = {
    page: undefined,
    section: undefined,
  }
  appData.pages.forEach((page, pageNumber) => {
    page.sections.forEach(section => {
      section.fields.forEach(field => {
        if (field.name === fieldName) {
          metaData = { page: page, section: section, pageNumber: pageNumber }
        }
      })
    })
  })
  return metaData
}

export function getSectionMetaData(
  appData: ApplicationData,
  section: SectionData,
): FieldMetaData {
  let metaData: FieldMetaData = {
    page: undefined,
    section: undefined,
  }

  appData.pages.forEach((page, pageNumber) => {
    page.sections.forEach(tempSection => {
      if (section.title === tempSection.title)
        metaData = { page: page, section: section, pageNumber: pageNumber }
    })
  })

  return metaData
}

export function getSectionErrors(
  errors: {
    [x: string]: any
  },
  section: SectionData,
): { [x: string]: any }[] {
  let fieldErrors: { [x: string]: any }[] = []
  section.fields.forEach(field => {
    if (!!errors[field.name]) {
      fieldErrors = [...fieldErrors, errors[field.name]]
    }
  })
  return fieldErrors
}

export const isFieldEmpty = (fieldValue: any) => {
  if (Array.isArray(fieldValue)) {
    return fieldValue.length === 0
  }
  return !fieldValue
}

export function getIsFieldComplexRequiredAndEmpty(
  field: FieldAttributes,
  formValues: { [x: string]: any },
  nestedIndex?: number,
  isFinalCheck?: boolean,
  isMultipleSection?: boolean,
): boolean {
  const fieldName =
    nestedIndex !== undefined && isMultipleSection
      ? field.name.replace("_", `_${nestedIndex + 1}_`)
      : field.name

  return (
    determineIsRequired(
      field,
      formValues,
      nestedIndex,
      isFinalCheck,
      isMultipleSection,
    ) && isFieldEmpty(formValues[fieldName])
  )
}

export const isFieldIncomplete = (
  field: FieldAttributes,
  formValues: {
    [x: string]: any
  },
  errors: {
    [x: string]: any
  },
  nestedIndex?: number,
  isMultipleSection?: boolean,
): boolean => {
  const fieldIsShown = determineShowField(
    field,
    formValues,
    nestedIndex,
    true,
    isMultipleSection,
  )

  if (fieldIsShown) {
    const isFieldRequiredAndEmpty = getIsFieldComplexRequiredAndEmpty(
      field,
      formValues,
      nestedIndex,
      true,
      isMultipleSection,
    )

    if (fieldHasError(field, errors, nestedIndex) || isFieldRequiredAndEmpty) {
      return true
    }
  }

  return false
}

export const fieldHasError = (
  field: FieldAttributes,
  errors: {
    [x: string]: any
  },
  nestedIndex?: number,
) => {
  if (nestedIndex !== undefined) {
    return Object.keys(errors).some(
      errorField =>
        errorField === field.name.replaceAll("{{x}}", `${nestedIndex}`),
    )
  }

  return Object.keys(errors).some(errorField => errorField === field.name)
}

export const getInvalidFields = (
  fields: FieldAttributes[],
  formValues: {
    [x: string]: any
  },
  errors: {
    [x: string]: any
  },
): string[] => {
  let invalidFields: string[] = []
  fields.forEach(field => {
    if (isFieldIncomplete(field, formValues, errors)) {
      invalidFields = [...invalidFields, field.name]
    }
  })

  return invalidFields
}

export const getIgnoredNestedTitle = (title: string): string => {
  return `${IGNORE_PREFIX}${title}`
}

export const getNestedSectionCount = (
  sectionData: SectionData,
  applicationData: {
    [key: string]: any
  },
) => {
  let count = 0

  count = applicationData[sectionData.controlSectionName]?.length || 0

  return count
}

export const getNestedFieldName = (
  field: FieldAttributes,
  nestedIndex: number,
): string => {
  return field.name.replace("_", `_${nestedIndex + 1}_`)
}

/**
 * The criteria for a nested section to have its fields addressed:
 *
 * -The nested section has some field answered
 * OR -The minimum number of nested sections hasn't been reached
 *
 * If the criteria is met, then validate the fields within the section
 * as normal.
 */
export const getInvalidNestedFields = (
  fields: FieldAttributes[],
  nestedSectionCount: number,
  minNestedCount: number,
  formValues: {
    [x: string]: any
  },
  errors: {
    [x: string]: any
  },
  controlSectionName?: string,
): string[] => {
  let invalidFields: string[] = []

  // For each nested section that exists, find incomplete fields

  for (let i = 0; i < (nestedSectionCount || minNestedCount); i++) {
    const hasAnyFormValueAnswered = fields.some(field => {
      const fieldName = controlSectionName
        ? getNestedFieldName(field, i)
        : field.name

      return formValues[fieldName] !== undefined
    })

    const shouldValidateSection =
      hasAnyFormValueAnswered ||
      (minNestedCount !== undefined && minNestedCount > 0 && minNestedCount > i)

    if (!shouldValidateSection) {
      // If this nested section shouldn't be validated, don't add its errors to the list
      break
    }

    const relevantFields = fields
      .filter(field => {
        return isFieldIncomplete(
          field,
          formValues,
          errors,
          i,
          !!controlSectionName,
        )
      })
      .map(field => {
        return field.name
      })

    invalidFields = [...invalidFields, ...relevantFields]
  }

  return invalidFields
}

export const getNestedIndexFromTitle = (sectionData: SectionData) => {
  if (sectionData.nestedSectionsMax) {
    // Return the first instance of a number
    const numbers = sectionData.title.match("_[0-9]+")
    if (numbers && numbers.length > 0) {
      return parseInt(numbers[0].substring(1))
    }
  }

  return undefined
}

export const isNestedSection = (sectionData: SectionData) => {
  return sectionData.nestedSectionTitle || sectionData.nestedSectionsMax
}

export const attachRedirectUrl = (baseStr: string, redirectUrl: string) => {
  return baseStr + "&redirect_uri=" + redirectUrl
}

export const getParamFromUrlHash = (
  hash: string,
  param: string,
): string | undefined => {
  const splitStr = hash.split(`${param}=`)
  if (splitStr.length > 0) {
    return splitStr[1]
  }
}

export const getHeaderFromHeaders = (headersString: string, header: string) => {
  const splitStr = headersString.split("\r\n")
  const headerString = splitStr.find(str => str.includes(header))
  if (headerString) {
    const headerSplitStr = headerString.split(": ")
    if (headerSplitStr.length > 0) {
      return headerSplitStr[1]
    }
  }
  return undefined
}

export const getApiHeaders = ({
  token,
  isFileUpload,
}: {
  token?: string
  isFileUpload?: boolean
}) => {
  if (token && isFileUpload) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  } else if (token) {
    return { headers: { Authorization: `Bearer ${token}` } }
  }
  return {}
}

export const isEmptyEquals = (x: any, y: any): boolean => {
  if (x === "" || x === null || x === undefined) {
    return y === "" || y === null || y === undefined
  }

  return x === y
}

export const lessThanOneMinuteAgo = date => {
  const ONE_MINUTE = 60 * 1000
  const oneMinuteAgo = Date.now() - ONE_MINUTE
  return date > oneMinuteAgo
}

export const sanitizeFormValues = (data: {
  [key: string]: any
}): { [key: string]: any } => {
  Object.keys(data).forEach(key => {
    if (data[key] === null || data[key] === undefined) {
      delete data[key]
    }
  })
  return data
}

// Allow or disallow a user to user specific characters in an input
export const filterKeyboardInput = (
  event: React.KeyboardEvent<HTMLInputElement>,
  filter: "allow" | "disallow",
  testChars: (string | number)[],
) => {
  const input = toLowercaseString(event.key)

  const inputIsAllowed =
    filter === "allow"
      ? // user input must match one of the test characters
        testChars.some(char => toLowercaseString(char) === input)
      : // filter === "disallow"
        // user input must not match ANY of the test characters
        testChars.every(char => toLowercaseString(char) !== input)

  // inputIsAllowed ? keypress works normally : keypress is disabled
  return inputIsAllowed ? null : event.preventDefault()
}

/**
 * @param count The number of items
 * @param unit The singular word for an item. e.g. "day" or "year"
 * @param unitPlural An optional custom plural version of the unit. e.g. "children" in place of "childs" for the unit "child"
 * @returns "day" if count === 1, or "5 days" if count equals 5.
 */
export const pluralize = (count: number, unit: string, unitPlural?: string) => {
  const pluralizedUnit = unitPlural || unit + "s"
  return count === 1 ? unit : `${count} ${pluralizedUnit}`
}

export const getValueOrNestedValue = (formValue: any) => {
  if (formValue && formValue.value !== undefined) {
    return formValue.value
  }

  return formValue
}

/**
 * Get a list of sections that are invalid or incomplete by the standards of the final check page
 */
export const getInvalidSections = ({
  applicationData,
  formValues,
  errors,
  currentValues,
}: {
  applicationData: ApplicationData
  formValues: {
    [x: string]: any
  }
  errors: {
    [x: string]: any
  }
  currentValues: {
    [x: string]: any
  }
}) => {
  let invalidSections = []

  getAllSections(applicationData).forEach(section => {
    const isSectionToValidate =
      isSectionStarted(section, formValues) || !section.isOptional

    if (isNestedSection(section) && isSectionToValidate) {
      // Generate individual field errors in nested section

      const fieldErrors = getInvalidNestedFields(
        section.fields,
        getNestedSectionCount(section, currentValues),
        section.nestedSectionsMin,
        formValues,
        errors,
        section.controlSectionName,
      )

      if (fieldErrors.length > 0) {
        invalidSections = [...invalidSections, section]
      }
    } else if (
      isSectionToValidate &&
      section.fields.some(field => isFieldIncomplete(field, formValues, errors))
    ) {
      invalidSections = [...invalidSections, section]
    }
  })

  return invalidSections
}

export const isSectionStarted = (
  sectionData: SectionData,
  formValues: {
    [x: string]: any
  },
): boolean => {
  return sectionData.fields.some(field => {
    // Nested section field name will be modified, standard field name will stay the same
    const fieldName = field.name.replaceAll("{{x}}", "1")
    return formValues[fieldName] !== undefined
  })
}

/**
 * Determine if an error is a 403 or 401 network error
 *
 * @param err The error containing the response
 * @returns Whether the error is a 401 or 403 network error
 */
export const isAuthorizationError = (err: any) => {
  return (
    !!err.response &&
    !!err.response.status &&
    (err.response.status === 401 || err.response.status === 403)
  )
}

//transform parents array to data fields as BE is expected data in this format
export const transformFormDataToServerData = (data, keysArray) => {
  const newData = data
  keysArray.forEach(key => {
    newData[key]?.map((el, ind) =>
      Object.keys(el).map(perentField => {
        const field = perentField.replace("_", `_${ind + 1}_`)
        data[field] = el[perentField]
        return field
      }),
    )

    delete newData[key]
  })
  return newData
}

//transform parents data to an array as FE form is expected data in this format
export const transformServerDataToFormData = (data, keysArray) => {
  const newData = data

  keysArray?.forEach(key => {
    newData[`${key}s`] = []

    Object.keys(newData).forEach(el => {
      if (el.includes(`${key}_`)) {
        //get index of an object we need to push to an array
        const match = el.match(/\d+/)
        let index: number

        if (match && match.length > 0) {
          index = parseInt(match[0])
        }

        //check whether we have the index to put only fields with numbers to the array (e.g. parent_1_address, not parent_existence)
        if (index) {
          const fieldName = el.replace(`_${index}_`, "_")

          newData[`${key}s`][index - 1]
            ? (newData[`${key}s`][index - 1][fieldName] = newData[el])
            : newData[`${key}s`].push({ [fieldName]: newData[el] })

          delete newData[el]
        }
      }
      return el
    })
  })

  return newData
}

export const transformDataToServerFormat = (data, arrayToCheck) => {
  //check if all the entities of arrayToCheck are marked as No
  const allFieldsAreRemoved = arrayToCheck.every(el => data[el.key] === "No")

  // if any of the entities of arrayToCheck are NOT marked as No - transform formValues
  if (!allFieldsAreRemoved) {
    const depArray = []

    // eslint-disable-next-line
    arrayToCheck.map(el => {
      if (data[el.key] === "No") {
        delete data[el.dependencyKey]
      } else {
        depArray.push(el.dependencyKey)
      }
    })
    return transformFormDataToServerData(data, depArray)
  }
}

const cleanupSection = ({ fields, values, setValue }) => {
  fields
    .filter(fieldData => values.hasOwnProperty(fieldData.name)) // Include only fields which already presented in data set
    .forEach(fieldData => {
      // hide:true uses as indicator that we never show this field, but need to have in data set,
      // that is why don't need to remove it from data set
      const isShown =
        fieldData.hide === true || determineShowField(fieldData, values)

      // Remove value from data set if it's hidden or empty
      if (!isShown || values[fieldData.name] === "") {
        !isShown && setValue(fieldData.name, "") // We need to reset field value manually in case we hide the field.
        delete values[fieldData.name]
      }
    })

  return values
}

// Get final data without hidden fields in server format
export const getFinalData = (getValues, client, setValue) => {
  const sections = getAllSections(client.data)

  let currentFields = []

  // Collect all sections in one array
  sections.forEach(({ fields }) => {
    currentFields = [...currentFields, ...fields]
  })

  const newData = getValues()

  // Check which multiple section presenten in the current data set
  const presentedMultipleSections = COLLEGE_NESTED_FIELDS[client.theme].filter(
    item => newData.hasOwnProperty(pluralizeString(item)),
  )

  // Go to each multiple section and clean from hidden and empty fields
  if (presentedMultipleSections.length > 0) {
    presentedMultipleSections.forEach(section => {
      if (newData[pluralizeString(section)].length > 0) {
        newData[pluralizeString(section)].map(element =>
          cleanupSection({
            fields: currentFields,
            values: element,
            setValue,
          }),
        )
        const firstItem = newData[pluralizeString(section)][0]
        // If multiple sections is presented but empty, just remove it
        if (firstItem && isEmpty(firstItem)) {
          delete newData[pluralizeString(section)]
        }
      } else {
        delete newData[pluralizeString(section)]
      }
    })
  }
  // Clean data set from hidden and empty fields
  const filteredData = cleanupSection({
    fields: currentFields,
    values: newData,
    setValue,
  })

  const arrayToCheck = COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT[client.theme]

  // Transform data to server format
  return transformDataToServerFormat(filteredData, arrayToCheck)
}

export const pluralizeString = string => `${string}s`

export const setupQueryParamsToLocalStorage = () => {
  const queryParamsString = window.location.search

  if (!queryParamsString) {
    return
  }
  const queryParams = new URLSearchParams(queryParamsString)

  const queryParamsObject = {}
  for (const [key, value] of queryParams) {
    queryParamsObject[key] = value
  }

  queryParamsObject["queryString"] = queryParamsString

  const queryParamsName = "queryParams"
  const isValidQueryParams =
    localStorage.getItem(queryParamsName) &&
    !isEmpty(localStorage.getItem(queryParamsName))

  if (!isValidQueryParams) {
    localStorage.setItem(queryParamsName, JSON.stringify(queryParamsObject))
  }
}
