import { Input } from "components/common"
import { TextInputProps } from "types"
import { forwardRef } from "@chakra-ui/react"
import { filterKeyboardInput } from "utilities/helpers"

export const ZipCodeInput = forwardRef<TextInputProps, "div">((props, ref) => {
  /**
   * 1. Why isn't this a NumberInput or <input type="number" /> ?
   * - Numbers can't start with zero and zip codes need to.
   * - Numbers can include decimals and zip codes do not.
   *
   * 2. Why is it a "tel" type input?
   * - "tel" type inputs will trigger the numeric keypad on mobile devices.
   */

  const handleKeyPress = (keyPress: React.KeyboardEvent<HTMLInputElement>) => {
    const singleDigitNumbers = Array.from(Array(10).keys())
    return filterKeyboardInput(keyPress, "allow", singleDigitNumbers)
  }

  return (
    <Input
      {...props}
      ref={ref}
      type="tel" // see comment #2 above
      onKeyPress={handleKeyPress}
    />
  )
})
