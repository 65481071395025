// Returned from `type: "tel-2"` in college data

import { forwardRef, useStyleConfig, Box } from "@chakra-ui/react"

import ReactPhoneInput, {
  PhoneInputProps as ReactPhoneInputProps,
} from "react-phone-input-2"

import { FormControl, SharedFormControlProps } from "./FormControl"

import "react-phone-input-2/lib/high-res.css"

interface PhoneInputProps
  extends ReactPhoneInputProps,
    SharedFormControlProps {}

export const PhoneInput2 = forwardRef<PhoneInputProps, "input">(
  (
    {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      ...rest
    },
    ref,
  ) => {
    const FormControlValues = {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
    }

    const phoneInputStyles = useStyleConfig("PhoneInput")

    return (
      <Box __css={phoneInputStyles} ref={ref}>
        <FormControl {...FormControlValues}>
          <ReactPhoneInput
            enableSearch={true}
            disableSearchIcon
            placeholder="(000) 000-0000"
            country="us" // initial country
            preferredCountries={["us", "ca", "au"]}
            preserveOrder={["preferredCountries"]}
            {...rest}
          />
        </FormControl>
      </Box>
    )
  },
)
