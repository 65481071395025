import { ApplicationData, FieldAttributes, FieldCondition } from "types"
import * as Yup from "yup"
import { getAllFields, pluralize } from "./helpers"

/**
 *
 * Create a schema to validate the form data based on form json
 *
 * @param applicationData The JSON representing the entire form
 * @returns a flat (single array) schema of key value pairs
 * key: the field name // value: a custom schema for each input type based on JSON data
 * E.G: [{fieldName1: schema1, fieldName2: schema2}]
 */

export const generateValidationSchema = (appData: ApplicationData) => {
  // Flatten all questions into a single array
  const fields = getAllFields(appData)
  const fieldsObj: any = {}

  fields.forEach(field => {
    // Don't include nested fields in validation schema
    if (field.name.includes("{{x}}")) {
      const maxFields = 10
      for (let i = 1; i < maxFields; i++) {
        let parsedFieldName = field.name.replace("{{x}}", `${i}`)
        fieldsObj[parsedFieldName] = field
      }
    } else {
      fieldsObj[field.name] = field
    }
  })

  const schemaShape = () => {
    Object.keys(fieldsObj).forEach(function (key) {
      fieldsObj[key] = generateFieldSchema(fieldsObj[key])
    })
    return fieldsObj
  }

  return Yup.object().shape(schemaShape())
}

/**
 * Create a schema for a field
 *
 * @param fieldData The JSON representing the field
 * @returns the schema to validate the given field
 */
const generateFieldSchema = (fieldData: FieldAttributes) => {
  // Fields that users cannot interact with shouldn't validate
  if (fieldData.isReadOnly || fieldData.isDisabled) {
    return DEFAULT_SCHEMA
  }

  const errorMessage = fieldData.errorText || undefined

  let schema: any

  switch (fieldData.type) {
    case "text":
    case "password":
    case "tel":
    case "tel-2":
    case "textarea":
    case "zip_code":
      schema = generateTextSchema(fieldData, errorMessage)
      break
    case "email":
      schema = generateEmailSchema(fieldData, errorMessage)
      break
    case "date":
      schema = generateDateSchema(fieldData, errorMessage)
      break
    case "month":
      schema = generateMonthSchema(fieldData, errorMessage)
      break
    case "year":
      schema = generateYearSchema(fieldData, errorMessage)
      break
    case "select":
    case "radio":
      schema = generateOptionsSchema(fieldData, errorMessage)
      break
    case "checkbox":
      schema = generateMultiOptionsSchema(fieldData, errorMessage)
      break
    case "number":
    case "integer":
      schema = generateNumberSchema(fieldData, errorMessage)
      break
    case "highSchoolSearch":
    case "collegeSearch":
    case "countrySearch":
    case "stateSearch":
    case "countySearch":
    case "activitySearch":
    case "apSearch":
    case "ibSearch":
    case "languageSearch":
    case "sportSearch":
    case "religionSearch":
      schema = generateSearchSchema(fieldData, errorMessage)
      break
    default:
      schema = DEFAULT_SCHEMA
  }

  // Check if schema is defined before applying isRequired
  if (schema === undefined) {
    console.log(fieldData)
    console.warn(`Schema is undefined for field: ${fieldData.name}`)
    return DEFAULT_SCHEMA
  }

  // Apply isRequired validation to all schemas
  return isRequired(fieldData, schema, errorMessage)
}

export const isRequired = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  const requiredMessage = errorMessage || "This field is required"

  if (!fieldData.isRequired) {
    return schema
  }

  if (typeof fieldData.isRequired === "boolean") {
    return schema.test("is-required", requiredMessage, value => {
      return value !== null && value !== undefined && value !== ""
    })
  }

  if (
    typeof fieldData.isRequired === "object" &&
    "conditions" in fieldData.isRequired
  ) {
    const conditionSet = fieldData.isRequired
    return schema.test(
      "is-conditionally-required",
      requiredMessage,
      function (value, context) {
        const isConditionMet = evaluateConditions(
          conditionSet.conditions,
          context.parent,
        )
        if (isConditionMet) {
          return value !== null && value !== undefined && value !== ""
        }
        return true
      },
    )
  }

  return schema
}

function evaluateConditions(
  conditions: FieldCondition[],
  formValues: any,
): boolean {
  return conditions.every(condition => {
    if (!formValues) return false
    if ("value" in condition) {
      return formValues[condition.name] === condition.value
    } else if ("notValue" in condition) {
      return formValues[condition.name] !== condition.notValue
    }
    return true
  })
}

export const numberFieldExists = (field: number) => {
  return field !== null && field !== undefined
}

export const booleanFieldExists = (field: boolean) => {
  return field !== null && field !== undefined
}

export const generateTextSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.string()

  schema = isRequired(fieldData, schema, errorMessage)
  schema = getMinLength(fieldData, schema, errorMessage)
  schema = getMaxLength(fieldData, schema, errorMessage)
  schema = getIsLength(fieldData, schema, errorMessage)

  return schema
}

export const generateEmailSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.string().email(errorMessage || "Email address invalid")

  // Apply the isRequired validation if necessary
  schema = isRequired(fieldData, schema, errorMessage)

  return schema
}

export const getMinLength = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.length?.min)) {
    const minLength = fieldData.validations?.length?.min
    return schema.min(
      minLength,
      errorMessage || `Value must be at least ${minLength} characters`,
    )
  }
  return schema
}

export const getMaxLength = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.length?.max)) {
    const maxLength = fieldData.validations?.length?.max
    return schema.max(
      maxLength,
      errorMessage || `Value must be at most ${maxLength} characters`,
    )
  }
  return schema
}

export const getIsLength = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.length?.is)) {
    return schema.test(
      `${fieldData.name}`,
      errorMessage ||
        `Input must be length: ${fieldData.validations.length.is}`,
      value => {
        if (value?.length === 0) {
          return true
        }
        return fieldData.validations.length.is === value?.length
      },
    )
  }
  return schema
}

export const generateDateSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.date().nullable()

  schema = setDateRules(fieldData, schema, errorMessage)
  schema = isRequired(fieldData, schema, errorMessage)

  // Any additional schemas generated AFTER this transform, will fail.
  schema = schema
    .transform((value, originalValue) => {
      if (originalValue?.length === 10) {
        return value
      }
      return null
    })
    .test(
      `${fieldData.name}-date-format`,
      errorMessage || "Invalid date format, must be MM/DD/YYYY",
      (value, context) => {
        // @ts-ignore
        if (!context.originalValue) {
          return true
        }
        // @ts-ignore
        return context.originalValue?.replace(/ /g, "").length === 10
          ? true
          : false
      },
    )

  return schema.typeError(errorMessage || "Invalid date")
}

export const transformMonthToStandardFormat = (month: string) => {
  return new Date(month.split("/").join("/01/"))
}

export const generateMonthSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.date().nullable()
  schema = setDateRules(fieldData, schema, errorMessage)
  schema = isRequired(fieldData, schema, errorMessage)

  // Any additional schemas generated AFTER this transform, will fail.
  schema = schema
    .transform((value, originalValue) => {
      if (originalValue?.length === 7) {
        return transformMonthToStandardFormat(originalValue)
      }
      return null
    })
    .test(
      `${fieldData.name}-date-format`,
      errorMessage || "Invalid date format, must be MM/YYYY",
      (value, context) => {
        // @ts-ignore
        if (!context.originalValue) {
          return true
        }
        // @ts-ignore
        return context.originalValue?.replace(/ /g, "").length === 7
          ? true
          : false
      },
    )

  return schema.typeError(errorMessage || "Invalid date")
}

export const generateYearSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.date().nullable()
  schema = setDateRules(fieldData, schema, errorMessage)
  schema = isRequired(fieldData, schema, errorMessage)

  // Any additional schemas generated AFTER this transform, will fail.
  schema = schema
    .transform((value, originalValue) => {
      if (originalValue?.length === 4) {
        return value
      }
      return null
    })
    .test(
      `${fieldData.name}-date-format`,
      errorMessage || "Invalid date format, must be YYYY",
      (value, context) => {
        // @ts-ignore
        if (!context.originalValue) {
          return true
        }
        // @ts-ignore
        return context.originalValue?.replace(/ /g, "").length === 4
          ? true
          : false
      },
    )

  return schema.typeError(errorMessage || "Invalid date")
}

export const getMinDate = (
  count: number,
  unit: "days" | "months" | "years",
) => {
  switch (unit) {
    case "days":
      return new Date(new Date().setDate(new Date().getDate() - count))
    case "months":
      return new Date(new Date().setMonth(new Date().getMonth() - count))
    case "years":
      return new Date(new Date().setFullYear(new Date().getFullYear() - count))
  }
}

export const getMaxDate = (
  count: number,
  unit: "days" | "months" | "years",
) => {
  switch (unit) {
    case "days":
      return new Date(new Date().setDate(new Date().getDate() + count))
    case "months":
      return new Date(new Date().setMonth(new Date().getMonth() + count))
    case "years":
      return new Date(new Date().setFullYear(new Date().getFullYear() + count))
  }
}

export const setDateRules = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  // Date must be after a specified date
  if (fieldData.validations?.date?.after) {
    let date = new Date()
    switch (fieldData.type as string) {
      case "date":
      case "year":
        date = new Date(fieldData.validations?.date?.after)
        break
      case "month":
        date = transformMonthToStandardFormat(
          fieldData.validations?.date?.after,
        )
        break
      default:
        break
    }
    schema = schema.min(
      date,
      errorMessage ||
        `Date must be after ${fieldData.validations?.date?.after}`,
    )
  }

  // Date must be before a specified date
  if (fieldData.validations?.date?.before) {
    let date = new Date()
    switch (fieldData.type as string) {
      case "date":
      case "year":
        date = new Date(fieldData.validations?.date?.before)
        break
      case "month":
        date = transformMonthToStandardFormat(
          fieldData.validations?.date?.before,
        )
        break
      default:
        break
    }
    schema = schema.max(
      date,
      errorMessage ||
        `Date must be before ${fieldData.validations?.date?.before}`,
    )
  }

  // Date must be after today
  if (booleanFieldExists(fieldData.validations?.date?.isInFuture)) {
    const dateToCompare = new Date() // aka Today
    schema = schema.min(
      dateToCompare,
      errorMessage || `Date must be in the future`,
    )
  }

  // Date must be before today
  if (booleanFieldExists(fieldData.validations?.date?.isInPast)) {
    const dateToCompare = new Date() // aka Today
    schema = schema.max(
      dateToCompare,
      errorMessage || `Date must be in the past`,
    )
  }

  // Date must be between maxDaysInThePast and today
  if (numberFieldExists(fieldData.validations?.date?.maxDaysInPast)) {
    const { maxDaysInPast } = fieldData.validations.date
    schema = schema.min(
      getMinDate(maxDaysInPast, "days"),
      errorMessage ||
        `Date must be within the past ${pluralize(maxDaysInPast, "day")}`,
    )
  }

  // Date must be between today and maxDaysInFuture
  if (numberFieldExists(fieldData.validations?.date?.maxDaysInFuture)) {
    const { maxDaysInFuture } = fieldData.validations.date
    schema = schema.max(
      getMaxDate(maxDaysInFuture, "days"),
      errorMessage ||
        `Date must be within the next ${pluralize(maxDaysInFuture, "day")}`,
    )
  }

  // Date must be between maxMonthsInPast and today
  if (numberFieldExists(fieldData.validations?.date?.maxMonthsInPast)) {
    const { maxMonthsInPast } = fieldData.validations.date
    schema = schema.min(
      getMinDate(maxMonthsInPast, "months"),
      errorMessage ||
        `Date must be within the past ${pluralize(maxMonthsInPast, "month")}`,
    )
  }

  // Date must be between today and maxMonthsInFuture
  if (fieldData.validations?.date?.maxMonthsInFuture) {
    const { maxMonthsInFuture } = fieldData.validations.date
    schema = schema.max(
      getMaxDate(maxMonthsInFuture, "months"),
      errorMessage ||
        `Date must be within the next ${pluralize(maxMonthsInFuture, "month")}`,
    )
  }

  // Date must be between maxYearsInPast and today
  if (numberFieldExists(fieldData.validations?.date?.maxYearsInPast)) {
    const { maxYearsInPast } = fieldData.validations.date
    schema = schema.min(
      getMinDate(maxYearsInPast, "years"),
      errorMessage ||
        `Date must be within the past ${pluralize(maxYearsInPast, "year")}`,
    )
  }

  // Date must be between today and maxYearsInFuture
  if (numberFieldExists(fieldData.validations?.date?.maxYearsInFuture)) {
    const { maxYearsInFuture } = fieldData.validations.date
    schema = schema.max(
      getMaxDate(maxYearsInFuture, "years"),
      errorMessage ||
        `Date must be within the next ${pluralize(maxYearsInFuture, "year")}`,
    )
  }

  return schema
}

export const setMinNumber = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.min)) {
    const { min } = fieldData.validations.value
    return schema.test(
      `${fieldData.name}`,
      errorMessage || `Value must be greater than or equal to ${min}`,
      value => {
        return value >= min
      },
    )
  }
  return schema
}

export const setMaxNumber = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.max)) {
    const { max } = fieldData.validations.value
    return schema.test(
      `${fieldData.name}`,
      errorMessage || `Value must be less than or equal to ${max}`,
      value => {
        return value <= max
      },
    )
  }
  return schema
}

export const setEqualNumber = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.is)) {
    return schema.test(
      `${fieldData.name}`,
      errorMessage || "Values must be equal",
      value => {
        return value === fieldData.validations.value.is
      },
    )
  }
  return schema
}

export const setIsDivisibleBy = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.divisor)) {
    const { divisor } = fieldData.validations.value
    return schema.test(
      `${fieldData.name}`,
      errorMessage || `Value must be divisible by ${divisor}`,
      value => {
        return value % divisor === 0
      },
    )
  }
  return schema
}

export const generateNumberSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.number().nullable()

  schema = isRequired(fieldData, schema, errorMessage)
  schema = setMaxNumber(fieldData, schema, errorMessage)
  schema = setMinNumber(fieldData, schema, errorMessage)
  schema = setEqualNumber(fieldData, schema, errorMessage)
  schema = setIsDivisibleBy(fieldData, schema, errorMessage)

  schema = schema.transform((value, originalValue) => {
    if (originalValue === "") {
      return null
    }
    return value
  })

  return schema
}

const generateOptionsSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  return Yup.mixed()
    .nullable()
    .test(
      "is-valid-selection",
      errorMessage || `${fieldData.label} is required`,
      value => {
        if (fieldData.isRequired) {
          if (typeof value === 'string') {
            return value !== null && value !== undefined && value !== "";
          } else if (value && typeof value === 'object' && 'value' in value) {
            return value.value !== null && value.value !== undefined && value.value !== "";
          }
          return false;
        }
        return true;
      },
    )
    .transform((currentValue, originalValue) => {
      if (originalValue && typeof originalValue === 'object' && 'value' in originalValue) {
        return originalValue.value;
      }
      return currentValue;
    });
}

export const generateMultiOptionsSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  if ("options" in fieldData && fieldData.options.length === 1) {
    // Single checkbox
    return Yup.boolean().nullable().test(
      'is-checked',
      errorMessage || "This field is required",
      value => {
        if (fieldData.isRequired) {
          // Check if the value is true or an array with a non-empty string
          return value === true || (Array.isArray(value) && value.length > 0 && value[0] !== '');
        }
        return true;
      }
    ).transform((value) => {
      // Transform array to boolean
      if (Array.isArray(value)) {
        return value.length > 0 && value[0] !== '';
      }
      return value;
    });
  } else {
    // Multiple checkboxes
    let schema = Yup.array().of(Yup.string())
    const lengthValidations = fieldData.validations?.length

    if (lengthValidations) {
      if (lengthValidations?.min) {
        schema = schema.min(
          lengthValidations?.min,
          errorMessage ||
            `At least ${lengthValidations?.min} selection(s) required`,
        )
      }
      if (lengthValidations?.max) {
        schema = schema.max(
          lengthValidations?.max,
          errorMessage ||
            `No more than ${lengthValidations?.max} selection(s) allowed`,
        )
      }
    }

    return schema
  }
}

const generateSearchSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  return Yup.mixed()
    .nullable()
    .test(
      "is-valid-selection",
      errorMessage || "This field is required",
      value => {
        if (fieldData.isRequired) {
          return value !== null && value !== undefined && value !== ""
        }
        return true
      },
    )
}

export const DEFAULT_SCHEMA = Yup.object()
  .shape({} as any)
  .nullable()
