import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  forwardRef,
} from "@chakra-ui/react"
import { Option } from "types"
import { FormControl, SharedFormControlProps } from "./FormControl"

export interface SelectProps extends ChakraSelectProps, SharedFormControlProps {
  /**
   * An array of { value: "foo", label: "bar" } objects
   */
  options: Option[]
  /**
   * Placeholder will be shown before all options
   */
  placeholder?: string
}

export const Select = forwardRef<SelectProps, "select">(
  (
    {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      options,
      ...rest
    },
    ref
  ) => {
    const FormControlValues = {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
    }

    return (
      <FormControl {...FormControlValues}>
        <ChakraSelect ref={ref} {...rest}>
          {options &&
            options.length > 0 &&
            options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </ChakraSelect>
      </FormControl>
    )
  }
)
