import { CollegeData } from "components/CollegeSearch"
import { HighSchoolData } from "components/HighSchoolSearch"
import ApiRoot from "./apiRoot"

export interface CollegeSearchResponse {
  status: string | number
  data: CollegeData[]
}

export interface HighSchoolSearchResponse {
  status: string | number
  data: HighSchoolData[]
}

export class CustomSearch extends ApiRoot {
  // TODO: replace 'any' with the shape of the returning data
  static highSchoolSearch(
    query: string,
    token: string | string[]
  ): Promise<void | HighSchoolSearchResponse> {
    let queryString = `?query=${query}`
    return this.connection
      .get(`/lookup/ceeb${queryString}`, {
        headers: {
          Authorization: `Bearer ${token[0]}`,
        },
      })
      .then(res => {
        return {
          status: res.status,
          data: res.data,
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // TODO: replace 'any' with the shape of the returning data
  static collegeSearch(
    query: string,
    token: string | string[]
  ): Promise<void | CollegeSearchResponse> {
    let queryString = `?query=${query}`
    return this.connection
      .get(`/lookup/college-codes${queryString}`, {
        headers: {
          Authorization: `Bearer ${token[0]}`,
        },
      })
      .then(res => {
        return {
          status: res.status,
          data: res.data,
        }
      })
      .catch(e => {
        console.log(e)
      })
  }
}
