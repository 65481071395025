const primaryColor = "#003767"
const secondaryColor = "#00AEEF"
const accentColor = "#58788D"

const pageBgColorShade1 = "#FAFAFA"
const pageBgColorShade2 = "#F5F5F5"

const bodyColor = "#323B48"
const bodyColorLight = "#5B6470"

const black = "#000000"
const white = "#FFFFFF"

// 👆 variables only used in this file
// -------------------------------------
// 👇 named variables used in the theme

export const colors = {
  bodyColor,
  bodyColorLight,

  borderColor: "#C2C2C2",
  borderColorAlt: "#BED1DD",

  buttonColor: "#AF7C31",
  buttonActiveColor: "#92631E",
  buttonFocusColor: "#AF7C31",
  buttonHoverColor: "#92631E",

  checkedBoxBackgroundColor: white,

  linkColor: accentColor,
  linkHoverColor: primaryColor,
  linkColorDark: bodyColor,
  linkHoverColorDark: black,

  black: black,

  checkedBoxMarkColor: primaryColor,

  error: "#C9440B",
  errorText: "#B71E25",

  formInputBackgroundColor: white,
  formInputBorderColor: primaryColor,
  formInputTextColor: black,
  formLabelColor: primaryColor,

  headingColor: primaryColor,
  helperTextColor: bodyColorLight,

  navLinkColor: "#41698D",
  navLinkActiveColor: "#013767",
  navLinkActiveAccentColor: secondaryColor,
  navLinkHoverBackgroundColor: pageBgColorShade2,
  navLinkHoverColor: accentColor,
  pageBgColorShade1: pageBgColorShade1,

  primaryColor: primaryColor,

  secondaryColor: secondaryColor,

  siteFooterBackgroundColor: "#FCFAF6",
  siteFooterLinkColor: bodyColorLight,

  siteHeaderBackgroundColor: primaryColor,

  white: white,

  checkboxBorder: "#809BB3",
  checkboxHoverBorder: "#013767",
  checkboxHoverBackground: "#E6EBF0",
  selectHoverColor: "#E6EBF0",
}
