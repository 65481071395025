import ApiRoot from "./apiRoot"

interface IdToken {
  idToken: string
}

export class AuthApi extends ApiRoot {
  static authenticate({
    idToken,
    cid,
  }: {
    idToken: string
    cid: string
  }): Promise<IdToken> {
    return this.connection.post(`/token`, { idToken, cid }).then(response => {
      return response.data
    })
  }
}
