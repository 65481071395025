import React from "react"
import { LinkProps as ChakraLinkProps, Icon, Link } from "@chakra-ui/react"
import { IconType } from "react-icons"
import { MdOpenInNew } from "react-icons/md"

export interface ExternalLinkProps extends ChakraLinkProps {
  /**
   *  If `true`, the link will open in new tab and include arrow icon
   */
  withIcon?: boolean
  /**
   *  An icon from the React Icons library
   */
  icon?: IconType
  /**
   * Size will reference Chakra theme sizes
   */
  iconSize?: number
}
export const ExternalLink = ({
  withIcon = false,
  icon = MdOpenInNew,
  iconSize = 4,
  children,
  ...rest
}: ExternalLinkProps) => {
  return (
    <Link isExternal target="_blank" {...rest}>
      {children}{" "}
      {withIcon ? <Icon as={icon} w={iconSize} h={iconSize} mx="2px" /> : null}
    </Link>
  )
}
