export const AP_SUBJECTS = [
  { label: "Art History", value: "Art History" },
  { label: "2-D Art and Design", value: "2-D Art and Design" },
  { label: "3-D Art and Design", value: "3-D Art and Design" },
  { label: "Drawing", value: "Drawing" },
  { label: "Music Theory", value: "Music Theory" },
  { label: "English exams", value: "English exams" },
  { label: "English", value: "English" },
  {
    label: "English Language and Composition",
    value: "English Language and Composition",
  },
  {
    label: "English Literature and Composition",
    value: "English Literature and Composition",
  },
  { label: "Macroeconomics", value: "Macroeconomics" },
  { label: "Microeconomics", value: "Microeconomics" },
  { label: "Human Geography", value: "Human Geography" },
  {
    label: "Comparative Government and Politics",
    value: "Comparative Government and Politics",
  },
  {
    label: "United States Government and Politics",
    value: "United States Government and Politics",
  },
  { label: "European History", value: "European History" },
  {
    label: "United States History",
    value: "United States History",
  },
  {
    label: "World History: Modern",
    value: "World History: Modern",
  },
  { label: "Psychology", value: "Psychology" },
  { label: "Biology", value: "Biology" },
  { label: "Chemistry", value: "Chemistry" },
  {
    label: "Environmental Science",
    value: "Environmental Science",
  },
  {
    label: "Physics 1: Algebra-Based",
    value: "Physics 1: Algebra-Based",
  },
  {
    label: "Physics 2: Algebra-Based",
    value: "Physics 2: Algebra-Based",
  },
  { label: "Physics B", value: "Physics B" },
  {
    label: "Physics C: Electricity and Magnetism",
    value: "Physics C: Electricity and Magnetism",
  },
  {
    label: "Physics C: Mechanics",
    value: "Physics C: Mechanics",
  },
  { label: "Calculus AB", value: "Calculus AB" },
  { label: "Calculus BC", value: "Calculus BC" },
  { label: "Computer Science A", value: "Computer Science A" },
  {
    label: "Computer Science Principles",
    value: "Computer Science Principles",
  },
  { label: "Statistics", value: "Statistics" },
  { label: "AP Capstone", value: "AP Capstone" },
  { label: "AP Capstone", value: "AP Capstone" },
  { label: "Capstone: Seminar", value: "Capstone: Seminar" },
  { label: "Capstone: Research", value: "Capstone: Research" },
  {
    label: "Chinese Language and Culture",
    value: "Chinese Language and Culture",
  },
  {
    label: "French Language and Culture",
    value: "French Language and Culture",
  },
  {
    label: "German Language and Culture",
    value: "German Language and Culture",
  },
  {
    label: "Italian Language and Culture",
    value: "Italian Language and Culture",
  },
  {
    label: "Japanese Language and Culture",
    value: "Japanese Language and Culture",
  },
  { label: "Latin: Vergil", value: "Latin: Vergil" },
  {
    label: "Spanish Language and Culture",
    value: "Spanish Language and Culture",
  },
  {
    label: "Spanish Literature and Culture",
    value: "Spanish Literature and Culture",
  },
]
