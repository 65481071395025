// TODO: When we have a CMS-style color picker for making these
// themes, some colors can have their default set by a parent and
// then optionally be customized.

export const colors = {
  bodyColor: "#323B48",
  bodyColorLight: "#5B6470",
  bodyColorLighter: "#858B93",
  borderColor: "#C2C2C2",
  buttonLinkColor: "#0D736A",
  buttonLinkHoverColor: "#1BA094",
  black: "#303336",
  checkedBoxMarkColor: "#323B48",
  checkedBoxBackgroundColor: "#FFFFFF",
  error: "#B71E25",
  errorText: "#B71E25",
  formInputBackgroundColor: "#FFFFFF",
  formInputBorderColor: "#858B93",
  formInputHoveredBorderColor: "#303336",
  formInputFocusedBorderColor: "blue.500",
  formInputTextColor: "#323B48",
  formLabelColor: "#323B48",
  headingColor: "#002E6D",
  helperTextColor: "#718096",
  navLinkColor: "#0D736A",
  navLinkActiveColor: "#002E6D",
  navLinkActiveAccentColor: "#3CB4E5",
  navLinkHoverColor: "#47938d",
  navLinkHoverBackgroundColor: "#F5F5F5",
  pageBgColor: "#FFFFFF",
  pageBgColorShade1: "#FAFAFA",
  pageBgColorShade2: "#F5F5F5",
  pageBgColorShade3: "#EBEBEB",
  // siteFooterBackgroundColor: "#EBEBEB", // causes flash on child themes
  siteFooterLinkColor: "#0D736A",
  siteFooterLinkHoverColor: "#1BA094",
  // siteHeaderBackgroundColor: "#3CB4E5", // causes flash on child themes
  primaryColor: "#3CB4E5",
  progressBarBackgroundColor: "#C2C2C2",
  progressBarFillColor: "#3CB4E5",
  secondaryColor: "#19D3C5",
  success: "#05B56C",
  white: "#FCFCFC",
  disabledBackground: "#F5F5F5",
  disabledText: "#ADB1B7",
  disabledBorder: "#EBEBEB",
  disabledCheckedBackground: "#CED0D4",
}
