import { createContext, useState, PropsWithChildren, useEffect } from "react"
import { ChildrenProps } from "./types/context.types"
import { Client } from "api/client"
import TealiumInit from "tealium/tealium-init"

interface ClientContextState {
  client: Client
  setClient: (client: Client) => void
}

const initialValue: ClientContextState = {
  client: undefined,
  setClient: () => {},
}

export const ClientContext = createContext(initialValue)

export const ClientProvider: React.FC<PropsWithChildren<ChildrenProps>> = ({
  children,
}) => {
  const [client, setClient] = useState<Client>()

  useEffect(() => {
    if (client) {
      TealiumInit(client.theme)
    }
  }, [client])

  return (
    <ClientContext.Provider value={{ client, setClient }}>
      {children}
    </ClientContext.Provider>
  )
}
