import { useContext } from "react"
import { forwardRef } from "@chakra-ui/react"
import FormContext from "context/FormContext"
import { Searchbox } from "components/Searchbox"
import {
  AP_SUBJECTS,
  COUNTRIES,
  ACTIVITIES,
  IB_SUBJECTS,
  LANGUAGES,
  RELIGIONS,
  SPORTS,
  STATES,
  COUNTIES,
} from "data/dataSets"
import { CustomSearchTypes, TextInputProps } from "types"
import { HighSchoolSearch } from "components/HighSchoolSearch"
import { CollegeSearch } from "components/CollegeSearch"

export const SearchHandler = forwardRef<TextInputProps, "div">((props, ref) => {
  const { name, ...rest } = props
  const {
    form: { getValues },
  } = useContext(FormContext)

  const formValues = getValues()

  let searchOptions = []

  switch (props.type as CustomSearchTypes) {
    case "highSchoolSearch":
      return <HighSchoolSearch ref={ref} {...props} />
    case "collegeSearch":
      return <CollegeSearch ref={ref} {...props} />
    case "countrySearch":
      searchOptions = COUNTRIES
      break
    case "stateSearch":
      searchOptions = STATES
      break
    case "countySearch":
      searchOptions = COUNTIES
      break
    case "sportSearch":
      searchOptions = SPORTS
      break
    case "activitySearch":
      searchOptions = ACTIVITIES
      break
    case "apSearch":
      searchOptions = AP_SUBJECTS
      break
    case "ibSearch":
      searchOptions = IB_SUBJECTS
      break
    case "languageSearch":
      searchOptions = LANGUAGES
      break
    case "religionSearch":
      searchOptions = RELIGIONS
      break
    default:
      // @ts-ignore
      searchOptions = rest.options
  }

  // Don't include not found for certain searches
  const includeNotFound = (props.type as CustomSearchTypes) !== "stateSearch"
  if (includeNotFound && searchOptions) {
    searchOptions = [
      ...searchOptions,
      { label: "Not Found", value: "Not Found" },
    ]
  }

  // This is the synchronous search for when options are known on load
  return (
    <Searchbox
      ref={ref}
      name={props.name}
      defaultValue={formValues[name]}
      options={searchOptions}
      isAsync={false}
      {...rest}
    />
  )
})
