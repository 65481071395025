const divisor = 16
const unit = "em"

const viewports = {
  base: 0,
  largeMobile: 480,
  minTablet: 768,
  midTablet: 975,
  minLaptop: 1280,
  minDesktop: 1440,
  minWidescreen: 1680,
}

/**
 * Use max-width sparingly, but, if needed, export and use:
 * @media screen and (max-width: maxMobile) { *styles* }
 *
 * Example use with useMediaQuery()
 * const [isMobile] = useMediaQuery(`(max-width: ${maxMobile})`)
 *
 * Or via custom hook useMaxWidth():
 * const { isMobileView } = useMaxWidth()
 */
export const maxMobile = (viewports.largeMobile - 1) / divisor + unit
export const maxTablet = (viewports.minTablet - 1) / divisor + unit
export const maxLaptop = (viewports.minLaptop - 1) / divisor + unit
export const maxDesktop = (viewports.minDesktop - 1) / divisor + unit

/**
 * Example use: <Box padding={{base: 4, md: 6, xl: 8}}
 * https://chakra-ui.com/docs/features/responsive-styles
 */
export const base = viewports.base.toString()
export const sm = viewports.largeMobile / divisor + unit
export const md = viewports.minTablet / divisor + unit
export const tab = viewports.midTablet / divisor + unit
export const lg = viewports.minLaptop / divisor + unit
export const xl = viewports.minDesktop / divisor + unit
export const xxl = viewports.minWidescreen / divisor + unit

export const breakpoints = {
  base,
  sm,
  md,
  tab,
  lg,
  xl,
  "2xl": xxl,
}
