export const typography = {
    fonts: {
      h1: "'linux-libertine', serif",
      heading: "'venn', sans-serif",
      body: "'open-sans', sans-serif",
    },
    fontSizes: {
      bodyDesktop: "1.125rem",
    },
    fontWeights: {
      normal: 300,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  }
  