import { createContext, useContext } from "react"
import { FieldValues, useForm, UseFormReturn } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { User } from "types/user"
import { UserApi } from "api/user"
import {
  getFinalData,
  isAuthorizationError,
  sanitizeFormValues,
  transformServerDataToFormData,
} from "utilities/helpers"
import { useNavigate } from "react-router-dom"
import { TealiumLinkTrack } from "tealium"

import { AppRoutes } from "utilities/constants"
import { ClientContext } from "./ClientContext"
import { COLLEGE_NESTED_FIELDS } from "data"

interface FormContextProps {
  validationSchema: any
  user: User
  token: string
  children?: React.ReactNode
}

export interface FormContextState {
  form: UseFormReturn<FieldValues, object>
  onSubmit: () => Promise<User | void>
  onSave: () => Promise<User | void>
}

export const initialFormContextState: FormContextState = {
  form: undefined,
  onSubmit: () => Promise.reject(() => {}),
  onSave: () => Promise.reject(() => {}),
}

const FormContext = createContext(initialFormContextState)
export default FormContext

export const FormContextProvider: React.FC<FormContextProps> = ({
  validationSchema,
  user,
  token,
  children,
}) => {
  const navigate = useNavigate()
  const { client } = useContext(ClientContext)

  const arrayData = transformServerDataToFormData(
    { ...user.applicationData },
    COLLEGE_NESTED_FIELDS[client.theme],
  )

  const form = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: arrayData,
  })

  const onSubmit = (): Promise<User | void> => {
    // Get data without hidden and empty fields in server format
    const data = getFinalData(form.getValues, client, form.setValue)

    const sanitizedData = sanitizeFormValues(data)

    return UserApi.finalize({
      token: token,
      user: {
        ...user,
        applicationData: {
          ...sanitizedData,
          queryParams:
            sanitizedData?.queryParams ||
            JSON.parse(localStorage.getItem("queryParams")) ||
            undefined,
        },
      },
    })
      .then(user => {
        if (user.submittedAt) {
          TealiumLinkTrack({
            tealium_event: "app_submit",
            email_address: user.email,
          })
        }
        return user
      })
      .catch(err => {
        if (isAuthorizationError(err)) {
          console.error("Authorization error... Logging out user.")
          navigate(AppRoutes.logout)
        } else {
          console.error(err)
        }
      })
      .finally(() => {
        localStorage.getItem("queryParams") &&
          localStorage.removeItem("queryParams")
      })
  }

  const onSave = (): Promise<User | void> => {
    // Get data without hidden and empty fields in server format
    const data = getFinalData(form.getValues, client, form.setValue)

    const sanitizedData = sanitizeFormValues(data)

    return UserApi.replace({
      token: token,
      user: {
        ...user,
        applicationData: {
          ...sanitizedData,
          queryParams:
            sanitizedData?.queryParams ||
            JSON.parse(localStorage.getItem("queryParams")) ||
            undefined,
        },
      },
    }).catch(err => {
      if (isAuthorizationError(err)) {
        console.log("Authorization error... Logging out user.")
        navigate(AppRoutes.logout)
      } else {
        console.error(err)
      }
    })
  }

  const formContextState: FormContextState = {
    ...initialFormContextState,
    form,
    onSubmit,
    onSave,
  }

  return (
    <FormContext.Provider value={formContextState}>
      {children}
    </FormContext.Provider>
  )
}
