export const typography = {
  fonts: {
    heading: "'proxima-nova', sans-serif",
    body: "'proxima-nova', sans-serif",
    h1: "'nocturne-serif', sans-serif",
  },
  fontSizes: {
    bodyDesktop: "1.125rem",
  },
  fontWeights: {
    normal: 300,
    medium: 500,
    bold: 700,
  },
  lineHeights: {},
}
