import { useMediaQuery } from "@chakra-ui/react"
import {
  maxMobile as maxMobileSize,
  maxTablet as maxTabletSize,
  maxLaptop as maxLaptopSize,
  maxDesktop as maxDesktopSize,
  xxl as xxlSize,
} from "themes/base/breakpoints"

/**
 * WAIT A MINUTE!!!
 *
 * Only use this hook if you really understand what you're doing.
 * Chakra UI is "mobile first" and max-width media queries can be
 * a great way to shoot yourself in the foot. Use sparingly.
 */

export function useMaxWidth() {
  const [isMobileView] = useMediaQuery(`(max-width: ${maxMobileSize})`)
  const isMaxMobileView = isMobileView

  const [isTabletTabletView] = useMediaQuery(
    `(min-width: ${maxMobileSize} and (max-width: ${maxTabletSize})`
  )
  const [isMaxTabletView] = useMediaQuery(`(max-width: ${maxTabletSize})`)

  const [isLaptopView] = useMediaQuery(
    `(min-width: ${maxTabletSize} and (max-width: ${maxLaptopSize})`
  )
  const [isMaxLaptopView] = useMediaQuery(`(max-width: ${maxLaptopSize})`)

  const [isDesktopView] = useMediaQuery(
    `(min-width: ${maxLaptopSize} and (max-width: ${maxDesktopSize})`
  )
  const [isMaxDesktopView] = useMediaQuery(`(max-width: ${maxDesktopSize})`)

  const [isWidescreenView] = useMediaQuery(`(min-width: ${xxlSize})`)

  return {
    isMobileView,
    isMaxMobileView,
    isTabletTabletView,
    isMaxTabletView,
    isLaptopView,
    isMaxLaptopView,
    isDesktopView,
    isMaxDesktopView,
    isWidescreenView,
  }
}
