import { Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "utilities/constants"

const SignOutButton = () => {
  const navigate = useNavigate()
  const redirectToLogout = () => {
    navigate(AppRoutes.logout)
  }

  return (
    <Button
      variant="link"
      className="sign-out-button"
      onClick={redirectToLogout}
    >
      Sign Out
    </Button>
  )
}

export default SignOutButton
